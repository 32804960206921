/* eslint-disable */
import Vue from 'vue'
import axios from 'axios'
import router from "/src/router";
export default {
    namespaced: true,
    state: {
        liens: [],
        totalPaginate: 0,
    },
    mutations: {
        setLiens(state, datas) {
            state.liens = datas
        },
        storeLien(state, data) {
            state.liens.push(data)
        },
        updateLien(state, data) {
            var index = state.liens.findIndex(l => l.id === data.id);
            Vue.set(state.liens, index, data);
        },
        deleteLien(state, id) {
            var index = state.liens.findIndex(lien => lien.id == id)
            state.liens.splice(index, 1)
        },
        setTotalPaginate(state, data) {
            state.totalPaginate = data
        }
    },
    getters: {
        getLiens(state) {
            return state.liens
        },
        getTotalPaginate(state) {
            return state.totalPaginate
        }
    },
    actions: {
        setLiens({ commit }, payload) {
            axios.get('liens/index/'+ payload.idContact +'?page=' + payload.page).then((response) => {
                commit('setLiens', response.data.data)
                commit('setTotalPaginate', response.data.last_page)
            }).catch(function(error) {
                if (error.response.status === 401) {
                    setTimeout(() => {
                        router.push({
                            name: "Connexion"
                        });
                    }, 900);
                }
            });
        },
           updateLien({ commit }, data) {
            commit('updateLien', data)
        },
        storeLien({ commit }, data) {
            commit('storeLien', data)
        },
        deleteLien({commit}, id) {
            commit('deleteLien', id)
        }
    }
}