<template>
    <div>
        <div class="
        alert alert-warning alert-dismissible
        mt-2
        m-3
        show
        text-break text-center
      " role="alert">
            Après toute modifications, Il faut que tu <strong>enregistres</strong> les
            modifications en cliquant sur le <strong>bouton en bas de page</strong> !

        </div>

        <div class="ml-2 d-flex justify-content-center">
            <vBoxCheck clef="remise" id="entreprise-remise" rulesInfo="required" :value="entreprise.remise"
                label="Autorisé les remise commerciale ?" type="entreprise" icon="mdi-form-textbox"></vBoxCheck>
        </div>

        <div class="d-flex justify-content-center mr-2">
            <img width="150" height="150" @click="triggerInputFile()" :src="inputFileSelect" />
            <input type="file" id="logo-entreprise" ref="input1" style="display: none"
                accept="file_extension|image/*|media_type" @change="changePreview($event)" />


        </div>
        <div class="text-center">
            <label for="logo-entreprise" class="text-center mr-1">Logo de votre entreprise <br />
                <i>(Cliquer sur l'image)</i></label>
        </div>

        <v-form v-model="valid">
            <input type="hidden" v-model="entreprise.id" />
            <v-col cols="12">
                <vTextInput libelle="Libelle" clef="libelle" class="mt-6" id="entreprise-libelle" rulesInfo="required"
                    :value="entreprise.libelle" placeholder="Entreprise"
                    :rules="[(v) => !!v || 'Le champ est obligatoire']" type="entreprise" icon="mdi-form-textbox">
                </vTextInput>

                <vTextInput libelle="Rue" clef="rue" class="mt-6" id="entreprise-rue" rulesInfo="required"
                    :value="entreprise.rue" placeholder="5 Boulevard de la République.."
                    :rules="[(v) => !!v || 'Le champ est obligatoire']" type="entreprise" icon="mdi-map-marker">
                </vTextInput>
                <vTextInput libelle="Ville" clef="ville" class="mt-6" id="entreprise-ville" rulesInfo="required"
                    :value="entreprise.ville" placeholder="Paris.." :rules="[(v) => !!v || 'Le champ est obligatoire']"
                    type="entreprise" icon="mdi-form-textbox"></vTextInput>
                <vTextInput libelle="Code postal" clef="codePostal" class="mt-6" id="entreprise-codePostal"
                    rulesInfo="required" :value="entreprise.codePostal" placeholder="75000.."
                    :rules="[(v) => !!v || 'Le champ est obligatoire']" type="entreprise" icon="mdi-form-textbox">
                </vTextInput>
                <vTextInput libelle="Téléphone" clef="telephone" class="mt-6" id="entreprise-telephone"
                    rulesInfo="required" :value="entreprise.telephone" placeholder="01352.....21"
                    :rules="[(v) => !!v || 'Le champ est obligatoire']" type="entreprise" icon="mdi-form-textbox">
                </vTextInput>
                <vTextInput libelle="Email" clef="email" class="mt-6" id="entreprise-email" rulesInfo="required"
                    :value="entreprise.email" placeholder="exemple@exemple.fr"
                    :rules="[(v) => !!v || 'Le champ est obligatoire', (v) => /.+@.+\..+/.test(v) || 'L\'adresse email est invalide',]"
                    type="entreprise" icon="mdi-email"></vTextInput>
                <vTextInput libelle="SIRET" clef="siret" class="mt-6" id="entreprise-siret" rulesInfo="required"
                    :value="entreprise.siret" placeholder="01352.....21"
                    :rules="[(v) => !!v || 'Le champ est obligatoire']" type="entreprise" icon="mdi-form-textbox">
                </vTextInput>
                <vTextInput libelle="TVA Intracom" clef="tvaIntracom" class="mt-6" id="entreprise-tvaIntracom"
                    rulesInfo="required" :value="entreprise.tvaIntracom" placeholder="01352.....21"
                    :rules="[(v) => !!v || 'Le champ est obligatoire']" type="entreprise" icon="mdi-form-textbox">
                </vTextInput>
                <vTextInput libelle="IBAN" clef="IBAN" class="mt-6" id="entreprise-IBAN" :value="entreprise.IBAN"
                    placeholder="01352.....21" type="entreprise" icon="mdi-form-textbox">
                </vTextInput>
                <vTextInput libelle="Code APE" clef="codeAPE" class="mt-6" id="entreprise-codeAPE" rulesInfo="required"
                    :value="entreprise.codeAPE" placeholder="01352.....21"
                    :rules="[(v) => !!v || 'Le champ est obligatoire']" type="entreprise" icon="mdi-form-textbox">
                </vTextInput>
                <vTextInput libelle="Forme Juridique" clef="formeJuridique" class="mt-6" id="entreprise-formeJuridique"
                    rulesInfo="required" :value="entreprise.formeJuridique" placeholder="01352.....21"
                    :rules="[(v) => !!v || 'Le champ est obligatoire']" type="entreprise" icon="mdi-form-textbox">
                </vTextInput>
                <vTextInput libelle="Fiche Google Business" clef="google_buiz" class="mt-6" id="entreprise-google_buiz"
                    :value="entreprise.google_buiz" placeholder="01352.....21" type="entreprise"
                    icon="mdi-form-textbox">
                </vTextInput>
            </v-col>
            <v-card-actions>
                <v-spacer></v-spacer>
                <vBtnEdit nom="Sauvegarder" v-if="validateForm()" :valid="valid" icon="mdi-content-save"
                    @saveForm="returnTo" :onlyIcon="false" :id="entreprise.id" table="entreprise"></vBtnEdit>
            </v-card-actions>
        </v-form>
    </div>
</template>
<script>
/* eslint-disable */
import Vue from "vue";
import vTextInput from "@/components/inputs/saisies/vTextInput.vue";
import VBtnEdit from "@/components/inputs/boutons/vBtnEdit.vue";
import VBtnSave from "@/components/inputs/boutons/vBtnSave.vue";
import vBoxCheck from "@/components/inputs/saisies/vBoxCheck.vue";
export default {
    components: { vTextInput, VBtnEdit, VBtnSave, vBoxCheck },
    data: () => ({
        inputFileSelect: require("../../assets/img/placeholder/monlogo.png"),
        switchh: "",
        valid: false,
        changeLogo: false,
        entreprise: [],
        email: "",
        requiredRules: [(v) => !!v || "Le champ est obligatoire"],
        numberRules: [
            (v) => !!v || "Le champ est obligatoire",
            (v) => !isNaN(v) || "Ce n'est pas un nombre",
        ],
        emailRules: [
            (v) => !!v || "Le champ doit être remplis",
            (v) => /.+@.+\..+/.test(v) || "L'adresse email est invalide",
        ],
        passwordConfirm: [
            (v) => this.validatePassword(v) || "Les mots de passes ne concorde pas !",
        ],
        numberRulesNoObligatory: [(v) => !isNaN(v) || "Ce n'est pas un nombre"],
    }),
    mounted() {
        this.getMyEnterprise();
    },
    methods: {
        triggerInputFile() {
            this.$refs.input1.click();
        },
        changePreview(event) {
            var reader = new FileReader();
            if (event) {
                if (event.size > 2e6) {
                    this.$swal(
                        "L'image est trop volumineuse !",
                        "Veuillez changer choisir une image pesant moins de 2 MB !",
                        "warning"
                    );
                } else {
                    var image = event.target.files[0];
                    this.entreprise.logo = event.target.files[0];
                    this.changeLogo = true;
                    reader.readAsDataURL(image);
                    reader.onload = () => {
                        let formData = new FormData();
                        formData.append('logo', this.entreprise.logo)
                        this.$axios.post('entreprise/changeLogo', formData).then((response) => {
                            this.$swal('Bravo !', response.data.message, "success")
                            this.inputFileSelect = reader.result;
                            document.location.reload()
                        }).catch(() => {
                            this.$swal("L'image est trop volumineuse !", "Veuillez choisir une image moins volumineuse", 'warning').then(() => {
                                document.location.reload()
                            })
                        
                        })
                    };
                }
            }
        },
        getMyEnterprise() {
            this.$axios.get("entreprise/info").then((response) => {
                this.entreprise = response.data;
                this.inputFileSelect = this.entreprise.logo;
                this.$store.dispatch("formulaire/initForm", {
                    form: "entreprise",
                    listFields: {
                        id: this.entreprise.id,
                        libelle: this.entreprise.libelle,
                        rue: this.entreprise.rue,
                        ville: this.entreprise.ville,
                        codePostal: this.entreprise.codePostal,
                        telephone: this.entreprise.telephone,
                        email: this.entreprise.email,
                        tvaIntracom: this.entreprise.tvaIntracom,
                        IBAN: this.entreprise.IBAN,
                        siret: this.entreprise.siret,
                        codeAPE: this.entreprise.codeAPE,
                        formeJuridique: this.entreprise.formeJuridique,
                        remise: this.entreprise.remise,
                        logo: this.entreprise.logo,
                        google_buiz: this.entreprise.google_buiz,
                        changeLogo: this.changeLogo,
                    },
                });
            });
        },
        validateForm() {
            if (
                this.entreprise.siret != "" &&
                this.entreprise.libelle != "" &&
                this.entreprise.formeJuridique != "" &&
                this.entreprise.rue != "" &&
                this.entreprise.ville != "" &&
                this.entreprise.codePostal != "" &&
                this.entreprise.telephone != ""
            ) {
                return true;
            } else {
                return false;
            }
        },
        returnTo() {
            setTimeout(() => {
                document.location.reload();
            }, 1000)

            /*     document.location.reload() */
        }
    },
};
</script>
<style scoped>
.-bg {
    color: #ffffff;
    background-color: #212121;
}
</style>
