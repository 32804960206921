<template>
    <div>
        <div style="right: -10px">
            <vBtnAdd class="mb-2 mt-3 mr-5 ml-2" :classItems="'bg-color-main text-white'" nom="Ajouter un type de rendez-vous" @openModal="openModal"
                table="crud" icon="mdi-plus"></vBtnAdd>
        </div>
        <div class="float-bottom">
            <VTableData :items="items" :loader="loader" @triggerRowTable="editItem" :headers="[
              {
                text: 'Libelle',
                align: 'left',
                sortable: true,
                value: 'libelle',
              },
              {
                text: 'Couleur de fond de l\'événement',
                align: 'left',
                sortable: true,
                value: 'bgColor',
              },
              {
                text: 'Couleur du texte de l\'événement',
                align: 'left',
                sortable: true,
                value: 'textColor',
              },
              {
                text: 'Client obligatoire ?',
                align: 'left',
                sortable: true,
                value: 'hasClientNeeded',
              },
            ]" :hiddenFooter="true" :hiddenHeader="false" :total="total" @pageChange="pageChange"></VTableData>
            <vModalCrud @closeDialog="close" deleteStoreFunction="typeRdv/deleteTypeRdv" :items="items"
                :itemEdited="editedItem" :formTitle="formTitle" v-if="dialog" :typeCRUD="typeCRUD"
                :typeForm="'FormTypeRdv'" type="typerdv"></vModalCrud>
        </div>
    </div>
</template>
  
<script>
import vBtnAdd from "@/components/inputs/boutons/vBtnAdd.vue";
import VTableData from "@/components/modules/vTableData.vue";
import vModalCrud from "@/components/modules/vModalCrud.vue";
import viewMixin from "@/mixins/viewMixin.js";

export default {
    components: { VTableData, vModalCrud, vBtnAdd },
    mixins: [viewMixin],
    data() {
        return {
            loader: false,
            formTitle: "Créer un type de rendez-vous",
            editedIndex: -1,
            editedItem: {
                id: "",
                libelle: "",
            },
            defaultItem: {
                id: "",
                libelle: "",
            },
            role: "",
            dialog: false,
            dialogDelete: false,
            page: 1,
            valid: true,
            typeCRUD: "",
            requiredRules: [(v) => !!v || "Le champ est obligatoire"],
        };
    },
    mounted() {
        this.setTypeRdvs()
    },
    computed: {
        items() {
            return this.$store.getters["typeRdv/getTypeRdvs"];
        },
        total() {
            return this.$store.getters["typeRdv/getTotalPaginate"];
        }
    },
    methods: {
        setTypeRdvs() {
            this.$store.dispatch("typeRdv/setTypeRdvs", this.page)
        },
        pageChange(page) {
            this.page = page
            this.setTypeRdvs()
        },
        validateForm() {
            if (this.editedItem.libelle != "") {
                return true;
            } else {
                return false;
            }
        },
        returnTo() {
            this.$store.dispatch("typeRdv/updateTypeRdv", this.editedItem);
        },
        openModal() {
            this.typeCRUD = "create";
            this.$store.dispatch("formulaire/initForm", {
                form: "typerdvs",
                listFields: {
                    id: "",
                    textColor: "",
                    bgColor: "",
                    hasClientNeeded: "",
                    libelle: "",
                },
            });
            this.dialog = true;
            this.formTitle = "Créer un type de rendez-vous";
        },
        editItem(item) {
            this.typeCRUD = "edit";
            this.editedIndex = this.items.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.formTitle = "Editer le type de rendez-vous";
            this.dialog = true;
            this.$store.dispatch("formulaire/initForm", {
                form: "typerdvs",
                listFields: {
                    id: this.editedItem.id,
                    libelle: this.editedItem.libelle,
                    textColor: this.editedItem.textColor,
                    bgColor: this.editedItem.bgColor,
                    hasClientNeeded: this.editedItem.hasClientNeeded,
                },
            });
        },
        closeDelete() {
            this.dialogDelete = false;
        },
        close() {
            this.dialog = false;
            this.editedItem = {
                id: "",
                textColor: "",
                bgColor: "",
                hasClientNeeded: "",
                libelle: "",
            };
            this.editedIndex = -1;
        },
        deleteItem() {
            this.dialogDelete = true;
        },
        deleteItemConfirm() {
            this.items.splice(this.editedItem, 1);
            this.$axios
                .delete("typerdvs/delete/" + this.editedItem.id)
                .then((response) => {
                    this.$swal("Bravo !", response.data.message, "success");
                    this.closeDelete();
                    this.close();
                });
        },
    },
};
</script>
  
<style>

</style>