<template>
    <div>
        <v-data-table :loading="loader" :hide-default-footer="hiddenFooter" :item-class="row_classes"
            :hide-default-header="hiddenHeader" mobile-breakpoint="0" :headers="headers" :items="items"
            @click:row="triggerRow" class="elevation-1">
            <!-- Type RDV -->
            <template v-slot:item.bgColor="{ item }">
                <div style="height: 20px; width: 20px" :style="'background-color:' + item.bgColor"></div>
            </template>
            <!-- Type RDV -->
            <template v-slot:item.textColor="{ item }">
                <div style="height: 20px; width: 20px" :style="'background-color:' + item.textColor"></div>
            </template>
            <!-- Produit -->
            <template v-slot:item.prix_unitaire="{ item }">
                {{ item.prix_unitaire }}€
            </template>
            <!-- Produit -->
            <template v-slot:item.montant_ttc="{ item }">
                {{ item.montant_ttc }}€
            </template>
            <!-- Produit -->
            <template v-slot:item.tva="{ item }">
                {{ item.tva }}%
            </template>
            <!-- Liens -->
            <template v-slot:item.actions="{ item }">
                <v-btn rounded class="bg-color-secondaire" @click="changeLocation(item)">Ouvrir l'URL</v-btn>
            </template>
            <!-- Devis - Commande - Facture -->
            <template v-slot:item.date_commande="{ item }">
                {{ $moment(item.date_commande).format('LL') }}
            </template>
            <template v-slot:item.date_devis="{ item }">
                {{ $moment(item.date_devis).format('LL') }}
            </template>
            <template v-slot:item.date_facture="{ item }">
                {{ $moment(item.date_facture).format('LL') }}
            </template>
        </v-data-table>
        <v-pagination v-model="page" color="#5A189A" class="m-3" @input="onPageChange()" v-if="total > 1"
            :length="total" circle></v-pagination>
    </div>
</template>

<script>
export default {
    props: ['items', 'headers', "hiddenFooter", "hiddenHeader", "loader", "total", "type"],
    data() {
        return {
            page: 1,
        }
    },
    methods: {
        triggerRow(event) {
            this.$emit('triggerRowTable', event)
        },
        onPageChange() {
            this.$emit('pageChange', this.page)
        },
        row_classes(item) {
            if (item.isDone) {
                return "grey lighten-1 text-secondary"; //can also return multiple classes e.g ["orange","disabled"]
            }
            if (item.annuler) {
                return "red accent-4 text-white"
            }
            return "";
        },
        changeLocation(item) {
            window.open(item.url, '_blank');
        }
    }
}
</script>

<style>

</style>