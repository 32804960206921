<template>
  <div class="container">
    <v-form ref="form" v-model="valid">
      <v-text-field
        v-model="form.nom"
        filled
        rounded
        :rules="requiredRules"
        label="Nom"
        required
        ><template v-slot:label>
          <span class="input__label"
            >Nom <span style="color: red">*</span></span
          >
        </template>
      </v-text-field>
      <v-text-field
        v-model="form.prenom"
        :rules="requiredRules"
        filled
        rounded
        label="Prénom"
        required
      >
        <template v-slot:label>
          <span class="input__label"
            >Prénom <span style="color: red">*</span></span
          >
        </template></v-text-field
      >
      <v-text-field
        v-model="form.email"
        filled
        rounded
        :rules="emailRules"
        label="E-mail"
        required
      >
        <template v-slot:label>
          <span class="input__label"
            >E-mail <span style="color: red">*</span></span
          >
        </template></v-text-field
      >
      <v-text-field
        type="password"
        filled
        rounded
        v-model="form.password"
        :rules="requiredRules"
        label="Mot de passe"
      >
        <template v-slot:label>
          <span class="input__label"
            >Mot de passe <span style="color: red">*</span></span
          >
        </template></v-text-field
      >
      <v-text-field
        type="password"
        filled
        rounded
        v-model="password2"
        :rules="passwordConfirm"
        label="Confirmer le mot de passe"
      >
        <template v-slot:label>
          <span class="input__label"
            >Confirmer le mot de passe <span style="color: red">*</span></span
          >
        </template></v-text-field
      >
    </v-form>
    <v-card-actions>
      <v-btn text @click="backStep()">
        <v-icon>mdi-arrow-left</v-icon> Retour
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        :disabled="checkIfAllFill()"
        class="bg-color-main text-white"
        rounded
        depressed
        @click="nextStep()"
      >
        Suivant <v-icon>mdi-arrow-right</v-icon>
      </v-btn>
    </v-card-actions>
  </div>
</template>
  
<script>
/* eslint-disable */
export default {
  data() {
    return {
      form: {
        nom: "",
        prenom: "",
        email: "",
        password: "",
        entreprise_id: "",
      },
      valid: false,
      password2: "",
      requiredRules: [(v) => !!v || "Le champ est obligatoire"],
      passwordConfirm: [
        (v) => this.validate(v) || "Les mot de passes ne concorde pas !",
        (v) => !!v || "Le champ est obligatoire",
      ],
      numberRules: [
        (v) => !!v || "Le champ est obligatoire",
        (v) => !isNaN(v) || "Ce n'est pas un nombre",
      ],
      emailRules: [
        (v) => !!v || "Le champ doit être remplis",
        (v) => /.+@.+\..+/.test(v) || "L'adresse email est invalide",
      ],
    };
  },
  watch: {
    valid(val) {
      if (val && this.form.password === this.password2) {
        this.$emit("disabledStep");
      }
    },
  },
  mounted() {},
  methods: {
    validate() {
      if (this.form.password === this.password2) {
        return true;
      } else {
        return false;
      }
    },
    checkIfAllFill() {
      if (
        this.form.nom != "" &&
        this.form.prenom != "" &&
        this.form.email != "" &&
        this.form.password != "" &&
        this.password2 != ""
      ) {
        return false;
      } else {
        return true;
      }
    },
    nextStep() {
      this.form.entreprise_id =
        this.$store.getters["entreprises/getEntreprise"]["id"];
      this.$store.dispatch("auth/register", this.form).then((response) => {
        if (response.data.messageError) {
          this.$swal(
            "Oups !",
            response.data.messageError,
            "error"
          )
          return;
        } else {
          this.$swal(
            "Super !",
            "Votre compte utilisateur a bien été créé !",
            "success"
          ).then(() => {
            this.$emit("changeStep", "next");
          });
        }
      });
    },
    backStep() {
      this.$emit("changeStep", "back");
    },
  },
};
</script>
  
<style>
</style>
  