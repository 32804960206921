<template>
    <div data-app>
        <v-window touchless v-model="step">
            <v-window-item :value="1" class="container">
                <v-card-text>
                    <div class="container">
                        <h1 class="text-center">BIENVENUE DANS DEVIS.CLICK</h1>
                        <div class="container">
                            <p class="lead text-weight-bold text-center text-break mb-5">
                                Nous vous offrons
                                <strong style="font-size: 20px; color: #bf00ff" class="fw-bold">5000</strong>
                                Coins !
                            </p>
                            <h4>Récapitulatif des coûts pour la création d'un(e):</h4>
                            <ul>
                                <li>
                                    Devis:
                                    <strong style="color: #bf00ff" class="fw-bold">1</strong>
                                    Coins
                                </li>
                                <li>
                                    Commande:
                                    <strong style="color: #bf00ff" class="fw-bold">1</strong> Coins
                                </li>
                                <li>
                                    Facture:<strong style="color: #bf00ff" class="fw-bold">
                                        0.0015% du montant HT de votre facture en coins</strong>
                                </li>
                                <li>
                                    Un rendez-vous:
                                    <strong style="color: #bf00ff" class="fw-bold">1</strong>
                                    Coins
                                </li>
                                <li>
                                    Un fournisseur :
                                    <strong style="color: #bf00ff" class="fw-bold">100</strong>
                                    Coins
                                </li>
                                <li>
                                    Un contact :
                                    <strong style="color: #bf00ff" class="fw-bold">1</strong>
                                    Coins
                                </li>
                                <li>
                                    Un utilisateur:
                                    <strong style="color: #bf00ff" class="fw-bold">350</strong>
                                    Coins
                                </li>
                                <li>
                                    Un point de vente :
                                    <strong style="color: #bf00ff" class="fw-bold">40</strong>
                                    Coins
                                </li>
                            </ul>
                        </div>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-btn :disabled="step === 1" text @click="step--">
                        <v-icon>mdi-arrow-left</v-icon> Retour
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn class="bg-color-main text-white" rounded depressed @click="step++">
                        Suivant <v-icon>mdi-arrow-right</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-window-item>

            <v-window-item :value="2">
                <v-card-title>Créer tous d'abord votre entreprise ?</v-card-title>
                <FormRegisterEntreprise @changeStep="changeStep"></FormRegisterEntreprise>
            </v-window-item>

            <v-window-item :value="3">
                <v-card-title>Qui êtes-vous ?</v-card-title>
                <FormRegisterGerant @changeStep="changeStep"></FormRegisterGerant>
            </v-window-item>
            <v-window-item :value="4">
                <v-card-title>Merci pour votre inscription !</v-card-title>
                <div class="container">
                    <p class="text-break text-center fs-6" style="font-size:14px">
                        Vous avez terminé l'enregistrement de votre compte chez <strong class="text-color-main">Devis.click
                            !</strong>
                        <br /> <br>
                        Nous vous conseillons de cliquer sur ce bouton en haut à gauche de votre écran <v-icon
                            class="text-color-main">mdi-menu</v-icon> pour
                        modifier vos : <br />
                        <br />
                        <strong class="text-color-main">
                            - Fournisseurs, <br />
                            - Points de ventes, <br />
                            - Origine de contact, <br />
                            - Famille de produits, <br />
                            - Type de rendez-vous, <br />
                            <br />
                        </strong>
                        Elles ont été créées par défaut ! <br /><br />

                        Vous pourrez également ajouter des :
                        <strong class="text-color-main">
                            <br />
                            <br />
                            - Produits, <br />
                            - Utilisateurs, <br />
                            - Type de règlements, <br />
                            <br />
                        </strong>
                        <strong>Prenez votre temps afin de paramétrer votre compte</strong>,
                        vous pourrez également modifier les autres informations pour
                        votre entreprise. <br /><br>
                        Nous vous souhaitons plein de réussite avec <strong class="text-color-main"> Devis.click !</strong>
                    </p>
                    <div class="d-flex justify-content-center">
                        <v-btn rounded @click="redirectTo()" class="bg-color-main text-white"><v-icon>mdi-emoticon-happy</v-icon>JE ME LANCE SUR DEVIS.CLICK</v-btn>
                    </div>
                    
                </div>
            </v-window-item>
        </v-window>
    </div>
</template>
  
<script>
/* eslint-disable */

import { mapActions } from "vuex";
import FormRegisterGerant from "@/components/register/FormRegisterGerant.vue";
import FormRegisterEntreprise from "@/components/register/FormRegisterEntreprise.vue";
export default {
    components: {
        FormRegisterGerant,
        FormRegisterEntreprise
    },
    data() {
        return {
            step: 1,
            user: [],
            entreprise: [],
            agencePrincipal: [],
            showRegisterAgence: false,
        };
    },
    computed: {
    },
    methods: {
        ...mapActions({
            register: "auth/register",
        }),
        toggleShowAgence() {
            this.showRegisterAgence = !this.showRegisterAgence;
        },
        changeStep(direction) {
            if (direction == "next") {
                this.step = this.step + 1;
            } else {
                this.step = this.step - 1;
            }

        },
        redirectTo() {
            this.$router.push({name: "Accueil"}).then(() => {
                document.location.reload()
            })
        }
    },

};
</script>
<style>

</style>
  