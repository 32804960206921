<template>
  <v-app>
    <NavBar></NavBar>
    <v-main>
      <router-view />
    </v-main>
    <BottomBar v-if="showAuthenticatedThing"></BottomBar>
  </v-app>
</template>

<script>
/* eslint-disable */

export default {
  name: "App",

  data: () => ({
  }),
  metaInfo() {
    return {
      title: this.$route.name + ' - '  + ' devis.click ',
    }
   
  },
  computed: {
    showAuthenticatedThing() {
      return !!this.$store.getters["auth/authenticated"];
    },
  },
};
</script>
