<template>
  <div>
    <div style="right: -10px">
      <vBtnAdd class="mb-2 mt-3 mr-5 ml-2" :classItems="'bg-color-main text-white'" nom="Ajouter un lien"
        @openModal="openModal" table="crud" icon="mdi-plus"></vBtnAdd>
    </div>
    <div class="float-bottom">
      <VTableData :items="items" :loader="loader" @triggerRowTable="editItem" :headers="[
        {
          text: 'Actions',
          align: 'left',
          sortable: false,
          value: 'actions',
        },
        {
          text: 'Titre',
          align: 'left',
          sortable: true,
          value: 'titre',
        },
        {
          text: 'URL',
          align: 'left',
          sortable: true,
          value: 'url',
        },
      
      ]" :hiddenFooter="true" :hiddenHeader="false" :total="total" @pageChange="pageChange"></VTableData>
      <vModalCrud @closeDialog="close" deleteStoreFunction="liens/deleteLien" :items="items" :itemEdited="editedItem"
        :formTitle="formTitle" v-if="dialog" :typeCRUD="typeCRUD" :typeForm="'FormLien'" type="lien"></vModalCrud>
    </div>
  </div>
</template>
  
<script>
import { bus } from "@/main";
import vModalCrud from "@/components/modules/vModalCrud.vue";
export default {
  props: ['idContact'],
  components: {
    vModalCrud
  },
  data() {
    return {
      loader: false,
      formTitle: "Créer une lien",
      editedIndex: -1,
      editedItem: {
        id: "",
        titre: "",
        url: "",
        contact_id: this.idContact
      },
      defaultItem: {
        id: "",
        titre: "",
        url: "",
        contact_id: this.idContact
      },
      role: "",
      dialog: false,
      dialogDelete: false,
      valid: true,
      typeCRUD: "",
      page: 1,
      requiredRules: [(v) => !!v || "Le champ est obligatoire"],
    };
  },
  created() {
    var payload = {
      table: "contact",
      icon: "mdi-plus",
      onlyIcon: true,
      classItems: "bg-primary text-white",
      typeComponent: "vBtnAdd",
      routeDisplay: "Mes contacts",
    };
    bus.$emit("changeButtonAction", payload);
  },
  mounted() {
    this.setLiens()
  },
  computed: {
    items() {
      return this.$store.getters["liens/getLiens"];
    },
    total() {
      return this.$store.getters["liens/getTotalPaginate"];
    }
  },
  methods: {
    setLiens() {
      var payload = {
        page: this.page,
        idContact: this.idContact
      }
      this.$store.dispatch("liens/setLiens", payload);
    },
    pageChange(page) {
      this.page = page
      this.setLiens()
    },
    validateForm() {
      if (this.editedItem.titre != "" && this.editedItem.url != "") {
        return true;
      } else {
        return false;
      }
    },
    returnTo() {
      this.$store.dispatch("liens/updateLien", this.editedItem);
    },
    openModal() {
      this.typeCRUD = "create";
      this.$store.dispatch("formulaire/initForm", {
        form: "liens",
        listFields: {
          id: "",
          titre: "",
          url: "",
          contact_id: this.idContact
        },
      });
      this.dialog = true;
      this.formTitle = "Créer un lien";
    },
    editItem(item) {
      this.typeCRUD = "edit";
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.formTitle = "Editer le lien";
      this.dialog = true;
      this.$store.dispatch("formulaire/initForm", {
        form: "liens",
        listFields: {
          id: this.editedItem.id,
          titre: this.editedItem.titre,
          url: this.editedItem.url,
          contact_id: this.idContact
        },
      });
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    close() {
      this.dialog = false;
      this.editedItem = {
        id: "",
        titre: "",
        url: "",
        contact_id: this.idContact
      };
      this.editedIndex = -1;
    },
    deleteItem() {
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.items.splice(this.editedItem, 1);
      this.$axios
        .delete("liens/delete/" + this.editedItem.id)
        .then((response) => {
          this.$swal("Bravo !", response.data.message, "success");
          this.closeDelete();
          this.close();
        });
    },
  },
};
</script>
  
<style>

</style>