<template>
  <div>
    <v-textarea
      filled
      rounded
      :id="id"
      :rules="rules"
      :prepend-inner-icon="icon"
      v-model="modelValue"
      :placeholder="placeholder"
      :name="clef"
      auto-grow
      ><template v-slot:label v-if="rulesInfo == 'required'">
        <span class="input__label"
          >{{ libelle }} <span style="color: red">*</span></span
        >
      </template>
      <template v-slot:label v-else>
        <span class="input__label">{{ libelle }}</span>
      </template></v-textarea
    >
  </div>
</template>

<script>
export default {
  props: [
    "id",
    "rulesInfo",
    "libelle",
    "icon",
    "rules",
    "clef",
    "value",
    "placeholder",
    "type",
    "storeDispatchName",
  ],
  data() {
    return {};
  },
  computed: {
    modelValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$store.dispatch("formulaire/updateForm", {
          form: this.type,
          field: this.clef,
          value: val,
        });
      },
    },
  },
};
</script>

<style>
</style>