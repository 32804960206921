/* eslint-disable */
export default {
    data() {
        return {}
    },
    computed: {
        currentRouteName() {
            return this.$route.name
        },
        isDarkMode() {
            return this.$vuetify.theme.isDark
        },
        isMobile() {
            return this.$vuetify.breakpoint.mobile
        },
    },
    methods: {
        areYouEnoughCoins(number) {
            if (this.$store.getters['coin/myCoins'] >= number) {
                return true;
            } else {
                return false;
            }

        },
        authUser(find) {
            if (this.$store.getters['auth/dataLoad']) {
                return this.$store.getters['auth/user'][find]
            }
        },
        authUserEntreprise(find) {
            if (this.$store.getters['auth/dataLoad']) {
                return this.$store.getters['auth/user']['entreprise'][find]
            }
        },
    }
}