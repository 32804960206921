<template>
    <div>
        <v-btn class="text-white float-right mt-5 m-3" rounded style="background-color: red"
            @click="deleteItemVente(produit)">
            <v-icon> mdi-delete </v-icon>
        </v-btn>
        <FormProduit :receivedProduit="produit" :idProduit="idProduit" :typeCRUD="'editproduitvente'" :idContact="(this.idContact) ? this.idContact : this.$route.params.idContact" :idVente="idVente" :typeVente="typeVente"
            :fromScenario="fromScenario">
        </FormProduit>
    </div>
</template>
  
<script>
import FormProduit from '@/components/produits/FormProduit.vue';
import viewMixin from "@/mixins/viewMixin.js";
export default {
    mixins: [viewMixin],
    components: { FormProduit },
    props: ['typeCRUD', 'receivedProduit', 'fromScenario', 'idVente', "typeVente", "idProduit", 'idContact'],
    data() {
        return {
            produit: []
        }
    },
    created() {
        this.getProduit()
    },
    mounted() {

        if (this.$store.getters["categories/getCategories"].length == 0) {
            this.getCategories()
        }
        if (this.$store.getters["fournisseurs/getFournisseurs"].length == 0) {
            this.getFournisseurs()
        }
    },
    methods: {
        getProduit() {
            var payload = {
                id: this.idProduit,
                idProduit: this.idProduit,
                idVente: this.idVente,
                typeVente: this.typeVente
            }

            this.$axios.post('produits/ventes/readProduit', payload).then((response) => {
                console.log(array)
                var array = response.data
                this.produit = array
                this.produit['quantite'] = array['pivot']['quantite']
                this.produit['prix_remise'] = array['pivot']['prix_remise']


            })
        },
        getFournisseurs() {
            this.$store.dispatch("fournisseurs/setFournisseurs");
        },
        getCategories() {
            this.$store.dispatch("categories/setCategories");
        },
        deleteItemVente(produit) {
            this.$swal({
                title: "Voulez-vous retirer le produit de la vente ?",
                showDenyButton: true,
                type: "danger",
                showCancelButton: false,
                confirmButtonText: "Oui",
                denyButtonText: `Non`,
            }).then((result) => {
                if (result.isConfirmed) {
                    var payload = {
                        id: produit.pivot.id,
                        idVente: produit.pivot.document_id,
                        typeVente: this.typeVente
                    }
                    this.$axios.post('produits/ventes/deleteProduitsVente', payload).then((response) => {
                        this.$swal("Bravo !", response.data.message, "success");
                        this.$router.push({
                            name: "J'ajoute mes produits", params: {
                                idVente: produit.pivot.document_id,
                                typeVente: this.typeVente,
                                idContact: (this.idContact) ? this.idContact : this.$route.params.idContact,
                                produitsList: response.data.vente
                            }
                        })
                    });
                }
            });

        }

    }
}
</script>
  
<style>

</style>