<template>
    <div style="padding-bottom:150px">
        <v-form ref="form" v-model="valid" lazy-validation>
            <v-card-text>
                <v-text-field filled rounded v-model="user.nom" :rules="requiredRules" label="Nom"><template
                        v-slot:label>
                        <span class="input__label">Nom <span style="color: red">*</span></span>
                    </template></v-text-field>
                <v-text-field filled rounded v-model="user.prenom" :rules="requiredRules" label="Prénom"><template
                        v-slot:label>
                        <span class="input__label">Prénom <span style="color: red">*</span></span>
                    </template></v-text-field>
                <v-text-field filled rounded v-model="user.email" :rules="emailRules" label="Adresse e-mail">
                    <template v-slot:label>
                        <span class="input__label">Adresse e-mail<span style="color: red">*</span></span>
                    </template>
                </v-text-field>
            </v-card-text>
            <center>
                <v-btn rounded @click="dialogMDP = true">
                    <v-icon>mdi-form-textbox-password</v-icon>Je modifie mon mot de passe
                </v-btn>
                <v-btn v-if="checkIfAllFill()" v-show="notShowing" rounded :disabled="!valid"
                    @click="updateProfilInformation()" class="bg-color-main text-light m-3">
                    <v-icon left>mdi-check</v-icon>
                    Enregistrer
                </v-btn>
            </center>
        </v-form>
        <v-card style="background-color: #5A189A" class="mt-3 pb-5 mb-5" dark>
            <h1 class=" text-center font-weight-bold p-3">
                Mes badges
            </h1>
            <p class="lead text-center p-3">
                Tous les badges obtenus pendant votre utilisation de l'application
            </p>
        </v-card>
        <div>
            <v-row v-if="badges.length > 0" justify="center" class="d-flex justify-content-center pb-5">
                <v-col v-for="badge in badges" :key="badge.id" cols="auto" class="pb-5">
                    <v-card :elevation="11" height="90" width="90" class="m-3 rounded-xl"
                        @click="openModalExplication(badge)">
                        <img :src="badge.image" class="rounded-xl" width="90" />
                        <p class="lead font-weight-bold small text-center mt-1">
                            {{ badge.libelle }}
                        </p>
                    </v-card>
                </v-col>
            </v-row>
            <div v-else class="text-center text-h6 p-5">
                Vous n'avez pas encore obtenu de badges !
            </div>
        </div>
        <v-dialog v-model="dialogExplication">
            <v-card class="mx-auto" max-width="344" outlined>
                <v-list-item three-line>
                    <v-list-item-content>
                        <div class="text-overline mb-4 lead">Explication du badge</div>
                        <v-list-item-title class="text-h5 mb-3 text-color-main text-font-weight-bold"
                            style="font-size: 20px">
                            {{ selectBadge.libelle }}
                        </v-list-item-title>
                        <v-list-item-subtitle class="text-break text-wrapper mb-5" style="font-size: 14px">{{
                        selectBadge.explications }}</v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-avatar tile size="80" color="grey">
                        <img :src="selectBadge.image" width="90" />
                    </v-list-item-avatar>
                </v-list-item>

                <v-card-actions class="d-flex justify-content-end">
                    <v-btn rounded class="bg-color-secondaire" @click="dialogExplication = false">
                        Retour
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogMDP">
            <v-toolbar>
                <v-btn @click="dialogMDP = false" icon>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>
                    Je modifie mon mot de passe
                </v-toolbar-title>
            </v-toolbar>
            <v-card class="container">
                <v-form v-model="validMDP">
                    <v-text-field filled rounded v-model="user.password" :rules="requiredRules" type="password"
                        label="Mot de passe">
                    </v-text-field>
                    <v-text-field filled rounded v-model="confirmPassword" :rules="passwordConfirm" type="password"
                        label="Confirmer le mot de passe"></v-text-field>
                </v-form>
                <v-card-actions class="d-flex justify-content-end">
                    <v-btn v-show="notShowing" rounded :disabled="!valid" @click="updatePassword()"
                        class="bg-color-main text-light">
                        <v-icon left>mdi-check</v-icon>
                        Modifier le mot de passe
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            badges: [],
            user: [],
            selectBadge: [],
            valid: false,
            validMDP: false,
            notShowing: true,
            dialogMDP: false,
            dialogExplication: false,
            confirmPassword: "",
            requiredRules: [(v) => !!v || "Le champ est obligatoire"],
            numberRules: [
                (v) => !!v || "Le champ est obligatoire",
                (v) => !isNaN(v) || "Ce n'est pas un nombre",
            ],
            emailRules: [
                (v) => !!v || "Le champ doit être remplis",
                (v) => /.+@.+\..+/.test(v) || "L'adresse email est invalide",
            ],
            passwordConfirm: [
                (v) => this.validate(v) || "Les mot de passes ne concorde pas !",
            ],
        }
    },
    mounted() {
        this.getAllInformationsProfil()
    },
    methods: {
        validate() {
            if (this.user.password === this.confirmPassword) {
                return true;
            } else {
                return false;
            }
        },
        checkIfMDPFill() {
            if (
                this.user.password != "" &&
                this.confirmPassword != ""
            ) {
                return true;
            } else {
                return false;
            }
        },
        checkIfAllFill() {
            if (
                this.user.nom != "" &&
                this.user.prenom != "" &&
                this.user.email != ""
            ) {
                return true;
            } else {
                return false;
            }
        },
        getAllInformationsProfil() {
            this.$axios.get("/profil/monProfil").then((response) => {
                this.user = response.data;
            });
            this.$axios.get("/badges/index").then((response) => {
                this.badges = response.data;
            });
        },
        openModalExplication(badge) {
            this.selectBadge = badge;
            this.dialogExplication = true;
        },
        updateProfilInformation() {
            this.$axios.put("/profil/updateProfil", this.user).then((response) => {
                this.$swal("Bravo !", response.data.message, "success");
            });
        },
        updatePassword() {
            this.$axios.post("/profil/updatePassword", { password: this.user.password }).then((response) => {
                this.$swal("Bravo !", response.data.message, "success");
                this.dialogMDP = false
            });
        },
    },
}
</script>

<style>

</style>