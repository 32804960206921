import moment from "moment";
const functions = {
    install(Vue) {
        Vue.prototype.colsResponsive = () => {
            if (window.innerWidth < 800) {
                return 12;
            } else {
                return 6;
            }
        };
        Vue.prototype.formatDate = (value) => {
            return moment(value).format("LLL");
        };
        Vue.prototype.slugify = (value, separator) => {
            return value
                .toString()
                .normalize("NFD") // split an accented letter in the base letter and the acent
                .replace(/[\u0300-\u036f]/g, "") // remove all previously split accents
                .toLowerCase()
                .trim()
                .replace(/[^a-z0-9 ]/g, "") // remove all chars not letters, numbers and spaces (to be replaced)
                .replace(/\s+/g, separator);
        };
        Vue.prototype.stringify = (value) => {

            var words = value.split('-');

            for (var i = 0; i < words.length; i++) {
                var word = words[i];
                words[i] = word.charAt(0).toUpperCase() + word.slice(1);
            }

            return words.join(' ');

        }
        Vue.prototype.displayComputer = () => {
            if (window.innerWidth < 800) {
                return false;
            } else {
                return true;
            }
        }
    },
};

export default functions;