<template>
    <div>
        <div class="mx-auto" color="grey lighten-4" max-width="600">
            <div v-if="loader">
                <h1 class="text-center mt-5">Chargement de la boutique . . .</h1>
                <v-progress-linear color="deep-purple accent-4" indeterminate rounded height="6">
                </v-progress-linear>
            </div>
            <v-card-text v-else class="pt-6" style="position: relative">
                <h3 class="text-h4 text-center mb-5 mt-2">
                    Vous avez <br> <strong>{{ myCoins.toLocaleString() }}</strong> Coins !
                </h3>
                <v-btn class="white--text mb-5" :to="{ name: 'Je veux des coins' }" block id="button-cart" large top>
                    Je veux des Coins ! <v-icon>mdi-cart</v-icon>
                </v-btn>
                <v-btn rounded block class="bg-color-main text-white" @click="dialogCoutsCoins = true">
                    Tableau des coûts en coins <v-icon>mdi-hand-coin</v-icon>
                </v-btn>

                <v-divider></v-divider>
                <h1 class="mb-2 mt-5 text-center">
                    Historique de vos achats et dépenses
                </h1>
                <div>
                    <v-card class="mx-auto rounded-xl" style="overflow: scroll" max-width="400" tile>
                        <div class="overflow-auto">
                            <v-list disabled>
                                <v-subheader>Relevé des 5 dernières dépenses</v-subheader>
                                <v-list-item-group v-model="selectedItem" v-if="transactions.length > 0"
                                    color="primary">
                                    <v-list-item v-for="item in transactions" :key="item.id">
                                        <v-list-item-icon>
                                            <v-icon v-if="item.type == 'Crediter'" class="text-success">
                                                mdi-arrow-up-bold</v-icon>
                                            <v-icon v-else class="text-danger">mdi-arrow-down-bold</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content v-if="item.type == 'Debiter'">
                                            <v-list-item-title>
                                                <strong><span style="font-size: 20px; color: red">-{{
                                                item.somme.toLocaleString() }}</span>
                                                    Coins
                                                </strong>
                                            </v-list-item-title>
                                            <v-list-item-subtitle>{{ item.model }} </v-list-item-subtitle>
                                            <p class="font-italic small">{{ item.date }}</p>
                                        </v-list-item-content>
                                        <v-list-item-content v-else>
                                            <v-list-item-title>
                                                <strong><span style="font-size: 20px; color: green">+{{ item.somme
                                                }}</span>
                                                    Coins
                                                </strong>
                                            </v-list-item-title>
                                            <v-list-item-subtitle>{{ item.model }}</v-list-item-subtitle>
                                            <p class="font-italic small">{{ item.date }}</p>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list-item-group>
                                <div v-else class="text-center m-3">
                                    Aucun historique pour le moment
                                </div>
                            </v-list>
                        </div>
                    </v-card>
                </div>
            </v-card-text>
        </div>
        <v-dialog v-model="dialogCoutsCoins">
            <v-card>
                <v-toolbar>
                    <v-btn icon @click="dialogCoutsCoins = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>
                        Récapitulatif des coûts
                    </v-toolbar-title>
                </v-toolbar>
                <div class="container p-4">

                    <ul>
                        <li>
                            Devis:
                            <strong style="color: #bf00ff" class="fw-bold">1</strong>
                            Coins
                        </li>
                        <li>
                            Commande:
                            <strong style="color: #bf00ff" class="fw-bold">1</strong> Coins
                        </li>
                        <li>
                            Facture:<strong style="color: #bf00ff" class="fw-bold">
                                0.0015% du montant HT de votre facture</strong>
                        </li>
                        <li>
                            Un fournisseur :
                            <strong style="color: #bf00ff" class="fw-bold">100</strong>
                            Coins
                        </li>
                        <li>
                            Un contact :
                            <strong style="color: #bf00ff" class="fw-bold">1</strong>
                            Coins
                        </li>
                        <li>
                            Un utilisateur:
                            <strong style="color: #bf00ff" class="fw-bold">350</strong>
                            Coins
                        </li>
                        <li>
                            Un point de vente :
                            <strong style="color: #bf00ff" class="fw-bold">40</strong>
                            Coins
                        </li>
                    </ul>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
/* eslint-disable */
export default {
    data() {
        return {
            dialogCoutsCoins: false,
            hover: false,
            selectedItem: "",
            loader: true,
            coins: 0,
            transactions: [],
            items: [
                { text: "Real-Time", icon: "mdi-clock" },
                { text: "Audience", icon: "mdi-account" },
                { text: "Conversions", icon: "mdi-flag" },
            ],
        };
    },
    computed: {
        myCoins() {
            if (this.$store.getters['coin/myCoins']) {
                return this.$store.getters['coin/myCoins']
            } else {
                return 0
            }

        },
    },
    mounted() {
        this.getMySoldeCoins();
        this.myTransactions();
    },
    methods: {
        getMySoldeCoins() {
            this.$store.dispatch('coin/getMyCoins')
        },
        myTransactions() {
            this.$axios.get("transactions/myTransactions").then((response) => {
                this.transactions = response.data;
                this.loader = false
            });
        },
    },
};
</script>

<style>
.v-card--reveal {
    align-items: center;
    bottom: 0;
    justify-content: center;
    opacity: 0.5;
    position: absolute;
    width: 100%;
}
</style>
<style scoped>
#button-cart {
    background-color: #1c87c9;
    -webkit-border-radius: 60px;
    border-radius: 60px;
    border: none;
    color: #eeeeee;
    cursor: pointer;
    display: inline-block;
    font-family: sans-serif;
    font-size: 20px;
    padding: 10px 10px;
    text-align: center;
    text-decoration: none;
}

@keyframes glowing {
    0% {
        background-color: #2ba805;
        box-shadow: 0 0 5px #2ba805;
    }

    50% {
        background-color: #49e819;
        box-shadow: 0 0 20px #49e819;
    }

    100% {
        background-color: #2ba805;
        box-shadow: 0 0 5px #2ba805;
    }
}

#button-cart {
    animation: glowing 1300ms infinite;
}
</style>
