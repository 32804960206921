/* eslint-disable */
import Vue from "vue";
import axios from "axios";
import router from "/src/router";
export default {
    namespaced: true,
    state: {
        contacts: [],
        maxContacts: 0,
        contactFilter: [],
    },
    mutations: {
        initContacts(state, datas) {
            state.contacts = datas.contacts;
            state.maxContacts = datas.maxContacts;
            state.contactFilter = datas.contactFilter;
        },
        setContactFilter(state, datas) {
            state.contactFilter = datas;
        },
    },
    getters: {
        getContacts(state) {
            return state.contacts;
        },
        getMaxContacts(state) {
            return state.maxContacts;
        },
        getContactsForFiltre(state) {
            return state.contactFilter;
        },
        storeContact(state, data) {
            state.contacts.push(data);
            state.contactFilter.push(data);
        },
        updateContact(state, data) {
            state.contactFilter = state.contactFilter.map((contact) => {
                if (contact.id === data.id) {
                    return Object.assign({}, contact, data);
                }
            });
            state.contacts = state.contacts.map((contact) => {
                if (contact.id === data.id) {
                    return Object.assign({}, contact, data);
                }
            });
        },
    },
    actions: {
        setContacts({ commit }, payload) {
            switch (payload.mode) {
                case "alphabetic":
                    axios
                        .get("contact/index/alphabetic/" + payload.limitTimeLine)
                        .then((response) => {
                            var datas = {
                                contacts: response.data.contacts,
                                contactFilter: response.data.contacts,
                                maxContacts: response.data.maxContacts,
                            };
                            commit("initContacts", datas);
                        })
                        .catch(function(error) {
                            if (error.response.status === 401) {
                                setTimeout(() => {
                                    router.push({
                                        name: "Connexion",
                                    });
                                }, 900);
                            }
                        });
                    break;
                case "lastcreate":
                    axios
                        .get("contact/index/time/" + payload.limitTimeLine)
                        .then((response) => {
                            var datas = {
                                contacts: response.data.contacts,
                                contactFilter: response.data.contacts,
                                maxContacts: response.data.maxContacts,
                            };
                            commit("initContacts", datas);
                        })
                        .catch(function(error) {
                            if (error.response.status === 401) {
                                setTimeout(() => {
                                    router.push({
                                        name: "Connexion",
                                    });
                                }, 900);
                            }
                        });
                    break;
                case "geoloc":
                    axios
                        .post("contact/index/geoloc/" + payload.limitTimeLine, payload.pos)
                        .then((response) => {
                            var datas = {
                                contacts: response.data.contacts,
                                contactFilter: response.data.contacts,
                                maxContacts: response.data.maxContacts,
                            };
                            commit("initContacts", datas);
                        })
                        .catch(function(error) {
                            if (error.response.status === 401) {
                                setTimeout(() => {
                                    router.push({
                                        name: "Login",
                                    });
                                }, 1200);
                            }
                        });
                    break;
                default:
                    break;
            }
        },
        searchContact({ commit }, val) {
            axios
                .get("contact/search/" + val)
                .then((res) => {
                    commit("setContactFilter", res.data);
                })
                .catch(function(error) {
                    if (error.response.status === 401) {
                        setTimeout(() => {
                            router.push({
                                name: "Connexion",
                            });
                        }, 900);
                    }
                });
        },
        setContactFilter({ commit, getters }) {
            commit("setContactFilter", getters.getContacts);
        },
        storeContact({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios.post("contact/store", payload).then((response) => {
                    if (response.data.messageError) {
                        resolve(response);
                    }
                    commit("storeContact", response.data.element);
                    resolve(response);
                });
            });
        },
    },
};