<template>
    <div>
        <form-commande :idContact="(this.idContact) ? this.idContact : this.$route.params.idContact" :mode="mode" :contact="contact"></form-commande>
    </div>
</template>

<script>
import FormCommande from '@/components/ventes/commande/FormCommande.vue'
export default {
    components: { FormCommande },
    props: ['mode', "idContact", "contact"],
    mounted() {
        if(!this.contact) {
            this.$router.go(-1)
        }
        window.onbeforeunload = function () {
            return "Vous allez perdre les information du commande si vous recharger la page.";
        }
    },
}
</script>

<style>

</style>