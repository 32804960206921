/* eslint-disable */
import Vue from 'vue'
import axios from 'axios'
import router from "/src/router";

export default {
    namespaced: true,
    state: {
        fournisseurs: [],
        totalPaginate: 0,
    },
    mutations: {
        initFournisseurs(state, datas) {
            state.fournisseurs = datas
        },
        storeFournisseur(state, data) {
            state.fournisseurs.push(data)
        },
        updateFournisseur(state, data) {
            var index = state.fournisseurs.findIndex(f => f.id === data.id);
            Vue.set(state.fournisseurs, index, data);
        },
        deleteFournisseur(state, id) {
            var index = state.fournisseurs.findIndex(fournisseur => fournisseur.id == id)
            state.fournisseurs.splice(index, 1)
        },
        setTotalPaginate(state, data) {
            state.totalPaginate = data
        }
    },
    getters: {
        getFournisseurs(state) {
            return state.fournisseurs
        },
        getTotalPaginate(state) {
            return state.totalPaginate
        }
    },
    actions: {
        setFournisseurs({ commit }, page) {
            axios
                .get("fournisseurs/index?page=" + page)
                .then((response) => {
                    commit('initFournisseurs', response.data.data)
                    commit('setTotalPaginate', response.data.last_page)
                }).catch(function(error) {
                    if (error.response.status === 401) {
                        setTimeout(() => {
                            router.push({
                                name: "Connexion"
                            });
                        }, 900);
                    }
                });
        },
        updateFournisseur({ commit }, data) {
            commit('updateFournisseur', data)
        },
        storeFournisseur({ commit }, data) {
            commit('storeFournisseur', data)
        },
        deleteFournisseur({commit}, id) {
            commit('deleteFournisseur', id)
        }
    }
}