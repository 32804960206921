<template>
    <div>
        <v-form v-model="valid">
            <input type="hidden" v-model="editedItem.id" />
            <v-col cols="12">
                <vTextInput libelle="Libelle" clef="libelle" class="mt-6" id="typerdvs-libelle" rulesInfo="required"
                    :value="editedItem.libelle" placeholder="R1.." :rules="[(v) => !!v || 'Le champ est obligatoire']"
                    type="typerdvs" icon="mdi-form-textbox"></vTextInput>

                <VPickerColor clef="bgColor" class="mt-6" id="typerdvs-bgColor" rulesInfo="required"
                    :value="editedItem.bgColor" label="Couleur de l'événement" type="typerdvs" icon="mdi-form-textbox">
                </VPickerColor>

                <VPickerColor clef="textColor" class="mt-6" id="typerdvs-textColor" rulesInfo="required"
                    :value="editedItem.textColor" label="Couleur du texte de l'événement" type="typerdvs"
                    icon="mdi-form-textbox"></VPickerColor>

                <VBoxCheck clef="hasClientNeeded" class="mt-6" id="typerdvs-hasClientNeeded" rulesInfo="required"
                    :value="editedItem.hasClientNeeded" label="Client Obligatoire ?" type="typerdvs"
                    icon="mdi-form-textbox"></VBoxCheck>

            </v-col>
            <v-card-actions>
                <v-spacer></v-spacer>
                <vBtnEdit nom="Sauvegarder" v-if="validateForm() && typerdv.id" :valid="valid" icon="mdi-content-save"
                    @saveForm="returnTo" :onlyIcon="false" :id="editedItem.id" table="typerdvs"></vBtnEdit>
                <VBtnSave v-else nom="Créer" :valid="valid" icon="mdi-plus" @saveForm="returnTo" :onlyIcon="false"
                    table="typerdvs"></VBtnSave>
                <v-btn class="m-1" text @click="closeModal"> Annuler </v-btn>
            </v-card-actions>
        </v-form>
    </div>
</template>
  
<script>

export default {

    props: ["editedItem", "typeCRUD"],
    data() {
        return {
            valid: true,
            requiredRules: [(v) => !!v || "Le champ est obligatoire"],
        };
    },
    computed: {
        typerdv() {
            return this.$store.getters["formulaire/getFormType"]("typerdvs")
        }
    },
    methods: {
        validateForm() {
            if (this.typerdv.libelle != "" && this.typerdv.bgColor && this.typerdv.textColor) {
                return true;
            } else {
                return false;
            }
        },
        closeModal() {
            this.$emit("close");
        },
        returnTo(typerdvStored) {
            if (this.typeCRUD == "edit") {
                this.$store.dispatch("typeRdv/updateTypeRdv", typerdvStored);
            } else {
                this.$store.dispatch("typeRdv/storeTypeRdv", typerdvStored);
            }

            this.closeModal()
        },
    },
};
</script>
  
<style>

</style>