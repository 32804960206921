<template>
  <div>
    <v-item-group>
      <v-container class="text-center">
        <v-row>
          <v-col cols="12" md="4">
            <v-item v-slot="{ active, toggle }">
              <v-card @click="toggle" :style="active ? 'background-color:#FF7900;' : ''" class="p-4" height="200">
                <div class="text-center mt-5" v-if="!active">
                  <h1>Pièce</h1>
                  <strong class="p-3">2.000 Coins</strong> <br />
                  <strong style="font-size: 25px; color:#0A70FF">60€ </strong>euros HT
                </div>
                <v-scroll-y-transition>
                  <div v-if="active" class="
                        text-center
                        font-weight-bold
                        p-2
                        text-dark
                        text-break
                      ">
                    <p class="mb-3">Pour confirmer votre achat veuillez cliquer sur le bouton
                      ci-dessous ! </p>
                    <StripeCheckout ref="checkoutRef" mode="payment" :pk="publishableKey" :line-items="pack2000"
                      :success-url="successURL" :cancel-url="cancelURL" :customerEmail="authUser('email')" />
                    <button class="btn text-white bg-color-main" @click="submit">
                      Payer pour <strong>2.000 Coins</strong> !
                    </button>
                  </div>
                </v-scroll-y-transition>
              </v-card>
            </v-item>
          </v-col>
          <v-col cols="12" md="4">
            <v-item v-slot="{ active, toggle }">
              <v-card @click="toggle" :style="active ? 'background-color:#FF7900;' : ''" class="p-4" height="200">
                <div v-if="!active" class="text-center mt-5">
                  <h1>Monney</h1>
                  <strong class="p-3">5.000 Coins + 1.000 bonus</strong>
                  <br />
                  <strong style="font-size: 25px; color:#0A70FF">150€ </strong>euros HT
                </div>
                <v-scroll-y-transition>
                  <div v-if="active" class="
                        text-center
                        font-weight-bold
                        p-2
                        text-dark
                        text-break
                      ">
                    <p class="mb-3">Pour confirmer votre achat veuillez cliquer sur le bouton
                      ci-dessous ! </p>
                    <StripeCheckout ref="checkoutRef" mode="payment" :pk="publishableKey" :line-items="pack5000"
                      :success-url="successURL" :cancel-url="cancelURL" :customerEmail="authUser('email')" />
                    <button class="btn text-white bg-color-main" @click="submit">
                      Payer pour <strong>5.000 Coins</strong> !
                    </button>
                  </div>
                </v-scroll-y-transition>
              </v-card>
            </v-item>
          </v-col>
          <v-col cols="12" md="4">
            <v-item v-slot="{ active, toggle }">
              <v-card @click="toggle" :style="active ? 'background-color:#FF7900;' : ''" class="p-4" height="200">
                <div v-if="!active" class="text-center mt-5">
                  <h1>Trésor</h1>
                  <strong class="p-3">10.000 Coins + 3.000 bonus</strong>
                  <br />
                  <strong style="font-size: 25px; color:#0A70FF">300€ </strong>euros HT
                </div>
                <v-scroll-y-transition>
                  <div v-if="active" class="
                        text-center
                        font-weight-bold
                        p-2
                        text-dark
                        text-break
                      ">
                    <p class="mb-3">Pour confirmer votre achat veuillez cliquer sur le bouton
                      ci-dessous !</p>
                    <StripeCheckout ref="checkoutRef" mode="payment" :pk="publishableKey" :line-items="pack10000"
                      :success-url="successURL" :cancel-url="cancelURL" :customerEmail="authUser('email')" />
                    <button class="btn text-white bg-color-main" @click="submit">
                      Payer pour <strong>10.000 Coins</strong> !
                    </button>
                  </div>
                </v-scroll-y-transition>
              </v-card>
            </v-item>
          </v-col>
          <v-col cols="12" md="4">
            <v-item v-slot="{ active, toggle }">
              <v-card @click="toggle" :style="active ? 'background-color:#FF7900;' : ''" class="p-4" height="200">
                <div v-if="!active" class="text-center mt-5">
                  <h1>Pactole</h1>
                  <strong class="p-3">20.000 Coins + 7.000 bonus</strong>
                  <br />
                  <strong style="font-size: 25px; color:#0A70FF">600€ </strong>euros HT
                </div>
                <v-scroll-y-transition>
                  <div v-if="active" class="
                        text-center
                        font-weight-bold
                        text-dark
                        p-2
                        text-break
                      ">
                    <p class="mb-3">Pour confirmer votre achat veuillez cliquer sur le bouton
                      ci-dessous ! </p>
                    <StripeCheckout ref="checkoutRef" mode="payment" :pk="publishableKey" :line-items="pack20000"
                      :success-url="successURL" :cancel-url="cancelURL" :customerEmail="authUser('email')" />
                    <button class="btn text-white bg-color-main" @click="submit">
                      Payer pour <strong>20.000 Coins</strong> !
                    </button>
                  </div>
                </v-scroll-y-transition>
              </v-card>
            </v-item>
          </v-col>
          <v-col cols="12" md="4">
            <v-item v-slot="{ active, toggle }">
              <v-card @click="toggle" :style="active ? 'background-color:#FF7900;' : ''" class="p-4" height="200">
                <div v-if="!active" class="text-center mt-5">
                  <h1>Gold Bars</h1>
                  <strong class="p-3">50.000 Coins + 20.000 bonus</strong>
                  <br />
                  <strong style="font-size: 25px; color:#0A70FF">1.500€ </strong>euros HT
                </div>
                <v-scroll-y-transition>
                  <div v-if="active" class="
                        text-center
                        font-weight-bold
                        p-2
                        text-break
                        text-dark
                      ">
                    <p class="mb-3">Pour confirmer votre achat veuillez cliquer sur le bouton
                      ci-dessous !</p>
                    <StripeCheckout ref="checkoutRef" mode="payment" :pk="publishableKey" :line-items="pack50000"
                      :success-url="successURL" :cancel-url="cancelURL" :customerEmail="authUser('email')" />
                    <button class="btn text-white bg-color-main" @click="submit">
                      Payer pour <strong>50.000 Coins</strong> !
                    </button>
                  </div>
                </v-scroll-y-transition>
              </v-card>
            </v-item>
          </v-col>
        </v-row>
      </v-container>
    </v-item-group>
  </div>
</template>

<script>
/* eslint-disable */
import variableGlobalMixin from "@/mixins/variableGlobalMixin.js"
import { StripeCheckout } from "@vue-stripe/vue-stripe";
export default {
  mixins: [variableGlobalMixin],
  components: {
    StripeCheckout,
  },
  data() {
    this.publishableKey =
      "pk_test_51JU5wpBqoAbQTNmE6RnSXZCpVEeL8uM86hr5osP6aMTT64dzkVRc9XbXLbbMBphelMcZD2ewlXbb8IhXMZAfqwiR00QtvEtjyO";
    return {
      user: [],
      loading: false,
      pack2000: [
        {
          price: "price_1JU9gBBqoAbQTNmEL4Wt4GmA", // The id of the one-time price you created in your Stripe dashboard
          quantity: 1,
        },
      ],
      pack5000: [
        {
          price: "price_1JU9hgBqoAbQTNmExxQh4Ew7", // The id of the one-time price you created in your Stripe dashboard
          quantity: 1,
        },
      ],
      pack10000: [
        {
          price: "price_1JU9iABqoAbQTNmE3wE9BKpM", // The id of the one-time price you created in your Stripe dashboard
          quantity: 1,
        },
      ],
      pack20000: [
        {
          price: "price_1JU9imBqoAbQTNmEXrML4GyK",
          quantity: 1,
        },
      ],
      pack50000: [
        {
          price: "price_1JU9jCBqoAbQTNmEPumsQNz0",
          quantity: 1,
        },
      ],
      successURL: "https://xn--m-kha.fr/mycoins/achat/payment_success",
      cancelURL: "https://xn--m-kha.fr/mycoins/achat/payment_failed",
    };
  },
  mounted() {

  },
  methods: {
    submit() {
      // You will be redirected to Stripe's secure checkout page
      this.$refs.checkoutRef.redirectToCheckout();
    },
    achat(mycoins) {
      switch (mycoins) {
        case 5000:
          this.$axios
            .get("/coins/crediterCoins/" + mycoins)
            .then((response) => {
              this.$swal("Félicitation !", response.data.message, "success");
            });
          break;

        case 13000:
          this.$axios
            .get("/coins/crediterCoins/" + mycoins)
            .then((response) => {
              this.$swal("Félicitation !", response.data.message, "success");
            });
          break;

        case 30000:
          this.$axios
            .get("/coins/crediterCoins/" + mycoins)
            .then((response) => {
              this.$swal("Félicitation !", response.data.message, "success");
            });
          break;

        default:
          break;
      }
    },
  }
}
</script>

<style>

</style>