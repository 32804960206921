var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticStyle:{"right":"-10px"}},[(_vm.areYouEnoughCoins(350) || !_vm.showIsSubscriber())?_c('vBtnAdd',{staticClass:"mb-2 mt-3 mr-5 ml-2",attrs:{"classItems":'bg-color-main text-white',"nom":"Ajouter un utilisateur","table":"crud","icon":"mdi-plus"},on:{"openModal":_vm.openModal}}):_vm._e()],1),_c('div',{staticClass:"float-bottom"},[_c('VTableData',{attrs:{"items":_vm.items,"loader":_vm.loader,"headers":[
          {
            text: 'Nom',
            align: 'left',
            sortable: true,
            value: 'nom',
          },
          {
            text: 'Prénom',
            align: 'left',
            sortable: true,
            value: 'prenom',
          },
          {
            text: 'Email',
            align: 'left',
            sortable: true,
            value: 'email',
          },
          {
            text: 'Point de vente',
            align: 'left',
            sortable: true,
            value: 'agence',
          },
          {
            text: 'Pourcentage de commission perso',
            align: 'left',
            sortable: true,
            value: 'pourcentage_commission_perso',
          },
          {
            text: 'Pourcentage de commission societe',
            align: 'left',
            sortable: true,
            value: 'pourcentage_commission_societe',
          },

        ],"hiddenFooter":true,"hiddenHeader":false,"total":_vm.total},on:{"triggerRowTable":_vm.editItem,"pageChange":_vm.pageChange}}),(_vm.dialog)?_c('vModalCrud',{attrs:{"deleteStoreFunction":"users/deleteUser","items":_vm.items,"itemEdited":_vm.editedItem,"formTitle":_vm.formTitle,"typeCRUD":_vm.typeCRUD,"typeForm":'FormUser',"type":"user"},on:{"closeDialog":_vm.close}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }