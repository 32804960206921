<template>
  <div class="mx-auto my-auto container mt-5">
    <v-card :min-width="minWidth" :elevation="11" class="p-5 rounded-xl mx-auto my-auto">
      <div class="d-flex justify-content-center">
        <v-btn  @click="createAccountEnterprise()" style="color:black;" rounded class="text-center fw-bold bg-color-secondaire mb-5"><v-icon>mdi-account-tie</v-icon>Création d'un compte gérant</v-btn>
      </div>
      <v-form ref="form" class="mt-5" v-model="valid" lazy-validation>
        <label class="fs-6">Email : </label>
        <v-text-field v-model="form.email" :error-messages="msgError" :rules="emailRules" label="exemple@exemple.com"
          required filled rounded prepend-inner-icon="mdi-email"></v-text-field>
        <label class="fs-6">Mot de passe : </label>
        <v-text-field filled rounded prepend-inner-icon="mdi-form-textbox-password" v-model="form.password"
          :error-messages="msgError" :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'" :type="show ? 'text' : 'password'"
          name="password" label="Taper votre mot de passe" @click:append="show = !show" required></v-text-field>
        <v-row>
          <v-col>
            <v-checkbox class="fw-bold fs-6" v-model="rememberMe" label="Se souvenir de moi ?"></v-checkbox>
          </v-col>
        </v-row>
        <div class="d-flex justify-content-center">
          <v-btn :disabled="!valid" rounded block class="fm-4 mt-3 bg-color-main text-white" @click="submit()">
            Connexion
          </v-btn>
        </div>
      </v-form>
      <v-card-actions class="mt-5">
        <v-btn text class="fw-bold" style="font-size: 10px;" @click="sendPasswordLink()">
          <u>Mot de passe oublié ?</u>
        </v-btn>
      </v-card-actions>

    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      valid: true,
      rememberMe: false,
      msgError: null,
      emailRules: [
        (v) => !!v || "Le champ doit être remplis",
        (v) => /.+@.+\..+/.test(v) || "L'adresse email est invalide",
      ],
      show: false,
      form: {
        email: null,
        password: null,
      },
    };
  },
  watch: {
    rememberMe(val) {
      if (val) {
        localStorage.setItem("emailMy", this.form.email);
        localStorage.setItem("rememberMeMy", this.rememberMe);
      } else {
        localStorage.removeItem("emailMy", this.form.email);
        localStorage.removeItem("rememberMeMy", this.rememberMe);
      }
    },
    form: {
      deep: true,
      handler(newValue) {
        if (newValue) {
          this.msgError = "";
        }
      },
    },
  },
  mounted() {
    this.form.email = localStorage.getItem("emailMy");
    this.rememberMe = localStorage.getItem("rememberMeMy");
  },
  computed: {
    minWidth() {
      if (window.innerWidth > 1200) {
        return 600;
      } else {
        return 300;
      }
    },
  },
  methods: {
    submit() {
      this.$store.dispatch("auth/login", this.form).then((response) => {
          if (response.data.messageError) {
            this.$swal("Oups !", response.data.messageError, "error").then(
              () => {
                this.msgError = response.data.messageError;
              }
            );
            return;
          }
          this.$router.push({ name: "Accueil" }).then(() =>  {
            document.location.reload()
          })
      });
    },
    sendPasswordLink() {
      this.$router.push({ name: "J'ai oublié mon mot de passe" }).catch((error) => {
        console.log(error);
      });
    },
    createAccountEnterprise() {
      this.$router.push({name: "Je crée mon compte d'entreprise"})
    }
  },
};
</script>

<style scoped>
.v-messages__message {
  color: red !important;
  font-size: 16px !important;
}
</style>