<template>
  <div>
    <v-card class="mt-5" v-if="!dataLoad" style="height:100%">
      <v-container style="height:100%">
        <v-row class="fill-height" align-content="center" justify="center">
          <v-col class="text-subtitle-1 text-center" cols="12">
            Chargement de votre rendez-vous
          </v-col>
          <v-col cols="6">
            <v-progress-linear color="deep-purple accent-4" indeterminate rounded height="6"></v-progress-linear>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <FormCalendar v-else :eventSelected="eventSelected"></FormCalendar>
  </div>
</template>

<script>

import FormCalendar from "@/components/rdv/FormCalendar.vue";
export default {
  props: ["id", "contact"],
  components: {
    FormCalendar,

  },
  data() {
    return {
      eventSelected: {
        contact: []
      },
      dataLoad: false,
    };
  },
  mounted() {
    this.getSelectedEvent();

  },
  methods: {
    getSelectedEvent() {
      this.$axios.get("events/show/" + this.id).then((response) => {
        this.eventSelected = response.data;
        this.dataLoad = true;
        var contactId = (this.contact) ? this.contact.id : this.eventSelected.contact_id
        this.eventSelected.contact_id = contactId
        if (this.contact) {
          this.eventSelected.contact = this.contact.prenom + ' ' + this.contact.nom
        }

        this.$store.dispatch("formulaire/initForm", {
          form: "events",
          listFields: {
            start: this.eventSelected.start,
            end: this.eventSelected.end,
            name: this.eventSelected.name,
            id: this.eventSelected.id,
            typerdv_id: this.eventSelected.typerdv_id,
            commentaires: this.eventSelected.commentaires,
            adresse: this.eventSelected.adresse,
            contact_id: this.eventSelected.contact_id,
            user: [this.eventSelected.user],
            annuler: this.eventSelected.annuler,
            raisonAnnulation: this.eventSelected.raisonAnnulation,
          },
        });
      });
    },
  },
};
</script>

<style>
</style>