/* eslint-disable */
import Vue from "vue";
import store from "@/store/index.js";
export default {
    data() {
        return {
            role: "",
            entreprise: [],
            loaderView: true,
        }
    },
    computed: {
        showAuthenticatedThing() {
            return !!store.getters["auth/authenticated"];
        },
        loaderMixin() {
            return this.loaderView
        }
    },
    mounted() {
        var refreshIntervalId = setInterval(() => {
            if (this.$store.getters["auth/dataLoad"]) {
                this.loaderView = false
                this.role = store.getters["auth/role"];
                this.entreprise = store.getters['auth/user']['entreprise']
                clearInterval(refreshIntervalId);
            }
        }, 1000);
    },
    methods: {
        showManagerThing() {
            if (this.role == "Manager") {
                return true;
            } else {
                return false;
            }
        },
        showIsSubscriber() {
            if (this.entreprise.isSubscriber) {
                return false;
            } else {
                return true;
            }
        },
        showRespAgenceThing() {
            if (this.role == "Responsable agence") {
                return true;
            } else {
                return false;
            }
        }


    }
};