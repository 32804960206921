var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"float-bottom"},[_c('VTableData',{attrs:{"items":_vm.items,"loader":_vm.loader,"headers":[
          {
            text: 'Titre du modèle',
            align: 'left',
            sortable: true,
            value: 'titre',
          }, {
            text: 'Type de vente',
            align: 'left',
            sortable: true,
            value: 'typeVente',
          },
        ],"hiddenFooter":true,"hiddenHeader":false,"total":_vm.total},on:{"triggerRowTable":_vm.editItem,"pageChange":_vm.pageChange}})],1),_c('v-dialog',{model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v("Que voulez-vous faire ?")])],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{staticClass:"bg-color-main p-4 text-center",attrs:{"dark":""},on:{"click":function($event){return _vm.watchModel()}}},[_c('v-icon',[_vm._v(" mdi-eye-settings")]),_vm._v(" Afficher le modèle ")],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{staticClass:"bg-danger p-4 text-center",attrs:{"dark":""},on:{"click":function($event){return _vm.deleteItem()}}},[_c('v-icon',[_vm._v(" mdi-delete")]),_vm._v(" Supprimer le modèle ")],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }