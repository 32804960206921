<template>
    <div>
        <form-devis :devisReceived="devis" :idContact="(this.idContact) ? this.idContact : this.$route.params.idContact" v-if="loadContact" :mode="mode" :contact="contact"></form-devis>
    </div>
</template>

<script>
import FormDevis from '@/components/ventes/devis/FormDevis.vue'
export default {
    components: { FormDevis },
    props: ['mode', "idVente", "typeVente", "idContact"],
    data() {
        return {
            contact: [],
            devis: [],
            loadContact: false
        }
    },
    mounted() {
        window.onbeforeunload = function () {
            return "Vous allez perdre les information du devis si vous recharger la page.";
        }
        this.getInformationsEditVente()
    },
    methods: {
        getInformationsEditVente() {
            this.$axios.get('/devis/read/'+ this.idVente).then((response) => {
                this.contact = response.data.contact
                this.devis = response.data
                this.loadContact = true
            })
        }
    },  
}
</script>

<style>

</style>