<template>
  <div>
    <v-select :clearable="clear" :disabled="disabled" v-if="noTextValue" v-model="modelValue" :items="items" menu-props="auto" :label="label" :rules="rules"
      :name="clef" hide-details :loader="loader" filled rounded :placeholder="placeholder" :prepend-inner-icon="icon"
      single-line>
      <template v-slot:label v-if="rulesInfo == 'required'">
        <span class="input__label">{{ libelle }} <span style="color: red">*</span></span>
      </template>
      <template v-slot:label v-else>
        <span class="input__label">{{ libelle }}</span>
      </template>
      <template v-slot:first> -- Choisissez une option -- </template>
    </v-select>
    <v-select :clearable="clear" v-else :disabled="disabled" v-model="modelValue" :items="items" menu-props="auto" :label="label" :rules="rules" :name="clef"
      hide-details :loader="loader" filled rounded :item-text="itemText" :item-value="itemValue"
      :placeholder="placeholder" :prepend-inner-icon="icon" single-line>
      <template v-slot:label v-if="rulesInfo == 'required'">
        <span class="input__label">{{ libelle }} <span style="color: red">*</span></span>
      </template>
      <template v-slot:label v-else>
        <span class="input__label">{{ libelle }}</span>
      </template>
      <template v-slot:first> -- Choisissez une option -- </template>
    </v-select>

  </div>
</template>

<script>
export default {
  props: [
    "libelle",
    "disabled",
    "rulesInfo",
    "icon",
    "clear",
    "clef",
    "rules",
    "noTextValue",
    "type",
    "loader",
    "value",
    "placeholder",
    "label",
    "datas",
    "itemText",
    "itemValue",
  ],
  computed: {
    modelValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('value', val)
        this.$store.dispatch("formulaire/updateForm", {
          form: this.type,
          field: this.clef,
          value: val,
        });
      },
    },
    items() {
      return this.datas;
    },
  },
};
</script>

<style>

</style>