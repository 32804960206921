/* eslint-disable */
import Vue from "vue";
import axios from "axios";
import router from "/src/router";

export default {
    namespaced: true,
    state: {
        produits: [],
        produitsFilter: [],
        produitsMax: 0,
    },
    mutations: {
        setProduits(state, datas) {
            state.produits = datas;
            state.produitsFilter = datas;
        },
        setProduitsMax(state, datas) {
            state.produitsMax = datas;
        },
        storeProduit(state, data) {
            state.produits.push(data);
        },
        updateProduit(state, data) {
            var index = state.produits.findIndex((f) => f.id === data.id);
            Vue.set(state.produits, index, data);
        },
        deleteProduit(state, id) {
            var index = state.produits.findIndex((produit) => produit.id == id);
            state.produits.splice(index, 1);
        },
        setTotalPaginate(state, data) {
            state.totalPaginate = data
        }
    },
    getters: {
        getProduits(state) {
            return state.produits;
        },
        getProduitsFilter(state) {
            return state.produitsFilter;
        },
        getProduitsMax(state) {
            return state.produitsMax;
        },
        getTotalPaginate(state) {
            return state.totalPaginate
        },
    },
    actions: {
        setProduits({ commit }, page) {
            axios
                .get("produits/index?page=" + page)
                .then((response) => {
                    commit("setProduits", response.data.produits);
                    commit("setTotalPaginate", response.data.last_page)
                })
                .catch(function(error) {
                    if (error.response.status === 401) {
                        setTimeout(() => {
                            router.push({
                                name: "Connexion",
                            });
                        }, 900);
                    }
                });
        },
        setListProduits({ commit }, limitTimeLine) {
            return new Promise((resolve, reject) => {
                axios
                    .get("produits/ventes/listProduits/" + limitTimeLine)
                    .then((response) => {
                        commit("setProduits", response.data.produits);
                        commit("setProduitsMax", response.data.produitsMax);
                        resolve(response);
                    })
                    .catch(function(error) {
                        if (error.response.status === 401) {
                            setTimeout(() => {
                                router.push({
                                    name: "Connexion",
                                });
                            }, 900);
                        }
                    });
            });
        },
        updateProduit({ commit }, data) {
            commit("updateProduit", data);
        },
        storeProduit({ commit }, data) {
            commit("storeProduit", data);
        },
        deleteProduit({ commit }, id) {
            commit("deleteProduit", id);
        },
    },
};