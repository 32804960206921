<template>
    <div>
        <v-checkbox rounded filled :id="id" :rules="rules" :prepend-inner-icon="icon" v-model="modelValue"
            :placeholder="placeholder" :name="clef" :label="label"></v-checkbox>
    </div>
</template>

<script>
export default {
    props: [
        "id",
        "rulesInfo",
        "label",
        "icon",
        "rules",
        "clef",
        "value",
        "placeholder",
        "type",
    ],
    data() {
        return {};
    },
    computed: {
        modelValue: {
            get() {
                return this.value;
            },
            set(val) {
                this.$store.dispatch("formulaire/updateForm", {
                    form: this.type,
                    field: this.clef,
                    value: val,
                });
            },
        },
    },
}
</script>

<style>
</style>