<template>
  <div>
    <v-btn icon fab :class="this.classItems" :disabled="(showIsSubscriber()) ? ((areYouEnoughCoins(1)) ? false : true) : false" v-if="this.onlyIcon && !disabled" @click="add()">
      <v-icon>{{ this.icon }}</v-icon>
    </v-btn>
    <v-btn v-else-if="!this.onlyIcon && !disabled" small rounded :class="this.classItems" @click="add()">
      <v-icon>{{ this.icon }}</v-icon>{{ this.nom }}
    </v-btn>
  </div>
</template>

<script>
export default {
  props: ["table", "nom", "classItems", "icon", "onlyIcon", "element", "typeCRUD", "fromScenario", 'id', 'typeVente', "disabled", 'idContact'],
  name: "Button",
  data() {
    return {
      payload: [],
      entreprise: []
    };
  },

  mounted() { 
  var refreshIntervalId = setInterval(() => {
      if (this.$store.getters["auth/dataLoad"]) {
        this.entreprise = this.$store.getters["auth/user"]['entreprise']
        clearInterval(refreshIntervalId);
      }
    }, 1000);
  },
  methods: {
    add() {
      switch (this.table) {
        case "contact":
          this.$router.push({ name: "Je crée un contact" });
          break;
        case "produit":
          this.$router.push({ name: "Je crée un produit", params: {  idContact: this.idContact ,receivedProduit: this.element, typeCRUD: this.typeCRUD, fromScenario: this.fromScenario, idVente: this.id, typeVente: this.typeVente } });
          break;
        case "crud":
          this.$emit("openModal");
          break;
        default:
          break;
      }
    },
    showIsSubscriber() {
      if (this.entreprise.isSubscriber) {
        return false;
      } else {
        return true;
      }
    },
   
  },
};
</script>

<style>

</style>