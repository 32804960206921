/* eslint-disable */
import Vue from 'vue'
import axios from 'axios'
import router from "/src/router";
export default {
    namespaced: true,
    state: {
        typeRdvs: [],
        totalPaginate: 1
    },
    mutations: {
        setTypeRdvs(state, datas) {
            state.typeRdvs = datas
        },
        storeTypeRdv(state, data) {
            state.typeRdvs.push(data)
        },
        updateTypeRdv(state, data) {
            var index = state.typeRdvs.findIndex(trdv => trdv.id === data.id);
            Vue.set(state.typeRdvs, index, data);
        },
        deleteTypeRdv(state, id) {
            var index = state.typeRdvs.findIndex(typerdv => typerdv.id == id)
            state.typeRdvs.splice(index, 1)
        },
        setTotalPaginate(state, data) {
            state.totalPaginate = data
        }
    },
    getters: {
        getTypeRdvs(state) {
            return state.typeRdvs
        },
        getTotalPaginate(state) {
            return state.totalPaginate
        }
    },
    actions: {
        setTypeRdvs({ commit }, page) {
            return new Promise((resolve, reject) => {
                axios.get('typerdvs/index?page=' + page).then((response) => {
                    commit('setTypeRdvs', response.data.typerdvs)
                    commit('setTotalPaginate', response.data.last_page)
                    resolve(response)
                }).catch(function(error) {
                    if (error.response.status === 401) {
                        setTimeout(() => {
                            router.push({
                                name: "Connexion"
                            });
                        }, 900);
                    }
                });
            })
        },
        updateTypeRdv({ commit }, data) {
            commit('updateTypeRdv', data)
        },
        storeTypeRdv({ commit }, data) {
            commit('storeTypeRdv', data)
        },
        deleteTypeRdv({ commit }, id) {
            commit('deleteTypeRdv', id)
        }
    }
}