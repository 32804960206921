import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import axios from "axios";
import store from "./store/index.js";
import moment from "moment";
import infiniteScroll from 'vue-infinite-scroll'
import VueSweetalert2 from 'vue-sweetalert2';
import DatetimePicker from 'vuetify-datetime-picker'
import Highcharts from 'highcharts'
import HighchartsVue from 'highcharts-vue'
import loadDrillDown from 'highcharts/modules/drilldown'
import VueMeta from 'vue-meta'
import 'sweetalert2/dist/sweetalert2.min.css';
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import '@/assets/styles/style.css'
// (Optional) import 'vuetify-datetime-picker/src/stylus/main.styl'
import "@/services/all-components";
import globals from "@/services/global-functions";
import globalFunctionMixin from "@/mixins/globalFunctionMixin.js";
import variableGlobalMixin from "@/mixins/variableGlobalMixin.js";
import viewMixin from "@/mixins/viewMixin.js";
import coinsMixin from "@/mixins/coinsMixin.js";
import '../public/manifest.json';

Vue.mixin(globalFunctionMixin);
Vue.mixin(coinsMixin);
Vue.mixin(variableGlobalMixin);
Vue.mixin(viewMixin);
Vue.use(globals);

export const bus = new Vue();

Vue.config.productionTip = false


Vue.use(infiniteScroll)
Vue.use(VueSweetalert2);
Vue.use(DatetimePicker)
Vue.use(HighchartsVue, {
    Highcharts
})

Vue.use(VueMeta)
loadDrillDown(Highcharts)

require("@/store/subscriber");



if (window.location.href.includes('http://localhost')) {
    axios.defaults.baseURL = "http://laramy/api/";
} else if (window.location.href.includes('https://devis.click')) {
    axios.defaults.baseURL = "https://api.devis.click/api/";
}

axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'
store.dispatch("auth/attempt", localStorage.getItem("tokenMy"));

moment.locale("fr");

Vue.prototype.$axios = axios
Vue.prototype.$store = store
Vue.prototype.$moment = moment


new Vue({
    router,
    store,
    axios,
    vuetify,
    render: h => h(App)
}).$mount('#app')