/* eslint-disable */
import axios from 'axios'
import Vue from 'vue'
export default {
    namespaced: true,
    state: {
        formulaire: [],
    },
    mutations: {
        initForm(state, datas) {
            var newForm = {}
            newForm['form'] = datas.form
            newForm['datas'] = datas.listFields
            if (state.formulaire.length == 0) {
                state.formulaire.push(newForm);
            } else {
                if (state.formulaire.find(type => type.form === datas.form)) {
                    state.formulaire.find(type => type.form === datas.form)['datas'] = datas.listFields
                } else {
                    state.formulaire.push(newForm);
                }
            }
        },
        deleteKeyForm(state, datas) {
            delete state.formulaire.find(type => type.form === datas.form)['datas'][datas.field]
        },
        updateForm(state, datas) {
            state.formulaire.find(type => type.form === datas.form)['datas'][datas.field] = datas.value
        },
    },
    getters: {
        getFormType: (state) => (typeForm) => {
            return state.formulaire.find(type => type.form === typeForm)['datas']
        },
        getFormulaire(state) {
            return state.formulaire
        }
    },
    actions: {
        updateForm({ commit }, payload) {
            commit('updateForm', payload)
        },
        initForm({ commit }, payload) {
            commit('initForm', payload)
        },
        storeData({ getters }, table) {
            return new Promise((resolve, reject) => {
                axios.post(table + '/store', getters.getFormType(table)).then((response) => {
                    if (response.data.messageError) {
                        resolve(response)
                    }
                    resolve(response)
                })
            })
        },
        updateData({ getters }, payload) {
            return new Promise((resolve, reject) => {
                axios.put(payload.table + '/update/' + payload.id, getters.getFormType(payload.table)).then((response) => {
                    if (response.data.messageError) {
                        resolve(response)
                    }
                    resolve(response)
                })
            })
        },
        deleteKeyForm({ commit }, payload) {
            commit('deleteKeyForm', payload)
        },
    }
}