var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',{staticClass:"text-center text-white mt-1 mb-3 p-3",staticStyle:{"background-color":"#088404"}},[_vm._v(" Liste des commande ")]),_c('VTableData',{attrs:{"items":_vm.items,"loader":_vm.loader,"headers":[
    {
      text: 'Titre',
      align: 'left',
      sortable: true,
      value: 'libelle',
    },
    {
      text: 'Montant TTC',
      align: 'left',
      sortable: true,
      value: 'montant_ttc',
    },
    { text: 'Date', value: 'date_commande' },
    { text: 'Etat du commande', value: 'etat_document_libelle' },
  ],"hiddenFooter":true,"hiddenHeader":false,"total":_vm.total,"type":"commande"},on:{"triggerRowTable":_vm.editItem,"pageChange":_vm.pageChange}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }