<template>
  <div>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left" v-for="header in headers" :key="header">
              {{ header }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr @click="editItem(item)" v-for="item in items" :key="item">
            <td>{{ item }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  props: ["items", "headers"],
  data() {
    return {};
  },
  mounted() {},
  methods: {
    editItem(item) {
      this.$emit("editItem", item);
    },
  },
};
</script>

<style>
</style>