<template>
    <div>
      <v-form v-model="valid">
        <input type="hidden" v-model="editedItem.id" />
        <v-col cols="12">
          <vTextInput
            libelle="Libelle"
            clef="libelle"
            class="mt-6"
            id="agences-libelle"
            rulesInfo="required"
            :value="editedItem.libelle"
            placeholder="Ma première agence ..."
            :rules="[(v) => !!v || 'Le champ est obligatoire']"
            type="agences"
            icon="mdi-form-textbox"
          ></vTextInput>
          <vTextInput
            libelle="Rue"
            clef="rue"
            class="mt-6"
            id="agences-rue"
            rulesInfo="required"
            :value="editedItem.rue"
            placeholder="5 Boulevard de la République.."
            :rules="[(v) => !!v || 'Le champ est obligatoire']"
            type="agences"
            icon="mdi-map-marker"
          ></vTextInput>
          <vTextInput
            libelle="Ville"
            clef="ville"
            class="mt-6"
            id="agences-ville"
            rulesInfo="required"
            :value="editedItem.ville"
            placeholder="Paris.."
            :rules="[(v) => !!v || 'Le champ est obligatoire']"
            type="agences"
            icon="mdi-map-marker"
          ></vTextInput>
          <vTextInput
            libelle="Code postal"
            clef="codePostal"
            class="mt-6"
            id="agences-codePostal"
            rulesInfo="required"
            :value="editedItem.codePostal"
            placeholder="75000.."
            :rules="[(v) => !!v || 'Le champ est obligatoire']"
            type="agences"
            icon="mdi-map-marker"
          ></vTextInput>
          <vTextInput
            libelle="Téléphone"
            clef="telephone"
            class="mt-6"
            id="agences-telephone"
            rulesInfo="required"
            :value="editedItem.telephone"
            placeholder="01352.....21"
            :rules="[(v) => !!v || 'Le champ est obligatoire']"
            type="agences"
            icon="mdi-phone"
          ></vTextInput>
          <vTextInput
            libelle="Email"
            clef="email"
            class="mt-6"
            id="agences-email"
            rulesInfo="required"
            :value="editedItem.email"
            placeholder="exemple@exemple.fr"
            :rules="[(v) => !!v || 'Le champ est obligatoire', (v) => /.+@.+\..+/.test(v) || 'L\'adresse email est invalide',]"
            type="agences"
            icon="mdi-email"
          ></vTextInput>
        </v-col>
        <v-card-actions>
          <v-spacer></v-spacer>
          <vBtnEdit
            nom="Sauvegarder"
            v-if="validateForm() && agence.id"
            :valid="valid"
            icon="mdi-content-save"
            @saveForm="returnTo"
            :onlyIcon="false"
            :id="editedItem.id"
            table="agences"
          ></vBtnEdit>
          <VBtnSave
            v-else
            nom="Créer"
            :valid="valid"
            icon="mdi-plus"
            @saveForm="returnTo"
            :onlyIcon="false"
            table="agences"
          ></VBtnSave>
          <v-btn class="m-1" text @click="closeModal"> Annuler </v-btn>
        </v-card-actions>
      </v-form>
    </div>
  </template>
  
  <script>
  export default {
    props: ["editedItem","typeCRUD"],
    data() {
      return {
        valid: true,
        requiredRules: [(v) => !!v || "Le champ est obligatoire"],
      };
    },
    computed: {
      agence() {
        return this.$store.getters["formulaire/getFormType"]("agences")
      }
    },
    methods: {
      validateForm() {
        if (this.agence.libelle != "") {
          return true;
        } else {
          return false;
        }
      },
      closeModal() {
        this.$emit("close");
      },
      returnTo(agenceStored) {
        
        if (this.typeCRUD == "edit") {
          this.$store.dispatch("agences/updateAgence", agenceStored);
        } else {
          this.$store.dispatch("agences/storeAgence", agenceStored);
        }
        
        this.closeModal()
      },
    },
  };
  </script>
  
  <style>
  </style>