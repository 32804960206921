<template>
  <div>
    <div class="container mb-5">
      <div
        v-infinite-scroll="loadProduits"
        infinite-scroll-disabled="busy"
        infinite-scroll-limit="3"
        class="pb-5"
      >
        <v-text-field
          filled
          rounded
          class="mt-5"
          prepend-inner-icon="mdi-magnify"
          v-model="search"
          placeholder="Porte..."
          label="Rechercher un de vos produits"
        ></v-text-field>
        <v-list rounded three-line class="pb-5 rounded-xl">
          <v-subheader>Selectionner les produits pour votre vente</v-subheader>
          <div v-if="produitsMax == 0" class="container">
            <p class="text-center">Il n'y a aucun produit</p>
          </div>
          <div v-else-if="produits.length == 0" class="container">
            <p class="text-center">Aucun produit trouvé !</p>
          </div>
          <div v-if="loaderInit" class="container">
            <div class="d-flex justify-content-center mb-4">
              <v-progress-circular
                indeterminate
                color="purple"
              ></v-progress-circular>
            </div>
            <v-skeleton-loader
              class="rounded-xl"
              type="list-item-three-line, list-item-three-liner,list-item-three-line,list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line"
            >
            </v-skeleton-loader>
          </div>
          <v-list-item-group
            active-class="pink--text"
            multiple
            v-else
            v-model="selected"
            color="primary"
          >
            <v-list-item
              @click="selection(produit)"
              v-for="produit in produitsFilter"
              :key="produit.id"
              :value="produit.id"
            >
              <v-list-item-content>
                <v-row>
                  <v-col>
                    <v-list-item-title
                      v-text="produit.designation"
                    ></v-list-item-title>
                    <v-list-item-subtitle
                      v-text="produit.description"
                    ></v-list-item-subtitle>
                    <v-list-item-subtitle
                      v-text="'Famille de produit : ' + produit.categorie"
                    ></v-list-item-subtitle>
                  </v-col>
                  <v-col>
                    <v-list-item-subtitle
                      >PU :
                      <strong class="fs-5">{{ produit.prix_unitaire }}</strong
                      >€ <br />
                      TVA : {{ produit.tva }}%</v-list-item-subtitle
                    >
                    <v-list-item-subtitle
                      v-text="'Fournisseur : ' + produit.fournisseur"
                    ></v-list-item-subtitle>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>

            <div v-if="loaderNext">
              <v-skeleton-loader
                class="rounded-xl"
                type="list-item-three-line, list-item-three-liner,list-item-three-line,list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line"
              >
              </v-skeleton-loader>
            </div>
          </v-list-item-group>
        </v-list>
        <v-btn
          class="float-right mt-4 mb-5 bg-color-main text-white"
          @click="selectProduits()"
          rounded
        >
          <v-icon>mdi-cart-arrow-down</v-icon>Selectionner
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["idVente", "typeVente", "id", "produitsAlreadySelected"],
  data() {
    return {
      loaderInit: true,
      limitTimeLine: 5,
      produitsMax: 0,
      produitsFilter: [],
      search: "",
      selected: [],
      selectedProduit: [],
      busy: false,
      loaderNext: false,
      saveSelectedProduits: [],
      items: [
        { text: "Real-Time", icon: "mdi-clock" },
        { text: "Audience", icon: "mdi-account" },
        { text: "Conversions", icon: "mdi-flag" },
      ],
    };
  },
  computed: {
    produits: {
      get() {
        return this.$store.getters["produits/getProduits"];
      },
      set(val) {
        return val;
      },
    },
  },
  watch: {
    search(val) {
      if (val.length == 1) {
        this.saveSelectedProduits = this.selected;
      }

      if (val.length > 2) {
        this.$axios.get("/produits/ventes/search/" + val).then((response) => {
          this.produitsFilter = response.data;
        });
      } else if (val == 0) {
        this.produitsFilter = this.produits;
        this.selected = this.saveSelectedProduits;
      }
    },
  },
  mounted() {
    this.getListProduits();
    window.onbeforeunload = function () {
      return "Vous allez perdre les information du devis si vous recharger la page.";
    };
  },
  methods: {
    loadProduits() {
      if (
        this.$store.getters["produits/getProduitsMax"] >
        this.$store.getters["produits/getProduits"].length
      ) {
        this.loaderNext = true;
        setTimeout(() => {
          this.limitTimeLine = this.limitTimeLine + 10;
          this.getListProduits();
          this.loaderNext = false;
        }, 2000);
      }
    },
    getListProduits() {
      this.$store
        .dispatch("produits/setListProduits", this.limitTimeLine)
        .then((response) => {
          this.produitsMax = response.data.produitsMax;
          this.produits = response.data.produits;
          this.produitsFilter = this.produits;
          if (this.produitsAlreadySelected) {
            this.produitsAlreadySelected.forEach((element) => {
              this.selected.push(element.id_produit);
            });
          }
          this.loaderInit = false;
        });
    },
    selectProduits() {
      var payload = {
        idsProduits: this.selected,
        idVente: this.idVente,
        typeVente: this.typeVente,
      };
      this.$axios
        .post("/produits/ventes/selectProduits", payload)
        .then((response) => {
          this.$swal({
            toast: true,
            title: "Les produits ont bien été ajoutés",
            icon: "success",
            animation: true,
            position: "top-right",
            showConfirmButton: false,
            timerProgressBar: true,
            timer: 2000,
          });
          this.$router.push({
            name: "J'ajoute mes produits",
            params: {
              idVente: response.data.id,
              id: this.id,
              produitsList: response.data.produits,
            },
          });
        });
    },
    selection(produit) {
      var index = this.saveSelectedProduits.indexOf(produit.id);

      if (index === -1) {
        this.saveSelectedProduits.push(produit.id);
      } else {
        this.saveSelectedProduits.splice(index, 1);
      }
    },
  },
};
</script>

<style></style>
