/* eslint-disable */
export default {
    data() {
        return {}
    },
    methods: {
        roundMontant(montant) {
            return Math.round(montant * 100) / 100;
          },
        
    }
}