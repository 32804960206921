<template>
  <div data-app>
    <div class="container text-center mt-5">
      <v-select
        filled
        rounded
        :items="items"
        v-model="dataFilter"
        label="Filtres"
      ></v-select>
      <v-btn
        x-small
        rounded
        v-if="this.typeDate == 'week'"
        class="button-size-graph-select"
        @click="getCaFactureParAgence('week')"
        >Cette semaine</v-btn
      >
      <v-btn
        x-small
        v-else
        class="button-size-graph"
        rounded
        @click="getCaFactureParAgence('week')"
        >Cette semaine
      </v-btn>
      <v-btn
        v-if="this.typeDate == 'month'"
        rounded
        x-small
        class="button-size-graph-select"
        @click="getCaFactureParAgence('month')"
        >Ce mois-ci</v-btn
      >
      <v-btn
        v-else
        x-small
        class="button-size-graph"
        rounded
        @click="getCaFactureParAgence('month')"
        >Ce mois-ci
      </v-btn>
      <v-btn
        v-if="this.typeDate == 'years'"
        x-small
        class="button-size-graph-select"
        @click="getCaFactureParAgence('years')"
        rounded
        >Cette année</v-btn
      >
      <v-btn
        v-else
        x-small
        rounded
        class="button-size-graph"
        @click="getCaFactureParAgence('years')"
        >Cette année
      </v-btn>
    </div>
    <div class="container">
      <div class="row">
        <div class="col">
          <v-dialog
            ref="menu_debut"
            v-model="menu_debut"
            :close-on-content-click="false"
            :return-value.sync="date_debut"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date_debut"
                filled
                rounded
                label="Date de début"
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="date_debut" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu_debut = false">
                Retour
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  $refs.menu_debut.save(date_debut),
                    (changeDate = Math.floor(Math.random() * 10424240))
                "
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </div>
        <div class="col">
          <v-dialog
            ref="menu_fin"
            v-model="menu_fin"
            :close-on-content-click="false"
            :return-value.sync="date_fin"
            transition="scale-transition"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date_fin"
                filled
                rounded
                label="Date de fin"
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="date_fin" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu_fin = false">
                Retour
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  $refs.menu_fin.save(date_fin),
                    (changeDate = Math.floor(Math.random() * 10424240))
                "
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </div>
      </div>
    </div>
    <div
      class="container"
      v-if="
        dataFilter == 'CA Origine de contact' ||
        dataFilter == 'CA famille produits'
      "
    >
      <v-select
        :items="itemsTypeDoc"
        filled
        rounded
        v-model="typeDocFilter"
        label="Type de document"
      ></v-select>
    </div>
    <highcharts ref="highcharts" class="pb-5" :options="options"></highcharts>
    <v-dialog v-model="dialogShowMoreInformation">
      <v-card>
        <div
          class="table-responsive text-mt-5"
          :class="isDarkMode ? 'text-white' : 'text-dark'"
        >
          <v-toolbar>
            <v-toolbar-title>
              <v-btn
                @click="dialogShowMoreInformation = false"
                icon
                class="mb-2"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              {{ nomCommercialSelect }}
            </v-toolbar-title>
          </v-toolbar>

          <h1 class="text-center lead mt-3 mb-3 font-weight-bold">
            Liste des ventes du commercial
          </h1>
          <div class="scrollbar" v-if="listeVentes">
            <table
              :class="isDarkMode ? 'text-white' : 'text-dark'"
              class="table border pb-5"
            >
              <thead>
                <tr>
                  <th scope="col">Nom du commercial</th>
                  <th scope="col">Nom de la vente</th>
                  <th scope="col">Type de vente</th>
                  <th scope="col">Montant (HT)</th>
                  <th scope="col">Commission (en €)</th>
                  <th scope="col">Type de règlement</th>
                  <th scope="col">Date</th>
                </tr>
              </thead>

              <tbody
                id="cursor-pointer"
                v-for="vente in listeVentes"
                v-bind:key="vente.id"
                @click="redirectToVentes(vente)"
              >
                <tr v-if="vente.annuler == 0 || vente.annuler == null">
                  <th scope="row">
                    {{ vente.userObject.nom }} {{ vente.userObject.prenom }}
                  </th>
                  <td>{{ vente.libelle }}</td>
                  <td>{{ vente.type_vente }}</td>
                  <td>{{ vente.montant }}</td>
                  <td
                    v-if="
                      (vente.userObject.pourcentage_commission_perso == null ||
                        vente.userObject.pourcentage_commission_perso == 0) &&
                      (vente.pourcentage_commission_societe == null ||
                        vente.pourcentage_commission_societe == 0)
                    "
                  >
                    Pas de commission
                  </td>
                  <td v-else>{{ vente.commission_reçu }}</td>
                  <td v-if="vente.reglement">{{ vente.reglement }}</td>
                  <td v-else>Aucun règlement</td>
                  <td>{{ formatDate(vente.dateVente) }}</td>
                </tr>
                <tr v-else>
                  <th class="barrer" scope="row">
                    {{ vente.userObject.nom }} {{ vente.userObject.prenom }}
                  </th>
                  <td class="barrer">{{ vente.libelle }}</td>
                  <td class="barrer">{{ vente.montant }}</td>
                  <td
                    class="barrer"
                    v-if="
                      (vente.userObject.pourcentage_commission_perso == null ||
                        vente.userObject.pourcentage_commission_perso == 0) &&
                      (vente.pourcentage_commission_societe == null ||
                        vente.pourcentage_commission_societe == 0)
                    "
                  >
                    Pas de commission
                  </td>
                  <td class="barrer" v-else>{{ vente.commission_reçu }}</td>
                  <td class="barrer" v-if="vente.reglement">
                    {{ vente.reglement }}
                  </td>
                  <td class="barrer" v-else>Aucun règlement</td>
                  <td class="barrer">{{ formatDate(vente.dateVente) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-else>
            <h2 class="text-center lead p-2">
              Ce commercial n'a pas encore de ventes
            </h2>
          </div>
          <h1 class="text-center lead mt-3 mb-3 font-weight-bold">
            Liste des ventes partagé <br />
            avec ce commercial
          </h1>
          <div class="scrollbar" v-if="listeVentesPartager">
            <table
              :class="isDarkMode ? 'text-white' : 'text-dark'"
              class="table border pb-5"
            >
              <thead>
                <tr>
                  <th scope="col">Nom du commercial</th>
                  <th scope="col">Nom de la vente</th>
                  <th scope="col">Type de vente</th>
                  <th scope="col">Montant (HT)</th>
                  <th scope="col">Commission (en €)</th>
                  <th scope="col">Type de règlement</th>
                  <th scope="col">Date</th>
                </tr>
              </thead>
              <tbody
                id="cursor-pointer"
                v-for="vente in listeVentesPartager"
                v-bind:key="vente.id"
                @click="redirectToVentes(vente)"
              >
                <tr>
                  <th scope="row">
                    {{ vente.userObject.nom }} {{ vente.userObject.prenom }}
                  </th>
                  <td>{{ vente.libelle }}</td>
                  <td>{{ vente.type_vente }}</td>
                  <td>{{ vente.montant }}</td>
                  <td
                    v-if="
                      (vente.userObject.pourcentage_commission_perso == null ||
                        vente.userObject.pourcentage_commission_perso == 0) &&
                      (vente.pourcentage_commission_societe == null ||
                        vente.pourcentage_commission_societe == 0)
                    "
                  >
                    Pas de commission
                  </td>
                  <td v-else>{{ vente.commission_reçu }}</td>
                  <td v-if="vente.reglement">{{ vente.reglement }}</td>
                  <td v-else>Aucun règlement</td>
                  <td>{{ formatDate(vente.dateVente) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-else>
            <h2 class="text-center lead p-2">
              Ce commercial n'a pas de ventes partagées
            </h2>
          </div>
          <hr class="bg-" />
          <p class="size-price-commission text-break">
            Total des commissions du commercial : <br />
            <strong
              ><u>{{ Math.ceil(totalComm) }}€ euros</u></strong
            >
          </p>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog" scrollable max-width="300px">
      <v-card class="container">
        <v-btn
          @click="dialog = false"
          style="background-color: white; color: black"
          >Fermer</v-btn
        >
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable */
// eslint-disable-next-line

import Vue from "vue";
import axios from "axios";
import Highcharts from "highcharts";
import exportingInit from "highcharts/modules/exporting";
exportingInit(Highcharts);

export default {
  name: "Stats",
  data() {
    return {
      typeDate: "",
      listeVentes: [],
      totalComm: "",
      date_debut: null,
      date_fin: null,
      menu_debut: false,
      menu_fin: false,
      dialogShowMoreInformation: false,
      listeVentesPartager: [],
      dialog: false,
      data: [],
      items: [
        "CA commande",
        "CA facturé",
        "CA devis signé",
        "CA Origine de contact",
        "CA famille produits",
      ],
      typeDocFilter: "Facture",
      itemsTypeDoc: ["Facture", "Commande", "Devis"],
      dataFilter: "",
      dataCharts: [],
      nomCommercialSelect: "",
      changeDate: null,
      options: {
        chart: {
          type: "",
        },
        title: {
          text: "",
        },
        yAxis: {
          min: 0,
          title: {
            text: "CA",
          },
          labels: {
            overflow: "justify",
          },
        },
        xAxis: {
          type: "category",
        },
        legend: {
          enabled: true,
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
            },
            point: {
              events: {
                click: ({ point }) => {
                  /* var UNDEFINED; */
                  var index = point.index;
                  if (point.x != undefined) {
                    Object.keys(this.data).forEach((element) => {
                      if (this.data[element]["data"][index]) {
                        if (
                          point["options"]["name"] ==
                          this.data[element]["data"][index][0]
                        ) {
                          this.listeElementsById(
                            this.data[element]["data"][index]
                          );
                        }
                      }
                    });
                  }
                },
              },
            },
          },
        },
        tooltip: {
          valueSuffix: " €",
        },
        series: [
          {
            name: "",
            colorByPoint: true,
            data: [],
          },
        ],
        drilldown: {
          series: [],
          drillUpButton: {
            position: {
              x: -20,
              y: 360,
            },
            relativeTo: "plotBox",
          },
        },
        lang: {
          loading: "Chargement...",
          months: [
            "janvier",
            "février",
            "mars",
            "avril",
            "mai",
            "juin",
            "juillet",
            "août",
            "septembre",
            "octobre",
            "novembre",
            "décembre",
          ],
          weekdays: [
            "dimanche",
            "lundi",
            "mardi",
            "mercredi",
            "jeudi",
            "vendredi",
            "samedi",
          ],
          shortMonths: [
            "jan",
            "fév",
            "mar",
            "avr",
            "mai",
            "juin",
            "juil",
            "aoû",
            "sep",
            "oct",
            "nov",
            "déc",
          ],
          exportButtonTitle: "Exporter",
          printButtonTitle: "Imprimer",
          rangeSelectorFrom: "Du",
          rangeSelectorTo: "au",
          rangeSelectorZoom: "Période",
          downloadPNG: "Télécharger en PNG",
          downloadJPEG: "Télécharger en JPEG",
          downloadPDF: "Télécharger en PDF",
          downloadSVG: "Télécharger en SVG",
          resetZoom: "Réinitialiser le zoom",
          resetZoomTitle: "Réinitialiser le zoom",
          thousandsSep: " ",
          decimalPoint: ",",
        },
      },
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              legend: {
                align: "center",
                verticalAlign: "bottom",
                layout: "horizontal",
              },
              yAxis: {
                labels: {
                  align: "left",
                  x: 0,
                  y: -5,
                },
                title: {
                  text: "CA",
                },
              },
              subtitle: {
                text: null,
              },
              credits: {
                enabled: false,
              },
            },
          },
        ],
      },
    };
  },
  mounted() {
    Vue.prototype.$constCharts = this.$refs.highcharts.chart;
    this.dataFilter = "CA commande";
    this.getCaFactureParAgence("week");
    var charts = this.$refs.highcharts.chart;
    setTimeout(() => {
      charts.renderTo;
      charts.reflow();
    }, 500);
    this.$constCharts.reflow();
  },
  watch: {
    dataFilter(val) {
      this.getCaFactureParAgence(this.typeDate);
    },
    typeDocFilter(val) {
      this.getCaFactureParAgence(this.typeDate, val);
    },
    changeDate(val) {
      this.getCaFactureParAgence("datePerso");
    },
  },
  methods: {
    redirectToVentes(vente) {
      console.log(vente);
      switch (vente.type_vente) {
        case "devis":
          if (vente.isDone) {
            this.$router.push({
              name: "Je regarde mon devis",
              params: {
                idContact: vente.contact_id,
                idVente: vente.id,
                typeVente: "devis",
              },
            });
          } else {
            this.$router.push({
              name: "J'edite un devis",
              params: {
                idContact: vente.contact_id,
                idVente: vente.id,
                typeVente: "devis",
              },
            });
          }

          break;
        case "commande":
          if (vente.isDone) {
            this.$router.push({
              name: "Je regarde ma commande",
              params: {
                idContact: vente.contact_id,
                idVente: vente.id,
                typeVente: "commande",
              },
            });
          } else {
            this.$router.push({
              name: "J'edite une commande",
              params: {
                idContact: vente.contact_id,
                idVente: vente.id,
                typeVente: "commande",
              },
            });
          }

          break;
        case "facture":
          this.$router.push({
            name: "Je regarde ma facture",
            params: {
              idContact: vente.contact_id,
              idVente: vente.id,
              typeVente: "facture",
            },
          });
          break;
        default:
          break;
      }
    },
    listeElementsById(dataBar) {
      var now = this.$moment();
      switch (this.typeDate) {
        case "week":
          var date_debut = now.clone().startOf("week").format("YYYY-MM-DD");
          var date_fin = now.clone().endOf("week").format("YYYY-MM-DD");
          this.date_debut = date_debut;
          this.date_fin = date_fin;
          break;

        case "month":
          var date_debut = now.startOf("month").format("YYYY-MM-DD");
          var date_fin = now.endOf("month").format("YYYY-MM-DD");
          this.date_debut = date_debut;
          this.date_fin = date_fin;
          break;

        case "years":
          var date_debut = now.startOf("year").format("YYYY-MM-DD");
          var date_fin = now.endOf("year").format("YYYY-MM-DD");
          this.date_debut = date_debut;
          this.date_fin = date_fin;

        case "datePerso":
          var date_debut = this.date_debut;
          var date_fin = this.date_fin;
          break;
        default:
          break;
      }
      var arrayParams = {
        typeDate: { date_debut: date_debut, date_fin: date_fin },
        dataFilter: this.dataFilter,
      };
      axios
        .post("/stats/listeVentesParCommerciaux/" + dataBar[2], arrayParams)
        .then((response) => {
          this.listeVentes = response.data.ventes;
          this.listeVentesPartager = response.data.ventesShare;
          this.totalComm = response.data.totalComm;
          this.totalComShare = response.data.totalComShare;
          this.nomCommercialSelect = response.data.nomCommercial;
          this.dialogShowMoreInformation = true;
        });
    },
    getCaFactureParAgence(typeDate, docTypeFilter = "Facture") {
      this.typeDate = typeDate;
      var now = this.$moment();
      var charts = this.$refs.highcharts.chart;

      switch (this.typeDate) {
        case "week":
          var date_debut = now.clone().startOf("week").format("YYYY-MM-DD");
          var date_fin = now.clone().endOf("week").format("YYYY-MM-DD");
          this.date_debut = date_debut;
          this.date_fin = date_fin;
          break;

        case "month":
          var date_debut = now.startOf("month").format("YYYY-MM-DD");
          var date_fin = now.endOf("month").format("YYYY-MM-DD");
          this.date_debut = date_debut;
          this.date_fin = date_fin;
          break;

        case "years":
          var date_debut = now.startOf("year").format("YYYY-MM-DD");
          var date_fin = now.endOf("year").format("YYYY-MM-DD");
          this.date_debut = date_debut;
          this.date_fin = date_fin;

        case "datePerso":
          var date_debut = this.date_debut;
          var date_fin = this.date_fin;
          break;
        default:
          break;
      }

      var arrayDate = { date_debut: date_debut, date_fin: date_fin };
      charts.drillUp();

      charts.renderTo;
      charts.reflow();
      charts.redraw();
      this.options.series = [];

      switch (this.dataFilter) {
        case "CA facturé":
          axios
            .post("/stats/caFactureParAgence", arrayDate)
            .then((response) => {
              var filtre = { filtre: "Facture", date: arrayDate };
              axios.post("/stats/caParCommerciaux", filtre).then((response) => {
                this.data = response.data;
                this.options.drilldown.series = this.data;
              });
              this.options.series = [];
              var typeDateString = "";
              switch (typeDate) {
                case "week":
                  typeDateString = " : Semaine";
                  break;
                case "month":
                  typeDateString = " : Mois";
                  break;
                case "years":
                  typeDateString = " : Année";
                  break;

                default:
                  break;
              }
              this.options.series.push({
                data: response.data.charts,
                name: response.data.name + typeDateString,
              });
              this.options.chart.type = response.data.typeCharts;
            });
          break;
        case "CA devis signé":
          axios
            .post("/stats/caDevisSigneParAgence", arrayDate)
            .then((response) => {
              this.options.series = [];
              var filtre = { filtre: "Devis", date: arrayDate };
              axios.post("/stats/caParCommerciaux", filtre).then((response) => {
                this.options.drilldown.series = response.data;
              });
              var typeDateString = "";
              switch (typeDate) {
                case "week":
                  typeDateString = " : Semaine";
                  break;
                case "month":
                  typeDateString = " : Mois";
                  break;
                case "years":
                  typeDateString = " : Année";
                  break;

                default:
                  break;
              }
              this.options.series.push({
                data: response.data.charts,
                name: response.data.name + typeDateString,
              });
              this.options.chart.type = response.data.typeCharts;
            });
          break;
        case "CA commande":
          axios
            .post("/stats/caCommandeParAgence", arrayDate)
            .then((response) => {
              this.options.series = [];
              var filtre = { filtre: "Commande", date: arrayDate };
              axios.post("/stats/caParCommerciaux", filtre).then((response) => {
                this.data = response.data;
                this.options.drilldown.series = this.data;
              });
              var typeDateString = "";
              switch (typeDate) {
                case "week":
                  typeDateString = " : Semaine";
                  break;
                case "month":
                  typeDateString = " : Mois";
                  break;
                case "years":
                  typeDateString = " : Année";
                  break;

                default:
                  break;
              }
              this.options.series.push({
                data: response.data.charts,
                name: response.data.name + typeDateString,
              });
              this.options.chart.type = response.data.typeCharts;
            });
          break;
        case "CA Origine de contact":
          arrayDate["etat_document"] = docTypeFilter;
          axios.post("/stats/caOrigineContact", arrayDate).then((response) => {
            this.options.series = [];
            var typeDateString = "";
            switch (typeDate) {
              case "week":
                typeDateString = " : Semaine";
                break;
              case "month":
                typeDateString = " : Mois";
                break;
              case "years":
                typeDateString = " : Année";
                break;

              default:
                break;
            }
            this.options.series.push({
              data: response.data.charts,
              name: response.data.name + typeDateString,
            });
            this.options.chart.type = response.data.typeCharts;
          });

          break;
        case "CA famille produits":
          arrayDate["etat_document"] = docTypeFilter;
          axios.post("/stats/caFamilleProduit", arrayDate).then((response) => {
            this.options.series = [];
            var filtre = { date: arrayDate, etat_document: docTypeFilter };
            axios.post("/stats/caProduits", filtre).then((response) => {
              this.options.drilldown.series = response.data;
            });
            var typeDateString = "";
            switch (typeDate) {
              case "week":
                typeDateString = " : Semaine";
                break;
              case "month":
                typeDateString = " : Mois";
                break;
              case "years":
                typeDateString = " : Année";
                break;

              default:
                break;
            }
            this.options.series.push({
              data: response.data.charts,
              name: response.data.name + typeDateString,
            });
            this.options.chart.type = response.data.typeCharts;
          });

          break;
        default:
          break;
      }
    },
    formatDate(value) {
      return this.$moment(value).format("LLL");
    },
  },
};
</script>

<style>
.highcharts-container {
  width: 100% !important;
  height: 100% !important;
  border: 1px solid #ddd !important;
}

.button-size-graph {
  font-size: 10px !important;
}

.button-size-graph-select {
  font-size: 10px !important;
  border: solid 3px rgb(255, 0, 0) !important;
}

.highcharts-credits {
  display: none !important;
}

.size-price {
  font-size: 30px;
  text-align: center !important;
}

.size-price-commission {
  font-size: 20px;
  text-align: center !important;
}

#cursor-pointer {
  cursor: pointer;
}

.barrer {
  text-decoration: line-through;
}

.scrollbar {
  max-height: 350px;
  overflow: auto;
}
</style>
