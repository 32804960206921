<template>
  <div>
    <v-dialog persistent v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline text-break">{{ formTitle }}</span>
          <v-spacer></v-spacer>
          <v-btn class="text-white" style="background-color: red" v-if="showManagerThing && editedItem.id"
            @click="deleteItem(editedItem)">
            <v-icon> mdi-delete </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <component v-bind:is="typeForm" :editedItem="editedItem" @close="close" :typeCRUD="typeCRUD"></component>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: [
    "items",
    "itemEdited",
    "typeCRUD",
    "formTitle",
    "storeForm",
    "type",
    "typeForm",
    "deleteStoreFunction"
  ],
  data() {
    return {
      loader: false,
      editedIndex: -1,
      role: "",
      dialog: true,
      valid: true,
    };
  },
  computed: {
    arrayItems: {
      get() {
        return this.items;
      },
      set(values) {
        return values;
      },
    },
    editedItem: {
      get() {
        return this.itemEdited;
      },
      set(values) {
        return values;
      },
    },
  },
  methods: {
    closeDelete() {
      this.dialogDelete = false;
    },
    close() {
      this.dialog = false;
      this.$emit("closeDialog");
      this.editedItem = {
        id: "",
        libelle: "",
      };
      this.editedIndex = -1;
    },
    deleteItem() {
      this.$swal({
        title: "Voulez-vous supprimer cet élément ?",
        showDenyButton: true,
        type: "danger",
        showCancelButton: false,
        confirmButtonText: "Oui",
        denyButtonText: `Non`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch(this.deleteStoreFunction, this.editedItem.id)
          this.$axios
            .delete(this.type + 's' + "/delete/" + this.editedItem.id)
            .then((response) => {
              this.$swal("Bravo !", response.data.message, "success");
              this.closeDelete();
              this.close();
            });
        }
      });

    },
  },
};
</script>
  
<style>

</style>