<template>
    <div>
        <label v-if="rulesInfo == 'required'">{{ this.label }} <span style="color: red">*</span></label>
        <v-color-picker mode="hexa" rounded filled :id="id" :prepend-inner-icon="icon" v-model="modelValue"
            :placeholder="placeholder" :name="clef" hide-canvas hide-mode-switch dot-size="25"
            swatches-max-height="200">
        </v-color-picker>
    </div>
</template>

<script>
export default {
    props: [
        "id",
        "rulesInfo",
        "label",
        "icon",
        "rules",
        "clef",
        "value",
        "placeholder",
        "type",
    ],
    computed: {
        modelValue: {
            get() {
                return this.value;
            },
            set(val) {
                this.$store.dispatch("formulaire/updateForm", {
                    form: this.type,
                    field: this.clef,
                    value: val,
                });
            },
        },
    },
}
</script>

<style>

</style>