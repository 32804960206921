<template>
    <div>
        <v-form v-model="valid">
            <input type="hidden" v-model="editedItem.id" />
            <v-col cols="12">
                <vTextInput libelle="Nom" clef="nom" class="mt-6" id="users-nom" rulesInfo="required"
                    :value="editedItem.nom" placeholder="Dupont.." :rules="[(v) => !!v || 'Le champ est obligatoire']"
                    type="users" icon="mdi-account"></vTextInput>
                <vTextInput libelle="Prénom" clef="prenom" class="mt-6" id="users-prenom" rulesInfo="required"
                    :value="editedItem.prenom" placeholder="Jean.." :rules="[(v) => !!v || 'Le champ est obligatoire']"
                    type="users" icon="mdi-account"></vTextInput>
                <vTextInput libelle="Email" clef="email" class="mt-6" id="users-email" rulesInfo="required"
                    :value="editedItem.email" placeholder="exemple@exemple.fr"
                    :rules="[(v) => !!v || 'Le champ est obligatoire', (v) => /.+@.+\..+/.test(v) || 'L\'adresse email est invalide',]"
                    type="users" icon="mdi-email"></vTextInput>
                <vTextInput typeInput="password" libelle="Mot de passe" clef="password" class="mt-6" id="users-password" rulesInfo="required"
                    :value="editedItem.password"  v-if="typeCRUD != 'edit'"
                    :rules="[(v) => !!v || 'Le champ est obligatoire']"
                    type="users" icon="mdi-password"></vTextInput>
                <VOneSelect icon="mdi-account" libelle="Role de l'utilisateur" :datas="[{id: 1, libelle: 'Utilisateur'}, {id: 2, libelle: 'Responsable agence'}]"
                    clef="role_id" itemText="libelle" class="mt-6" itemValue="id" rulesInfo="required" type="users"
                    :value="editedItem.role_id" :rules="[(v) => !!v || 'Le champ est obligatoire']"></VOneSelect>
                <VOneSelect icon="mdi-domain" libelle="Point de vente lié à cet utilisateur" :datas="agences"
                    clef="agence_id" itemText="libelle" class="mt-6" itemValue="id" rulesInfo="required" type="users"
                    :value="editedItem.agence_id" :rules="[(v) => !!v || 'Le champ est obligatoire']"></VOneSelect>
                <vTextInput libelle="Pourcentage commission perso" clef="pourcentage_commission_perso" class="mt-6"
                    id="users-pourcentage_commission_perso" rulesInfo="required"
                    :value="editedItem.pourcentage_commission_perso" placeholder="10"
                    :rules="[(v) => !!v || 'Le champ est obligatoire']" type="users" icon="mdi-cash-refund">
                </vTextInput>
                <vTextInput libelle="Pourcentage commission société" clef="pourcentage_commission_societe" class="mt-6"
                    id="users-pourcentage_commission_societe" rulesInfo="required"
                    :value="editedItem.pourcentage_commission_societe" placeholder="5"
                    :rules="[(v) => !!v || 'Le champ est obligatoire']" type="users" icon="mdi-cash-refund">
                </vTextInput>

            </v-col>
            <v-card-actions>
                <v-spacer></v-spacer>
                <vBtnEdit nom="Sauvegarder" v-if="validateForm() && user.id" :valid="valid" icon="mdi-content-save"
                    @saveForm="returnTo" :onlyIcon="false" :id="editedItem.id" table="users"></vBtnEdit>
                <VBtnSave v-else nom="Créer" :valid="valid" icon="mdi-plus" @saveForm="returnTo" :onlyIcon="false"
                    table="users"></VBtnSave>
                <v-btn class="m-1" text @click="closeModal"> Annuler </v-btn>
            </v-card-actions>
        </v-form>
    </div>
</template>
  
<script>
export default {
    props: ["editedItem", "typeCRUD"],
    data() {
        return {
            valid: true,
            agences: [],
            requiredRules: [(v) => !!v || "Le champ est obligatoire"],
        };
    },
    mounted() {
        this.getAllAgences()
    },
    computed: {
        user() {
            return this.$store.getters["formulaire/getFormType"]("users")
        },
    },
    methods: {
        getAllAgences() {
            this.$axios.get('/param/getAllAgences').then((response) => {
                this.agences = response.data.agences
            })
        },
        validateForm() {
            if (this.user.libelle != "") {
                return true;
            } else {
                return false;
            }
        },
        closeModal() {
            this.$emit("close");
        },
        returnTo(userStored) {
            if (this.typeCRUD == "edit") {
                this.$store.dispatch("users/updateUser", userStored);
            } else {
                this.$store.dispatch("users/storeUser", userStored);
            }

            this.closeModal()
        },
    },
};
</script>
  
<style>

</style>