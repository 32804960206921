<template>
  <div>
    <v-form v-model="valid" class="container pb-5">
      <v-container v-if="loader">
        <h1 class="text-center" style="margin-top:150px;">Chargement du formulaire . . .</h1>
        <v-progress-linear :indeterminate="true"></v-progress-linear>
      </v-container>
      <div v-else>
        <v-container>
          <v-card-title> Produit </v-card-title>
          <vTextInput libelle="Designation" clef="designation" class="mt-6" id="produit-designation" rulesInfo="required"
            :value="produit.designation" placeholder="Fenêtre PVC.." :rules="[(v) => !!v || 'Le champ est obligatoire']"
            type="produits" icon="mdi-door"></vTextInput>
          <VAreaText libelle="Description" clef="description" class="mt-6" id="produit-description" rulesInfo="required"
            :value="produit.description" placeholder="Matériaux PVC.." :rules="[(v) => !!v || 'Le champ est obligatoire']"
            type="produits" icon="mdi-image-text"></VAreaText>
          <vTextInput libelle="Quantité" clef="quantite" v-if="fromScenario == 'vente'" class="mt-6" id="produit-quantite"
            rulesInfo="required" :value="produit.quantite" placeholder="1.2..3" :rules="[(v) => !!v || 'Le champ est obligatoire',
            (v) => !!v || 'Le champ est obligatoire', (v) => !isNaN(v) || 'Ce n\'est pas un nombre']" type="produits"
            icon="mdi-counter"></vTextInput>
          <vTextInput libelle="Prix unitaire" clef="prix_unitaire" class="mt-6" id="produit-prix_unitaire"
            rulesInfo="required" :value="produit.prix_unitaire" placeholder="500.." :rules="[(v) => !!v || 'Le champ est obligatoire',
            (v) => !!v || 'Le champ est obligatoire', (v) => !isNaN(v) || 'Ce n\'est pas un nombre']" type="produits"
            icon="mdi-currency-eur"></vTextInput>
          <vTextInput libelle="Prix fournisseur" clef="prix_fournisseur" class="mt-6" id="produit-prix_fournisseur"
            rulesInfo="required" :value="produit.prix_fournisseur" placeholder="500.." :rules="[(v) => !!v || 'Le champ est obligatoire',
            (v) => !!v || 'Le champ est obligatoire', (v) => !isNaN(v) || 'Ce n\'est pas un nombre']" type="produits"
            icon="mdi-currency-eur"></vTextInput>
          <vTextInput libelle="Pourcentage de la remise" v-if="fromScenario == 'vente' && authUserEntreprise('remise')"
            clef="pourcentage_remise" class="mt-6" id="produit-pourcentage_remise"
            :value="(produit.pivot.pourcentage_remise) ? produit.pivot.pourcentage_remise : 0" placeholder="500.."
            :rules="[(v) => !isNaN(v) || 'Ce n\'est pas un nombre']" type="produits" icon="mdi-percent">
          </vTextInput>
          <div class="d-flex justify-content-center">
            <v-btn rounded v-if="fromScenario == 'vente' && authUserEntreprise('remise')" class="bg-primary text-white"
              @click="applyRemise()">
              <v-icon>mdi-sack-percent</v-icon>Appliquer la remise
            </v-btn>
          </div>
          <VLabelParagraph v-if="fromScenario == 'vente' && formProduit.prix_remise > 0" label="Prix remisé"
            class="text-center" classParagraph="mt-5 text-h4 text-center fw-bold" classLabel="mt-5 text-h5"
            type="produits" field="prix_remise" :value="produit.prix_remise + ' €'">
          </VLabelParagraph>
          <div>
            <VOneSelect icon="mdi-truck" libelle="Fournisseur" :datas="fournisseurs" clef="fournisseur_id"
              itemText="libelle" class="mt-6" itemValue="id" rulesInfo="required" type="produits"
              :value="produit.fournisseur_id" :rules="[(v) => !!v || 'Le champ est obligatoire']"></VOneSelect>
            <VOneSelect icon="mdi-label-percent" :noTextValue="true" libelle="TVA" :datas="[0.0, 5.50, 10, 20]" clef="tva"
              class="mt-6" rulesInfo="required" type="produits" :value="parseFloat(produit.tva)"
              :rules="[(v) => !isNaN(v) || 'Ce n\'est pas un nombre']"></VOneSelect>
            <VOneSelect icon="mdi-shape" libelle="Catégorie de produit" :datas="categories" clef="categorie_id"
              itemText="libelle" class="mt-6" itemValue="id" rulesInfo="required" type="produits"
              :value="produit.categorie_id" :rules="[(v) => !!v || 'Le champ est obligatoire']"></VOneSelect>
          </div>
        </v-container>
        <div class="d-flex justify-content-end mt-5">
          <vBtnSave v-if="typeCRUD == 'create' || typeCRUD == 'add' || !produit.id" nom="Créer le produit" :valid="valid"
            class="mb-5" icon="mdi-plus" @saveForm="returnTo" :onlyIcon="false" table="produits">
          </vBtnSave>
          <vBtnEdit v-else-if="typeCRUD == 'edit'" nom="Modifier le produit" :valid="valid" class="mb-5" icon="mdi-pencil"
            @saveForm="returnTo" :onlyIcon="false" :id="produit.id" table="produits"></vBtnEdit>
          <v-btn rounded class="bg-color-main mb-5 text-white" @click="updateProduitVente()"
            v-else-if="typeCRUD == 'editproduitvente'">
            <v-icon>mdi-pencil</v-icon>Modifier le produit
          </v-btn>
        </div>
      </div>
    </v-form>
  </div>
</template>

<script>
/* eslint-disabled */
export default {
  data() {
    return {
      valid: true,
      isRemised: false,
      loader: false,
      produitForm: [],
      fournisseurs: [],
      categories: [],
      produit: {
        id: "",
        designation: "",
        description: "",
        tva: "",
        categorie_id: "",
        quantite: "",
        prix_unitaire: "",
        pourcentage_remise: 0,
        fournisseur_id: "",
        pivot: [],
        prix_remise: 0.00,
      },
    };
  },
  props: ["typeCRUD", "receivedProduit", "fromScenario", 'idVente', 'typeVente', 'idProduit', 'idContact'],
  mounted() {

    this.loader = true
    this.getValueRelationshipProduit()
    var count = 0
    var refreshIntervalId3 = setInterval(() => {

      if (this.receivedProduit) {
        this.produit = this.receivedProduit
        this.setFormProduit();
        clearInterval(refreshIntervalId3);
      }
      if (count > 3) {
        this.setFormProduit();
        this.loader = false

        clearInterval(refreshIntervalId3);
      }

      count++

    }, 1000);

  },
  computed: {
    formProduit: {
      get() {
        return this.$store.getters["formulaire/getFormType"]("produits");
      }
    }
  },
  methods: {
    getValueRelationshipProduit() {
      this.$axios.get('/param/getValueRelationshipProduit').then((response) => {
        this.categories = response.data.categories
        this.fournisseurs = response.data.fournisseurs
      })
    },
    setFormProduit() {
      this.$store.dispatch("formulaire/initForm", {
        form: "produits",
        listFields: {
          id: this.produit.id,
          designation: this.produit.designation,
          description: this.produit.description,
          tva: parseFloat(this.produit.tva),
          prix_unitaire: this.produit.prix_unitaire,
          quantite: this.produit.quantite,
          fournisseur_id: this.produit.fournisseur_id,
          pourcentage_remise: (this.produit.pivot) ? this.produit.pivot.pourcentage_remise : 0,
          categorie_id: this.produit.categorie_id,
          prix_remise: this.produit.prix_remise,
          prix_fournisseur: this.produit.prix_fournisseur
        },
      });
      this.loader = false
    },
    applyRemise() {
      this.isRemised = true
      var prixRemise = 0
      this.produitForm =
        this.$store.getters["formulaire/getFormType"]("produits");
      if (
        this.formProduit.pourcentage_remise == null ||
        this.formProduit.pourcentage_remise == 0
      ) {
        var payload1 = {
          form: "produits",
          field: "prix_remise",
          value: 0,
        };
        this.$store.dispatch("formulaire/updateForm", payload1);
        this.produit.prix_remise = 0
      } else {
        prixRemise = this.produitForm.prix_unitaire -
          (this.produitForm.prix_unitaire *
            this.produitForm.pourcentage_remise) /
          100
        var payload2 = {
          form: "produits",
          field: "prix_remise",
          value: prixRemise
        };
        this.$store.dispatch("formulaire/updateForm", payload2);
        this.produit.prix_remise = prixRemise
      }
    },
    updateProduitVente() {
      var payload = {
        idVente: this.idVente,
        produit: this.formProduit,
        typeVente: this.typeVente
      }
      this.$axios.post('/produits/ventes/storeProduitsVente', payload).then((resp) => {
        this.$router.push({
          name: "J'ajoute mes produits", params: {
            idVente: this.idVente,
            idContact: (this.idContact) ? this.idContact : this.$route.params.idContact,
            produitsList: resp.data
          }
        })
      })
    },
    returnTo(produitStored) {
      var produitArray = []
      if (this.$route.name == "J'edite le produit") {
        this.$store.dispatch("produits/updateProduit", produitStored);

        produitArray = this.formProduit
        produitArray['id'] = produitStored.id

        if (this.fromScenario == 'vente') {
          var payload = {
            idVente: this.idVente,
            produit: produitArray,
            typeVente: this.typeVente
          }
          this.$axios.post('/produits/ventes/storeProduitsVente', payload).then((resp) => {
            this.$router.push({
              name: "J'ajoute mes produits", params: {
                idVente: this.idVente,
                idContact: (this.idContact) ? this.idContact : this.$route.params.idContact,
                produitsList: resp.data
              }
            })
          })

        } else {
          this.$router.push({ name: "Mes produits" })
        }

      } else {
        this.$store.dispatch("produits/storeProduit", produitStored);
        if (this.fromScenario == 'vente') {
          produitArray = this.formProduit
          produitArray['id'] = produitStored.id
          var payload1 = {
            idVente: this.idVente,
            produit: produitArray,
            typeVente: this.typeVente
          }
          this.$axios.post('/produits/ventes/storeProduitsVente', payload1).then((resp) => {
            this.$router.push({
              name: "J'ajoute mes produits", params: {
                idVente: this.idVente,
                idContact: (this.idContact) ? this.idContact : this.$route.params.idContact,
                produitsList: resp.data
              }
            })
          })

        } else {
          this.$router.push({ name: "Mes produits" })
        }
      }

    }
  },
};
</script>

<style></style>