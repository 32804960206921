<template>
  <div>
    <v-form v-model="valid" lazy-validation>
      <v-row>
        <v-col cols="12" md="6">
          <v-card-title> Informations contacts </v-card-title>
          <VOneSelect icon="mdi-account" libelle="Titre" :datas="['Monsieur', 'Madame', 'Personne morale']" clef="titre"
            rulesInfo="required" type="contact" :value="contactField.titre" @value="displayPartEnterprise"
            :rules="[(v) => !!v || 'Le champ est obligatoire']"></VOneSelect>
          <VOneSelect icon="mdi-office-building-outline" libelle="Type d'entreprise" :datas="[
            'SARL',
            'UERL',
            'SA',
            'SAS',
            'SCP',
            'GIE',
            'SNC',
            'SELARL',
            'Auto-entrepreneur',
            'Micro entreprise',
            'SCOP',
            'SCA',
            'SCS',
            'Établissements d\'enseignement supérieur privés à but lucratif'
          ]" clef="type_entreprise" type="contact" :value="contactField.type_entreprise" rulesInfo="required"
            class="mt-6" v-if="titreEntreprise" :rules="[(v) => !!v || 'Le champ est obligatoire']"></VOneSelect>
          <vTextInput libelle="Nom de l'entité" v-if="titreEntreprise" clef="nom" class="mt-6" id="contact-nom"
            rulesInfo="required" :value="contactField.nom" placeholder="Entreprise dupont"
            :rules="[(v) => !!v || 'Le champ est obligatoire']" type="contact" icon="mdi-account"></vTextInput>
          <vTextInput libelle="Nom du contact" v-if="!titreEntreprise" clef="nom" class="mt-6" id="contact-nom"
            rulesInfo="required" :value="contactField.nom" placeholder="Dupont"
            :rules="[(v) => !!v || 'Le champ est obligatoire']" type="contact" icon="mdi-account"></vTextInput>
          <vTextInput libelle="Prénom du contact" v-if="!titreEntreprise" :value="contactField.prenom" clef="prenom"
            id="contact-prenom" :rules="[(v) => !!v || 'Le champ est obligatoire']" placeholder="Jean"
            rulesInfo="required" type="contact" icon="mdi-account"></vTextInput>
          <vTextInput libelle="Email" :value="contactField.email" clef="email" id="contact-email" rulesInfo="email"
            :rules="[
              (v) => /.+@.+\..+/.test(v) || 'L\'adresse email est invalide',
            ]" placeholder="exemple@exemple.com" type="contact" icon="mdi-at"></vTextInput>
          <vTextInput libelle="Téléphone fixe" clef="telephoneFixe" id="contact-telfixe" rulesInfo="nullable"
            :value="contactField.telephoneFixe" :rules="[(v) => (!isNaN(v) || v == null) || 'Ce ne sont pas des nombres']"
            placeholder="01020300405" type="contact" icon="mdi-phone"></vTextInput>
          <vTextInput libelle="Téléphone mobile" clef="telephoneMobile" id="contact-telmobile"
            :rules="[(v) => (!isNaN(v) || v == null) || 'Ce ne sont pas des nombres']" placeholder="01020300405"
            :value="contactField.telephoneMobile" type="contact" icon="mdi-cellphone">
          </vTextInput>
        </v-col>
        <v-col cols="12" md="6">
          <v-card-title> Localisation du contact </v-card-title>
          <vTextInput libelle="Adresse du contact" clef="rue" id="contact-adresse" placeholder="Rue de la République"
            :value="contactField.rue" rulesInfo="required" :rules="[(v) => !!v || 'Le champ est obligatoire']"
            type="contact" icon="mdi-map-marker"></vTextInput>
          <vTextInput libelle="Compléments" clef="complements" id="contact-adresse" :value="contactField.complements"
            placeholder="Batiment D" type="contact" icon="mdi-map-marker"></vTextInput>
          <vTextInput libelle="Code postal" clef="codePostal" id="contact-codePostal" :rules="[
            (v) => !!v || 'Le champ est obligatoire',
            (v) => !isNaN(v) || 'Ce ne sont pas des nombres',
          ]" rulesInfo="required" placeholder="75000" :value="contactField.codePostal" type="contact"
            icon="mdi-map-marker"></vTextInput>
          <vTextInput libelle="Ville" clef="ville" id="contact-ville" :rules="[(v) => !!v || 'Le champ est obligatoire']"
            rulesInfo="required" placeholder="Paris" :value="contactField.ville" type="contact" icon="mdi-map-marker">
          </vTextInput>
          <VOneSelect icon="mdi-location-enter" libelle="Origine du contact" :datas="sources" clef="source_id"
            rulesInfo="required" type="contact" :value="contactField.source_id"
            :rules="[(v) => !!v || 'Le champ est obligatoire']"></VOneSelect>
        </v-col>
      </v-row>
      <div class="d-flex justify-content-end mt-5">
        <VBtnSave v-if="mode == 'create'" nom="Créer le contact" :valid="valid" class="mb-5" icon="mdi-plus"
          @saveForm="returnTo" :onlyIcon="false" table="contact"></VBtnSave>
        <vBtnEdit v-else nom="Modifier le contact" :valid="valid" class="mb-5" icon="mdi-pencil" @saveForm="returnTo"
          :onlyIcon="false" :id="contactField.id" table="contact"></vBtnEdit>
      </div>
    </v-form>
  </div>
</template>

<script>
export default {
  props: ["contactField", "mode", "eventParams"],
  data() {
    return {
      titreValue: "",
      valid: true,
      requiredRules: [(v) => !!v || "Le champ est obligatoire"],
      numberRules: [
        (v) => !!v || "Le champ est obligatoire",
        (v) => !isNaN(v) || "Ce n'est pas un nombre",
      ],
      emailRules: [
        (v) => !!v || "Le champ doit être rempli",
        (v) => /.+@.+\..+/.test(v) || "L'adresse email est invalide",
      ],
      passwordConfirm: [
        (v) =>
          this.validatePassword(v) || "Les mots de passes ne concorde pas !",
      ],
      rules: {
        requiredRules: (v) => !!v || "Le champ est obligatoire",
        minRules: (v) =>
          (v && v.length >= 6) ||
          "Le mot de passe doit faire au minimum 6 caractères",
      },
      numberRulesNoObligatory: [(v) => !isNaN(v) || "Ce n'est pas un nombre"],
    };
  },
  mounted() {
    this.$store.dispatch("sources/setSourcesParam");
    var interval = setInterval(() => {
      if (this.contactField) {
        this.titreValue = this.contactField.titre;
        clearInterval(interval)
      }

    }, 1000)


  },
  computed: {
    sources() {
      return this.$store.getters["sources/getSources"];
    },
    titreEntreprise() {
      if (this.titreValue == "Personne morale") {
        return true;
      } else {
        if (this.titreValue) {
          var payload = {
            field: "type_entreprise",
            form: "contact",
          };
          this.$store.dispatch("formulaire/deleteKeyForm", payload);
        }

        return false;
      }
    },
  },
  methods: {
    returnTo(element) {
      switch (this.eventParams['whereReturn']) {
        case "back":
          this.$router.go(-1);
          break;
        case "folderContact":
          this.$router.push({
            name: 'Dossier du contact', params: {
              idContact: element.id,
            }
          })
          break;
        case "calendar":
          this.$store.dispatch("formulaire/updateForm", {
            form: "events",
            field: "contact_id",
            value: element.id,
          });
          this.$router.push({
            name: 'Saisir un rendez-vous', params: {
              id: this.eventParams['idEvent'],
              contact: element,
            }
          })
          break;

        default:
          break;
      }
    },
    displayPartEnterprise(event) {
      this.titreValue = event
    }
  },
};
</script>

<style></style>