<template>
  <div>
    <v-form v-model="valid">
      <input type="hidden" v-model="editedItem.id" />
      <v-col cols="12">
        <vTextInput
          libelle="Libelle"
          clef="libelle"
          class="mt-6"
          id="categories-libelle"
          rulesInfo="required"
          :value="editedItem.libelle"
          placeholder="Bois...PVC"
          :rules="[(v) => !!v || 'Le champ est obligatoire']"
          type="categories"
          icon="mdi-form-textbox"
        ></vTextInput>
      </v-col>
      <v-card-actions>
        <v-spacer></v-spacer>
        <vBtnEdit
          nom="Sauvegarder"
          v-if="validateForm() && categorie.id"
          :valid="valid"
          icon="mdi-content-save"
          @saveForm="returnTo"
          :onlyIcon="false"
          :id="editedItem.id"
          table="categories"
        ></vBtnEdit>
        <VBtnSave
          v-else
          nom="Créer"
          :valid="valid"
          icon="mdi-plus"
          @saveForm="returnTo"
          :onlyIcon="false"
          table="categories"
        ></VBtnSave>
        <v-btn class="m-1" text @click="closeModal"> Annuler </v-btn>
      </v-card-actions>
    </v-form>
  </div>
</template>

<script>
export default {
  props: ["editedItem","typeCRUD"],
  data() {
    return {
      valid: true,
      requiredRules: [(v) => !!v || "Le champ est obligatoire"],
    };
  },
  computed: {
    categorie() {
      return this.$store.getters["formulaire/getFormType"]("categories")
    }
  },
  methods: {
    validateForm() {
      if (this.categorie.libelle != "") {
        return true;
      } else {
        return false;
      }
    },
    closeModal() {
      this.$emit("close");
    },
    returnTo(categorieStored) {
      
      if (this.typeCRUD == "edit") {
        this.$store.dispatch("categories/updateCategorie", categorieStored);
      } else {
        this.$store.dispatch("categories/storeCategorie", categorieStored);
      }
      
      this.closeModal()
    },
  },
};
</script>

<style>
</style>