<template>
  <div>
    <v-card class="pb-5">
      <div class="pt-5">
        <v-select filled rounded attach multiple v-if="showManagerThing() || showRespAgenceThing()" @input="getEventsUser()"
          :loading="selectUserLoading" label="Calendrier de " :items="users" v-model="arrayUser" item-text="text"
          item-value="id">
          <template v-slot:selection="{ item }">
            <v-chip class="mt-2" :color="isDarkMode ? '#FF7900' : '#5A189A'"
              :class="isDarkMode ? 'text-dark' : 'text-white'">
              <span>{{ item.text }}</span>
            </v-chip>
          </template>
        </v-select>
      </div>
      <v-toolbar class="elevation-0" dense>
        <v-btn fab small :class="isDarkMode ? 'text-dark' : 'text-white'" :color="isDarkMode ? '#FF7900' : '#5A189A'"
          @click="prev">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-toolbar-title :class="isDarkMode ? 'text-white' : 'text-dark'" v-if="$refs.calendar">
          {{ $refs.calendar.title }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn fab small :class="isDarkMode ? 'text-dark' : 'text-white'" :color="isDarkMode ? '#FF7900' : '#5A189A'"
          @click="next">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-toolbar>
      <v-toolbar :dark="isDarkMode" class="elevation-0 d-flex justify-content-center" dense>
        <div class="text-center pb-3 pt-2">
          <v-chip @click="ChangPeriode('day')" text-color="#ffffff" color="#9D4EDD" class="mr-1">
            Journée
          </v-chip>
          <v-chip @click="ChangPeriode('week')" text-color="#ffffff" color="#3C096C" class="mr-1">
            Semaine
          </v-chip>
          <v-chip @click="ChangPeriode('month')" text-color="#ffffff" color="#240046" class="mr-1">
            Mois
          </v-chip>
        </div>
      </v-toolbar>
      <v-calendar class="border mb-5" style="margin-top: 0" ref="calendar" v-model="focus" :dark="isDarkMode"
        :events="events" :type="type" weekdays="1,2,3,4,5" locale="fr" :short-weekdays="false" first-interval="7"
        interval-minutes="60" interval-count="13" interval-height="30" @change="getEvents" :event-color="getEventColor"
        :event-text-color="getEventTextColor" @click:event="showEvent" :event-ripple="false"
        @mousedown:event="startDrag" @mousedown:time="startTime" @mousemove:time="mouseMove" @mouseup:time="endDrag"
        @mouseleave.native="cancelDrag">
        <template v-slot:event="{ event }">
          <div v-if="event.annuler">
            <div style="opacity: 0.3; overflow: auto;" :style="'color:' + event.textColor" class="v-event-draggable" v-html="
              '<strong>' +
              event.name +
              '</strong>' +
              ', ' +
              $moment(event.start).format('HH:mm') +
              ' - ' +
              $moment(event.end).format('HH:mm') +
              ' - ' +
              event.type_rdv +
              ' - ' +
              event.contact
            "></div>
          </div>
          <div v-else>
            <div :style="'color:' + event.textColor + ';overflow:auto'" class="v-event-draggable" v-html="
              '<strong>' +
              event.name +
              '</strong>' +
              ', ' +
              $moment(event.start).format('HH:mm') +
              ' - ' +
              $moment(event.end).format('HH:mm') +
              ' - ' +
              event.type_rdv +
              ' - ' +
              event.contact
            "></div>
          </div>
        </template>
      </v-calendar>
      <div class="text-center dark-bg">
        <div class="custom-control custom-switch">
          <input type="checkbox" v-model="displayCancelEvent" class="custom-control-input" id="customSwitch2" />
          <label class="custom-control-label ml-2" for="customSwitch2">Rendez-vous annulé</label>
        </div>
      </div>

      <v-menu v-model="selectedOpen" :close-on-content-click="false" :activator="selectedElement" offset-x>
        <v-card :dark="isDarkMode" min-width="350px" flat>
          <v-toolbar :color="selectedEvent.color" dark>
            <v-btn :style="'color:' + selectedEvent.textColor" icon @click="editEvent(selectedEvent)">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn :style="'color:' + selectedEvent.textColor" icon v-if="showManagerThing"
              @click="deleteEvent(selectedEvent)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>

            <v-spacer></v-spacer>
            <v-toolbar-title :style="'color:' + selectedEvent.textColor"
              v-html="selectedEvent.name + ' - ' + selectedEvent.contact"></v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <v-btn v-if="selectedEvent.contact != 'Aucun contact'"
              :class="isDarkMode ? 'bg-color-secondaire' : 'bg-color-main text-white'" class="mb-3" rounded
              @click="openContactFolder(selectedEvent.contact_id)">
              Dossier contact
            </v-btn>
            <v-spacer></v-spacer>
            <span class="font-weight-bold fs-5" v-html="
              selectedEvent.type_rdv +
              '<br>' +
              selectedEvent.contact +
              ' ' +
              '<br> Adresse: ' +
              selectedEvent.adresse +
              '<br> Début : ' +
              dateStart +
              '<br> Fin : ' +
              dateEnd +
              '<br> Commentaires : ' +
              selectedEvent.commentaires
            "></span>
          </v-card-text>
          <v-card-actions>
            <v-btn rounded class="bg-white text-dark" @click="selectedOpen = false">
              Annuler
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-card>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
export default {
  data() {
    return {
      valid: false,
      selectUserLoading: false,
      focus: "",
      users: [],
      listContacts: [],
      isLoading: false,
      createStart: null,
      dialogCreateEvent: false,
      currentUser: [],
      createEvent: null,
      dragEvent: null,
      dateDebut: null,
      dateFin: null,
      dragStart: null,
      extendOriginal: null,
      editedItem: {
        id: "",
        titre: "",
        nom: "",
        prenom: "",
        origine: "",
        email: "",
        telephoneMobile: "",
        telephoneFixe: "",
      },
      defaultItem: {
        titre: "",
        nom: "",
        prenom: "",
        origine: "",
        email: "",
        telephoneMobile: "",
        telephoneFixe: "",
      },
      newEvent: false,
      value: "",
      rdvNoClient: "",
      type: "day",
      findName: true,
      search: "",
      select: "",
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      periode: "  voir à la semaine",
      dialog: false,
      date: new Date().toISOString().substr(0, 10),
      menu1: false,
      menu2: false,
      contacts: [],
      titreForm: "",
      arrayUser: [],
      optionsSelectContact: [],
      optionsSelectTypeRdv: [],
      optionsSelectUsers: [],
      optionsSelectSources: [],
      role: "",
      displayCancelEvent: false,
      colors: ["#FA7E0A", "#F21616", "#0AB6FA", "#22FA0A", "#4CAF50", "FA0A8A"],
    };
  },
  mounted() {
    this.getUsers();
    this.getSelectOptions();
  },

  computed: {
    dateStart: function () {
      return this.$moment(this.selectedEvent.start).format("LLL");
    },
    dateEnd: function () {
      return this.$moment(this.selectedEvent.end).format("LLL");
    },
    events: {
      get() {
        return this.$store.getters["events/getEvents"];
      },
      set(val) {
        return val;
      },
    },
  },

  watch: {
    displayCancelEvent() {
      this.getEventsUser();
    },
  },

  methods: {
    ChangPeriode(value) {
      this.type = value;
      this.periode = this.type === "week" ? "journée" : "semaine";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },

    getEventColor(event) {
      return event.color;
    },

    getEventTextColor(event) {
      return event.textcolor;
    },
    getSelectOptions() {
      this.selectUserLoading = true;
      if (
        this.$store.getters["events/getOptions"]["contacts"].length > 0 &&
        this.$store.getters["events/getOptions"]["users"].length > 0 &&
        this.$store.getters["events/getOptions"]["typeRdvs"].length > 0 &&
        this.$store.getters["events/getOptions"]["sources"].length > 0
      ) {
        this.optionsSelectContact =
          this.$store.getters["events/getOptions"]["contacts"];
        this.optionsSelectUsers =
          this.$store.getters["events/getOptions"]["users"];
        this.optionsSelectTypeRdv =
          this.$store.getters["events/getOptions"]["typeRdvs"];
        this.optionsSelectSources =
          this.$store.getters["events/getOptions"]["sources"];
      } else {
        this.$store.dispatch("events/optionsEvents");

        var refreshIntervalId = setInterval(() => {
          if (this.$store.getters["events/getOptionLoad"]) {
            this.optionsSelectContact =
              this.$store.getters["events/getOptions"]["contacts"];
            this.optionsSelectUsers =
              this.$store.getters["events/getOptions"]["users"];
            this.optionsSelectTypeRdv =
              this.$store.getters["events/getOptions"]["typeRdvs"];
            this.optionsSelectSources =
              this.$store.getters["events/getOptions"]["sources"];
            clearInterval(refreshIntervalId);
          }
        }, 1000);
      }
    },
    getUsers() {
      this.selectUserLoading = true;
      this.$axios.get("manager/user/listeUsers").then((response) => {
        this.users = response.data.users;
        this.selectUserLoading = false;
        if (this.users.length > 0) {
          this.users.forEach((element) => {
            if (element.id == response.data.you.id) {
              this.arrayUser.push(element.id);
              this.getEventsUser();
            }
          });
        } else {
          this.arrayUser.push(response.data.you.id);
        }
      });
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        if (event.contact_array) {
          this.editedItem = event.contact_array;
          this.search = this.editedItem.nom;
          this.findName = false;
        }
        this.selectedElement = nativeEvent.target;
        setTimeout(() => {
          this.selectedOpen = true;
        }, 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },

    startDrag({ event }) {
      if (event) {
        this.dragEvent = event;
        this.dragTime = null;
        this.extendOriginal = null;
      }
    },
    confirmCreateEvent() {
      try {
        this.createStart = this.roundTime(this.$mouse);
        this.createEvent = {
          name: `Nouvel évenement`,
          color: this.optionsSelectTypeRdv[0]["bgColor"],
          start: this.toTimestamp(new Date(this.createStart)),
          end: this.toTimestamp(new Date(this.createStart)),
          contact_id: this.idContact,
        };

        if (this.createEvent != undefined && !this.createEvent.id) {
          var arraySend = {};
          arraySend = this.createEvent;
          arraySend["users"] = [];
          if (this.arrayUser.length > 0) {
            this.arrayUser.forEach((element, index) => {
              arraySend["users"][index] = [];
              arraySend["users"][index] = element;
            });
          } else {
            arraySend["users"] = this.arrayUser;
          }

          this.$store
            .dispatch("events/storeEvents", arraySend)
            .then((response) => {
              if (response.data.message) {
                this.$swal({
                  toast: true,
                  icon: "success",
                  title: response.data.message,
                  animation: false,
                  position: "top-right",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
              }
              this.selectedEvent = response.data.element;
              /*             this.events = this.$store.getters["events/getEvents"]; */
              this.optionsSelectUsers.forEach((element) => {
                if (element.id == this.currentUser["id"]) {
                  this.selectedEvent.user = [element];
                }
              });
              this.formRDV();
              this.newEvent = true;
              /*    this.debitCoins("SRDV"); */
            });
        }
        this.dragTime = null;
        this.dragEvent = null;
        this.createEvent = null;
        this.createStart = null;
        this.extendOriginal = null;
      } catch (error) {
        console.log(error);
      }
    },
    formRDV() {
      this.$router.push({
        name: "Saisir un rendez-vous",
        params: { id: this.selectedEvent.id },
      });
    },
    openContactFolder(contactId) {
      this.$router.push({ name: "Dossier du contact", params: { idContact: contactId } })
    },
    startTime(tms) {
      Vue.prototype.$mouse = this.toDate(tms);
      if (this.dragEvent && this.dragTime === null) {
        const start = this.dragEvent.start;
        this.dragTime = this.$mouse - start;
      } else {
        this.$swal({
          title: "Voulez-vous créer un événement ?",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "OUI ! Allons-y",
          cancelButtonText: "NON ! Pas encore",
        }).then((result) => {
          if (result.isConfirmed) {
            this.confirmCreateEvent();
          }
        });
      }
    },
    extendBottom(event) {
      this.createEvent = event;
      this.createStart = event.start;
      this.extendOriginal = event.end;
    },
    mouseMove(tms) {
      const mouse = this.toDate(tms).getTime();

      if (this.dragEvent && this.dragTime !== null) {
        const start = this.toDate(this.dragEvent.start);
        const end = this.toDate(this.dragEvent.end);
        const duration = end.getTime() - start.getTime();

        const newStartTime = mouse.getTime() - this.dragTime;
        const newStart = new Date(this.roundTime(newStartTime));
        const newEnd = new Date(newStart.getTime() + duration);

        this.dragEvent.start = this.toTimestamp(newStart);
        this.dragEvent.end = this.toTimestamp(newEnd);
      } else if (this.createEvent && this.createStart !== null) {
        const mouseRounded = this.roundTime(mouse, false);
        const min = Math.min(mouseRounded, this.createStart);
        const max = Math.max(mouseRounded, this.createStart);

        this.createEvent.start = this.toTimestamp(new Date(min));
        this.createEvent.end = this.toTimestamp(new Date(max));
      }
    },
    endDrag() {
      /* if (this.createEvent != undefined && !this.createEvent.id) {
        var arraySend = {};
        arraySend = this.createEvent;
        this.$axios.post("events/store", arraySend).then((response) => {
          if (response.data.message) {
            if (response.data.message == "Vous n'avez plus de Coins !") {
              this.$toast(response.data.message, {
                position: "top-right",
                timeout: 5000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false,
              });
            }
          }
          this.getEventsUser();
          this.selectedEvent = response.data.event;
          this.dialog = true;
          this.newEvent = true;
          this.debitCoins("SRDV");
        });
      }
      this.dragTime = null;
      this.dragEvent = null;
      this.createEvent = null;
      this.createStart = null;
      this.extendOriginal = null; */
    },
    cancelDrag() {
      if (this.createEvent) {
        if (this.extendOriginal) {
          this.createEvent.end = this.extendOriginal;
        } else {
          const i = this.events.indexOf(this.createEvent);
          if (i !== -1) {
            this.events.splice(i, 1);
          }
        }
      }

      this.createEvent = null;
      this.createStart = null;
      this.dragTime = null;
      this.dragEvent = null;
    },
    roundTime(time, down = true) {
      const roundDownTime = 15 * 60 * 1000; // 15 minutes

      return down
        ? time - (time % roundDownTime)
        : time + (roundDownTime - (time % roundDownTime));
    },
    toDate(tms) {
      return typeof tms === "string"
        ? new Date(tms)
        : new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute);
    },
    formatDate(a, withTime) {
      return withTime
        ? `${a.getFullYear()}-${a.getMonth() + 1
        }-${a.getDate()} ${a.getHours()}:${a.getMinutes()}`
        : `${a.getFullYear()}-${a.getMonth() + 1}-${a.getDate()}`;
    },
    toTimestamp(date) {
      return `${date.getFullYear()}-${date.getMonth() + 1
        }-${date.getDate()} ${date.getHours()}:${date.getMinutes()}`;
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    rndElement(arr) {
      return arr[this.rnd(0, arr.length - 1)];
    },
    getEvents({ start, end }) {
      if (start != undefined && end != undefined) {
        this.dateDebut = start;
        this.dateFin = end;
      }

      var arrayDate = { start: "", end: "", arrayUser: {}, type: this.type };
      arrayDate["start"] = this.dateDebut.date;
      arrayDate["end"] = this.dateFin.date;
      var array = [];
      this.arrayUser.forEach((value, index) => {
        array.push(value);
      });
      arrayDate["arrayUser"] = array;
      arrayDate["showCancelEvent"] = this.displayCancelEvent;

      this.$axios
        .post("events/index", arrayDate)
        .then((response) => {
          this.$store.dispatch("events/setEvents", response.data);
          this.events = this.$store.getters["events/getEvents"];
        })
        .catch((e) => {
          if (e.response.status === 401) {
            setTimeout(() => {
              this.$router.push({
                name: "Connexion",
              });
            }, 900);
          }
        });
    },
    getEventsUser() {
      this.getEvents(this.dateDebut, this.dateFin);
    },
    editEvent() {
      this.titreForm = "Modification d'un evenement";
      this.formRDV();
    },
    saveEvent() {
      if (this.selectedEvent.annuler == 1) {
        this.events.splice(this.selectedEvent, 1);
      }
      this.optionsSelectTypeRdv.forEach((element) => {
        if (this.selectedEvent.typerdv_id == element.id) {
          this.selectedEvent.color = element.color;
        }
        if (
          this.selectedEvent.typerdv_id == element.id &&
          element.hasClientNeeded != 1
        ) {
          this.editedItem = {};
          this.search = "";
          this.rdvNoClient = true;
        } else if (
          this.selectedEvent.typerdv_id == element.id &&
          element.hasClientNeeded == 1
        ) {
          this.rdvNoClient = false;
        }
      });
      var arraySendEvent = {
        event: this.selectedEvent,
        contact_id: this.editedItem.id,
      };
      if (
        (this.editedItem.id == null || this.editedItem.id == "") &&
        this.rdvNoClient == false
      ) {
        this.save();
      } else if (this.rdvNoClient) {
        arraySendEvent = {
          event: this.selectedEvent,
          contact_id: "",
        };
      }
      this.$axios
        .put("events/update/" + this.selectedEvent.id, arraySendEvent)
        .then((response) => {
          this.dialog = false;
          if (response.data.message) {
            this.$swal("Bravo !", response.data.message, "success");
          } else {
            this.$swal("Erreur !", response.data.messageError, "error");
          }
          this.getEventsUser();
          this.verifObjectif();
        });
    },
    deleteEvent(event) {
      this.$swal({
        title: "Voulez-vous supprimer cet évenement ?",
        icon: "danger",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "OUI ! Allons-y",
        cancelButtonText: "NON !",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("events/deleteEvent", event).then((response) => {
            this.$swal({
              toast: true,
              icon: "success",
              title: response.data.message,
              animation: false,
              position: "top-right",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
        }
      });
    },
  },
};
</script>

<style>
</style>