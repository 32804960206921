<template>
  <div data-app>
    <v-container style="max-width: 600px; padding-bottom: 50px" class="mt-3">
      <v-select rounded filled prepend-inner-icon="mdi-clipboard-text-clock-outline" v-if="showManagerThing()"
        :items=" itemsHistoriqueManager" v-model="historiqueFilter" label="Historique de"></v-select>
      <v-select rounded filled prepend-inner-icon="mdi-clipboard-text-clock-outline" v-else-if="showRespAgenceThing()"
        :items="itemsHistoriqueRespAgence" v-model="historiqueFilter" label="Historique de"></v-select>
      <div class="text-center mt-2 pb-3">
        <v-chip @click="filterHistory('Devis')" text-color="#ffffff" color="#9D4EDD" class="mr-1 mt-1">
          Devis
        </v-chip>
        <v-chip @click="filterHistory('Commande')" text-color="#ffffff" color="#3C096C" class="mr-1 mt-1">
          Commandes
        </v-chip>
        <v-chip @click="filterHistory('Facture')" text-color="#ffffff" color="#0804fc" class="mr-1 mt-1">
          Factures
        </v-chip>
        <v-chip @click="filterHistory('Contact')" text-color="#FFFFFF" color="#006F62" class="mr-1 mt-1">
          Contacts
        </v-chip>
        <v-btn rounded block small class="mt-3 bg-primary text-white" @click="reinitialisationHistory()">
          <v-icon>mdi-reload</v-icon>Réinitialisation
        </v-btn>
      </div>
      <v-timeline :dense="$vuetify.breakpoint.smAndDown">
        <div v-infinite-scroll="loadTimeLine" infinite-scroll-disabled="busy" infinite-scroll-limit="3">
          <div class="
              mb-4
              row-hover
            " small v-for="event in timelineFiltre" :key="event.id">

            <v-timeline-item fill-dot :color="cardColor[event.id]" small>
              <v-card class="p-4">
                <v-row class="pt-1" @click="handleClick(event)">
                  <v-col cols="8">
                    <strong>{{ formatDate(event.date_event) }} </strong>
                  </v-col>
                  <v-col cols="10">
                    <span class="lead">{{ event.type }}</span> <span class="lead">{{
                    event.event }}</span> <br>
                    <span>
                      Utilisateur : {{ event.user.prenom }} {{ event.user.nom }}
                    </span>
                    <br>
                    <span>Titre : {{ event.valeur }}</span>
                  </v-col>
                </v-row>
              </v-card>
            </v-timeline-item>
          </div>
        </div>
      </v-timeline>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      timeline: [],
      events: [],
      input: null,
      type: "",
      cardColor: [],
      dotColor: [],
      timelineFiltre: [],
      timelineMax: 0,
      nonce: 0,
      busy: false,
      itemsHistoriqueManager: ["Mon historique", "Agence", "Entreprise"],
      itemsHistoriqueRespAgence: ["Mon historique", "Agence"],
      historiqueFilter: "Mon historique",
      limitTimeLine: 3,
      role: null,
    }
  },
  mounted() {
    this.getHistoryOfUser();
  },
  watch: {
    historiqueFilter() {
      this.getHistoryOfUser();
    },
  },
  methods: {
    loadTimeLine() {
      this.busy = true;
      if (this.timeline.length <= this.timelineMax) {
        setTimeout(() => {
          this.limitTimeLine = this.limitTimeLine + 3;
          this.getHistoryOfUser();
          this.busy = false;
        }, 1000);
      }

    },
    getHistoryOfUser() {
      var array = { filter: this.historiqueFilter };
      this.$axios
        .post("historique/historyOfUser/" + this.limitTimeLine, array)
        .then((response) => {
          this.timeline = response.data.timeline;
          this.timelineFiltre = this.timeline;
          this.timelineMax = response.data.timelineMax
          this.colorCardHistory();
        });
    },
    filterHistory(type) {
      this.type = type;
      var timelineFiltre = [];
      Object.keys(this.timeline).forEach((key) => {
        if (this.timeline[key]["type"] == type) {
          timelineFiltre.push(this.timeline[key]);
        }
      });
      this.timelineFiltre = timelineFiltre;
      if (type == "Contact") {
        this.$swal({
          toast: true,
          title: "Filtrage sur les Contacts",
          icon: "info",
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timerProgressBar: true,
          timer: 2000,
        });
      } else if (type == "Commande") {
        this.$swal({
          toast: true,
          title: "Filtrage sur les Commandes",
          icon: "info",
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timerProgressBar: true,
          timer: 2000,
        });
      } else if (type == "Facture") {
        this.$swal({
          toast: true,
          title: "Filtrage sur les Factures",
          icon: "info",
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timerProgressBar: true,
          timer: 2000,
        });
      } else {
        this.$swal({
          toast: true,
          title: "Filtrage sur les " + type,
          icon: "info",
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timerProgressBar: true,
          timer: 2000,
        });
      }

      /* this.timelineFiltre = this.timeline.filter((item) => item.type == type) */
    },
    reinitialisationHistory() {
      this.timelineFiltre = this.timeline;
      this.$swal({
        toast: true,
        title: "Filtre réinitilisé",
        icon: "info",
        animation: true,
        position: "top-right",
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 2000,
      });
    },
    colorCardHistory() {
      this.timelineFiltre.forEach((element) => {
        /*this.cardColor[element["id"]] = []
        this.dotColor[element["id"]] = [] */

        switch (element["type"]) {
          case "Facture":
            this.cardColor[element["id"]] = "#DD9D00";

            switch (element["event"]) {
              case "Mise à jour":
                this.dotColor[element["id"]] = "#00CEF3";
                break;
              case "Création":
                this.dotColor[element["id"]] = "#80FF00";
                break;
              case "Suppression":
                this.dotColor[element["id"]] = "#E50000";
                break;
              case "Restoration":
                this.dotColor[element["id"]] = "#FD62FF";
                break;

              default:
                break;
            }

            break;
          case "Devis":
            this.cardColor[element["id"]] = "#9D4EDD";

            switch (element["event"]) {
              case "Mise à jour":
                this.dotColor[element["id"]] = "#00CEF3";
                break;
              case "Création":
                this.dotColor[element["id"]] = "#80FF00";
                break;
              case "Suppression":
                this.dotColor[element["id"]] = "#E50000";
                break;
              case "Restoration":
                this.dotColor[element["id"]] = "#FD62FF";
                break;

              default:
                break;
            }

            break;
          case "Commande":
            this.cardColor[element["id"]] = "#3C096C";

            switch (element["event"]) {
              case "Mise à jour":
                this.dotColor[element["id"]] = "#00CEF3";
                break;
              case "Création":
                this.dotColor[element["id"]] = "#80FF00";
                break;
              case "Suppression":
                this.dotColor[element["id"]] = "#E50000";
                break;
              case "Restoration":
                this.dotColor[element["id"]] = "#FD62FF";
                break;

              default:
                break;
            }

            break;
          case "Contact":
            this.cardColor[element["id"]] = "#006F62";

            switch (element["event"]) {
              case "Mise à jour":
                this.dotColor[element["id"]] = "#00CEF3";
                break;
              case "Création":
                this.dotColor[element["id"]] = "#80FF00";
                break;
              case "Suppression":
                this.dotColor[element["id"]] = "#E50000";
                break;
              case "Restoration":
                this.dotColor[element["id"]] = "#FD62FF";
                break;

              default:
                break;
            }

            break;
          case "Rendez-vous":
            this.cardColor[element["id"]] = "#1A9FC7";

            switch (element["event"]) {
              case "Mise à jour":
                this.dotColor[element["id"]] = "#00CEF3";
                break;
              case "Création":
                this.dotColor[element["id"]] = "#80FF00";
                break;
              case "Suppression":
                this.dotColor[element["id"]] = "#E50000";
                break;
              case "Restoration":
                this.dotColor[element["id"]] = "#FD62FF";
                break;

              default:
                break;
            }

            break;
          default:
            break;
        }
      });
    },
    handleClick(event) {
      if (event.event == "Suppression") {
        this.$axios
          .get("param/restoreModel/" + event.id_model + "/" + event.type)
          .then((response) => {
            this.$swal("Bravo !", response.data.message, "success");
            switch (event.type) {
              case "Devis":
                this.$router.push({
                  name: "J'edite un devis",
                  params: { idContact: event.contact_id, idVente: event.id_model, typeVente: 'devis' },
                });
                break;
              case "Commande":
                this.$router.push({
                  name: "J'edite une commande",
                  params: { idContact: event.contact_id, idVente: event.id_model, typeVente: 'commande' },
                });
                break;
              case "Facture":
                this.$router.push({
                  name: "Je regarde ma facture",
                  params: { idContact: event.contact_id, idVente: event.id_model, typeVente: 'facture' },
                });
                break;
              case "Rendez-vous":
                this.$router.push({
                  name: "Saisir un rendez-vous",
                  params: { id: response.data.id },
                });
                break;
              case "Contact":
                this.$router.push({
                  name: "J'edite un contact",
                  params: { idContact: response.data.id },
                });
                break;
              default:
                break;
            }
          });
      }
      switch (event.type) {
        case "Devis":
          this.$router.push({
            name: "J'edite un devis",
            params: { idContact: event.contact_id, idVente: event.id_model, typeVente: 'devis' },
          });
          break;
        case "Commande":
          this.$router.push({
            name: "J'edite une commande",
            params: { idContact: event.contact_id, idVente: event.id_model, typeVente: 'commande' },
          });
          break;
        case "Facture":
          this.$router.push({
            name: "Je regarde ma facture",
            params: { idContact: event.contact_id, idVente: event.id_model, typeVente: 'facture' },
          });
          break;
        case "Rendez-vous":
          this.$router.push({
            name: "Saisir un rendez-vous",
            params: { id: event.id_model },
          });
          break;
        case "Contact":
          this.$router.push({
            name: "J'edite un contact",
            params: { idContact: event.data.id },
          });
          break;
        default:
          break;
      }
    },
    formatDate(value) {
      return this.$moment(value).format("DD/MM/YYYY HH:mm");
    },
  },
}
</script>

<style>

</style>