<template>
  <v-container>
    <h1 class="text-ceter mb-5">Créer un nouveau contact</h1>
    <FormContact :mode="'create'" :eventParams="eventParams" :contactField="fieldValue"></FormContact>
  </v-container>
</template>

<script>
import FormContact from "@/components/contacts/FormContact.vue";
export default {
  components: { FormContact },
  props: ['paramEvent'],
  data() {
    return {
      fieldValue: {},
      eventParams: [],
    };
  },
  mounted() {
    if(this.paramEvent) {
      this.eventParams['whereReturn'] =  this.paramEvent['returnMode']
      this.eventParams['idEvent'] =  this.paramEvent['idEvent']
    } else {
      this.eventParams['whereReturn'] = "folderContact"
    }
    this.$store.dispatch("formulaire/initForm", {
      form: "contact",
      listFields: {
        titre: "",
        type_entreprise: "",
        nom: "",
        prenom: "",
        email: "",
        telephoneFixe: "",
        telephoneMobile: "",
        rue: "",
        ville: "",
        codePostal: "",
        complements: "",
        source_id: "",
      },
    });
  },
};
</script>

<style>
</style>