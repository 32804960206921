<template>
  <div>
    <div style="right: -10px">
      <vBtnAdd class="mb-2 mt-3 mr-5 ml-2" v-if="areYouEnoughCoins(1) || !showIsSubscriber()" :classItems="'bg-color-main text-white'" nom="Ajouter une produit" :element="editedItem" typeCRUD="create"
        table="produit" icon="mdi-plus"></vBtnAdd>
    </div>
    <div class="float-bottom">
      <VTableData :items="items" :loader="loader" @triggerRowTable="editItem" :headers="[
        {
          text: 'Désignation',
          align: 'left',
          sortable: true,
          value: 'designation',
        },
        {
          text: 'Description',
          align: 'left',
          sortable: true,
          value: 'description',
        },
        {
          text: 'Famille de produit',
          align: 'left',
          sortable: true,
          value: 'categorie',
        },
        {
          text: 'TVA',
          align: 'left',
          sortable: true,
          value: 'tva',
        },
        {
          text: 'Fournisseur',
          align: 'left',
          sortable: true,
          value: 'fournisseur',
        },
        {
          text: 'Prix unitaire',
          align: 'left',
          sortable: true,
          value: 'prix_unitaire',
        },
      ]" :hiddenFooter="true" :hiddenHeader="false"  :total="total" @pageChange="pageChange"></VTableData>
    </div>
  </div>
</template>
  
<script>
import vBtnAdd from "@/components/inputs/boutons/vBtnAdd.vue";
import VTableData from "@/components/modules/vTableData.vue";
import viewMixin from "@/mixins/viewMixin.js";
import variableGlobalMixin from "@/mixins/variableGlobalMixin.js";

export default {
  components: { VTableData, vBtnAdd },
  mixins: [viewMixin, variableGlobalMixin],
  data() {
    return {
      loader: false,
      formTitle: "Créer un produit",
      editedIndex: -1,
      editedItem: {
        id: "",
        designation: "",
        description: "",
        tva: "",
        categorie_id: "",
        prix_unitaire: "",
        fournisseur_id: "",
        prix_fournisseur: "",
        prix_remise: "",
      },
      role: "",
      dialog: false,
      dialogDelete: false,
      valid: true,
      typeCRUD: "",
      page:1,
      requiredRules: [(v) => !!v || "Le champ est obligatoire"],
    };
  },
  mounted() {
    this.setProduits()
  },
  computed: {
    items() {
      return this.$store.getters["produits/getProduits"];
    },
    total() {
      return this.$store.getters["produits/getTotalPaginate"];
    }
  },
  methods: {
    setProduits() {
      this.$store.dispatch("produits/setProduits", this.page);
    },
    pageChange(page) {
      this.page = page
      this.setProduits()
    },  
    validateForm() {
      if (this.editedItem.designation != "") {
        return true;
      } else {
        return false;
      }
    },
    returnTo() {
      this.$store.dispatch("produits/updateProduit", this.editedItem);
    },
    editItem(item) {
      this.typeCRUD = "edit";
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.$router.push({ name: "J'edite le produit", params: { idProduit: this.editedItem.id, receivedProduit: this.editedItem, typeCRUD: this.typeCRUD } });
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    close() {
      this.dialog = false;
      this.editedItem = {
        id: "",
        libelle: "",
      };
      this.editedIndex = -1;
    },

  },
};
</script>
  
<style>

</style>