<template>
    <div>
        <form-commande :commandeReceived="commande" :idContact="(this.idContact) ? this.idContact : this.$route.params.idContact" v-if="loadContact" :mode="mode" :contact="contact"></form-commande>
    </div>
</template>

<script>
import FormCommande from '@/components/ventes/commande/FormCommande.vue'
export default {
    components: { FormCommande },
    props: ['mode', "idVente", "typeVente", "idContact"],
    data() {
        return {
            contact: [],
            commande: [],
            loadContact: false
        }
    },
    mounted() {
        window.onbeforeunload = function () {
            return "Vous allez perdre les information du commande si vous recharger la page.";
        }
        this.getInformationsEditVente()
    },
    methods: {
        getInformationsEditVente() {
            this.$axios.get('/commande/read/'+ this.idVente).then((response) => {
                this.contact = response.data.contact
                this.commande = response.data
                this.loadContact = true
            })
        }
    },  
}
</script>

<style>

</style>