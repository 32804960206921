<template>
    <div>
        <v-toolbar class="p-1 bg-color-main">

            <v-btn icon @click="closeModal()">
                <v-icon>mdi-close</v-icon>
            </v-btn>

            <v-spacer></v-spacer>
            <v-btn icon @click="clearCanvas()">
                <v-icon large class="text-warning">mdi-eraser</v-icon>
            </v-btn>
            <v-btn icon @click="saveSignature()">
                <v-icon large class="text-light">mdi-content-save</v-icon>
            </v-btn>

        </v-toolbar>

        <h1 class="text-center mt-5">Signature</h1>
        <p class="text-center"><strong>Bon et approuvé</strong></p>
        <p class="text-center"><strong>{{ this.dateNow }}</strong></p>
        <div class="container">
            <v-card class="elevation-11 container">
                <v-card-title>Cadre de signature</v-card-title>
                <canvas id="canvas" height="400" width="300"></canvas>
            </v-card>
        </div>



    </div>
</template>
  
<script>
/* eslint-disable */
export default {
    props: ["idVente", "typeVente"],
    data() {
        return {
            radius: 5,
            start: 0,
            end: Math.PI * 2,
            dateNow: this.$moment().format('LLL'),
            dragging: false,
        };
    },
    computed: {
        canvas: {
            get() {
                return document.getElementById("canvas");
            },
            set(newVal) {
                return newVal;
            },
        },
        context() {
            return this.canvas.getContext("2d");
        },
    },
    mounted() {
        this.initCanvas();
    },
    methods: {
        closeModal() {
            this.$emit("closeModalSignature");
        },
        initCanvas() {
            this.canvas.width = window.innerWidth - 15;
            this.context.lineWidth = this.radius * 2;
            this.canvas.addEventListener("mousedown", this.engage);
            this.canvas.addEventListener("mousemove", this.putPoint);
            this.canvas.addEventListener("mouseup", this.disengage);
            document.addEventListener(
                "touchstart",
                (e) => {
                    this.mapTouchEvents(e, "mousedown");
                },
                true
            );
            document.addEventListener(
                "touchmove",
                (e) => {
                    this.mapTouchEvents(e, "mousemove");
                },
                true
            );
            document.addEventListener(
                "touchend",
                (e) => {
                    this.mapTouchEvents(e, "mouseup");
                },
                true
            );
            document.addEventListener(
                "touchcancel",
                (e) => {
                    this.mapTouchEvents(e, "mouseup");
                },
                true
            );
        },
        engage(e) {
            this.dragging = true;
            this.putPoint(e);
        },
        disengage() {
            this.dragging = false;
            this.context.beginPath();
        },
        putPoint(e) {
            if (this.dragging) {
                this.context.lineTo(e.offsetX, e.offsetY);
                this.context.stroke();
                this.context.beginPath();
                this.context.arc(
                    e.offsetX,
                    e.offsetY,
                    this.radius,
                    this.start,
                    this.end
                );
                this.context.fill(); //填滿它
                this.context.beginPath();
                this.context.moveTo(e.offsetX, e.offsetY);
            }
        },
        saveSignature() {
            var signature = this.canvas.toDataURL("image/png", 1.0);
            let formData = new FormData();
            formData.append("idVente", this.idVente);
            formData.append('typeVente', this.typeVente)
            formData.append("signature", signature);
            this.$axios
                .post("ventesDocument/saveSignatureClientImg", formData)
                .then((response) => {
                    this.$swal(
                        "Signature enregistré !",
                        "La signature a été enregistré dans la vente",
                        "success"
                    ).then(() => {
                        this.$emit("changeDateSignature", response.data.vente);
                        this.$emit("closeModalSignature");
                    });
                });
        },
        clearCanvas() {
            this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
        },
        mapTouchEvents(e, simulatedType) {
            //Ignore any mapping if more than 1 fingers touching
            if (e.changedTouches.length > 1) {
                return;
            }

            var touch = e.changedTouches[0];
            //--https://developer.mozilla.org/en/DOM/document.createEvent--
            var eventToSimulate = document.createEvent("MouseEvent");

            //--https://developer.mozilla.org/en-US/docs/Web/API/event.initMouseEvent--
            eventToSimulate.initMouseEvent(
                simulatedType, //type
                true, //bubbles
                true, //cancelable
                window, //view
                1, //detail
                touch.screenX, //screenX
                touch.screenY, //screenY
                touch.clientX, //clientX
                touch.clientY, //clientY
                false, //ctrlKey
                false, //altKey
                false, //shiftKey
                false, //metaKey
                0, //button
                null //relatedTarget
            );
            touch.target.dispatchEvent(eventToSimulate);
            //This ignores the default scroll behavior
            //e.preventDefault();
        },
    },
};
</script>
<style>
#canvas {

    display: block;
}
</style>
  