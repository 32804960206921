<template>
    <div>
        <v-container v-if="loader" class="mt-5">
            <h1>Facture en cours de chargement . . .</h1>
            <v-progress-linear indeterminate color="purple"></v-progress-linear>
        </v-container>
        <v-container v-else>
            <v-row class="fw-bold">
                <v-col class="text-start">
                    <div>
                        <h1 class="mt-5 text-start lead mb-5">Infos contact</h1>
                        <p>
                            {{ devis.contact.titre }} {{ devis.contact.nom }} {{ devis.contact.prenom }}
                        </p>
                        <p>
                            {{ devis.contact.rue }} {{ devis.contact.ville }}, {{ devis.contact.complements }} {{
                            devis.contact.codePostal }}
                        </p>
                        <p>
                            Tel Mob : {{ devis.contact.telephoneMobile }} <br>
                            Tel Fixe : {{ devis.contact.telephoneFixe }}
                        </p>
                    </div>
                </v-col>
                <v-col class="d-flex justify-content-end text-end">
                    <div>
                        <h1 class="mt-5 text-end lead mb-5">Vos infos</h1>
                        <p>
                            {{ authUserEntreprise('libelle')}}
                        </p>
                        <p>
                            {{ authUserEntreprise('rue')}} {{ authUserEntreprise('ville') }}, {{
                            authUserEntreprise('codePostal')}}
                        </p>
                        <p>
                            Tel : {{ authUserEntreprise('telephone')}}

                        </p>
                        <p v-if="devis.second_commercial">Commerciaux : {{ authUser('prenom') }} {{  authUser('nom') }} &
                            {{ devis.second_commercial_nomPrenom }}
                        </p>
                        <p v-else>
                           Commercial : {{ authUser('prenom') }} {{  authUser('nom') }}
                        </p>
                    </div>
                </v-col>

            </v-row>
            <div class="d-flex justify-content-end">
                <p class="text-end">Date devis : <strong class="fs-6">{{
                this.$moment(devis.date_vente).format("DD/MM/YYYY") }}</strong></p>
            </div>

    

            <h1 class="mt-5 text-center p-3">Produits</h1>
            <v-simple-table>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">
                                Désignation
                            </th>
                            <th class="text-left">
                                Quantité
                            </th>
                            <th class="text-left">
                                Prix unitaire
                            </th>
                            <th class="text-left">
                                PU HT Remisé
                            </th>
                            <th class="text-left">
                                TVA
                            </th>

                            <th class="text-left">
                                Prix TTC
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in devis.produits" :key="item.id">
                            <td>{{ item.designation }}</td>
                            <td>{{ item.pivot.quantite }}</td>
                            <td>{{ item.prix_unitaire }}€</td>
                            <td >{{ (item.prix_remise) ? item.prix_remise + '€' : 'Pas de remise' }}</td>
                            <td>{{ item.tva }}%</td>
                            <td>{{ parseFloat(item.prix_unitaire) + (item.prix_unitaire * (item.tva / 100)) }} €</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
            <v-row class="mt-5 pb-5">
                <h1 class="mt-5 text-center p-3">Montants / TVA</h1>
                <v-col>
                    <VLabelParagraph label="Montant TVA 5.50" class="text-center"
                        :class="isDarkMode ? 'text-white' : 'text-dark'" v-if="devis.montant_total_tva_cinq > 0"
                        classParagraph="mt-5 text-h4 text-center fw-bold" classLabel="mt-5 text-h6" type="devis"
                        field="montant_total_tva_cinq" :value="roundMontant(devis.montant_total_tva_cinq) + ' €'">
                    </VLabelParagraph>
                    <VLabelParagraph label="Montant TVA 10" class="text-center"
                        :class="isDarkMode ? 'text-white' : 'text-dark'" v-if="devis.montant_total_tva_dix > 0"
                        classParagraph="mt-5 text-h4 text-center fw-bold" classLabel="mt-5 text-h6" type="devis"
                        field="montant_total_tva_dix" :value="roundMontant(devis.montant_total_tva_dix) + ' €'">
                    </VLabelParagraph>
                    <VLabelParagraph label="Montant TVA 20" class="text-center"
                        :class="isDarkMode ? 'text-white' : 'text-dark'" v-if="devis.montant_total_tva_vingt > 0"
                        classParagraph="mt-5 text-h4 text-center fw-bold" classLabel="mt-5 text-h6" type="devis"
                        field="montant_total_tva_vingt" :value="roundMontant(devis.montant_total_tva_vingt) + ' €'">
                    </VLabelParagraph>
                </v-col>
                <v-col>
                    <VLabelParagraph label="Montant HT" class="text-center"
                        :class="isDarkMode ? 'text-white' : 'text-dark'"
                        classParagraph="mt-5 text-h4 text-center fw-bold" classLabel="text-h6" type="devis"
                        field="montant_ht" :value="devis.montant_ht + ' €'"> </VLabelParagraph>

                    <VLabelParagraph label="Montant TTC" class="text-center"
                        :class="isDarkMode ? 'text-white' : 'text-dark'"
                        classParagraph="mt-5 text-h4 text-center fw-bold" classLabel="mt-5 text-h5" type="devis"
                        field="montant_ttc" :value="roundMontant(devis.montant_ttc) + ' €'">
                    </VLabelParagraph>
                    <VLabelParagraph label="Montant remisé" class="text-center"
                        :class="isDarkMode ? 'text-white' : 'text-dark'" v-if="devis.montant_remise > 0"
                        classParagraph="mt-5 text-h4 text-center fw-bold" classLabel="mt-5 text-h6" type="devis"
                        field="montant_remise" :value="roundMontant(devis.montant_remise) + ' €'">
                    </VLabelParagraph>
                </v-col>
            </v-row>
            <v-btn class="bg-primary float-right mb-5 mr-3 text-white" rounded @click="openActionsModal()">
                <v-icon>mdi-list-box</v-icon> Actions
            </v-btn>
        </v-container>
        <v-dialog v-model="dialogActions">
            <v-card>
                <v-toolbar class="mb-5">
                    <v-btn icon @click="dialogActions = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>Que voulez-vous faire ?</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <v-row>
                        <v-col>
                            <v-card @click="openSendMailDialog()" class="p-4 blue lighten-4 text-dark text-center mb-1">
                                <v-icon class="text-dark">mdi-email-arrow-right</v-icon> <br>
                                Envoyer par mail le document
                            </v-card>
                        </v-col>
                        <v-col>
                            <v-card @click="downloadPDF()" class="p-4 text-dark pink  text-center mb-1">
                                <v-progress-circular indeterminate v-if="loadPDF"></v-progress-circular>
                                <v-icon class="text-dark" v-else>mdi-file-pdf-box</v-icon> Télécharger le PDF
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogSendMail" max-width="500px">
            <v-card>
                <v-card-title class="headline text-break">Envoyer le PDF à quel adresse email ?</v-card-title>
                <v-card-actions>
                    <v-row>
                        <v-col>
                            <v-text-field rounded filled label="Email" v-model="emailContact" required></v-text-field>
                            <v-textarea v-model="emailBody" rounded filled name="input-7-4" label="Corps du mail">
                            </v-textarea>
                            <div class="d-flex justify-content-end">
                                <v-btn class="text-dark bg-color-secondaire m-1" rounded
                                    @click="dialogSendMail = false">Annuler
                                </v-btn>
                                <v-btn @click="sendPdfMail()" :loading="loadEmailBtn" rounded
                                    class="text-white bg-color-main m-1">
                                    <v-icon>mdi-send</v-icon>Envoyer
                                </v-btn>
                            </div>

                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    props: ['idVente', "idContact", "typeVente"],
    data() {
        return {
            devis: [],
            loader: true,
            dialogActions: false,
            loadPDF: false,
            emailBody: "",
            loadEmailBtn: false,
            dialogSendMail: false,
        }
    },
    computed: {
        emailContact() {
            if (this.devis.contact) {
                return this.devis.contact.email
            }
            return ''
        }
    },
    mounted() {
        this.readFacture()
    },
    methods: {
        readFacture() {
            this.loader = true
            this.$axios.get('/devis/read/' + this.idVente).then((response) => {
                this.devis = response.data
                this.loader = false
            })
        },
        openActionsModal() {
            this.dialogActions = true
        },
        downloadPDF() {
            this.loadPDF = true;
            this.$axios({
                url: "ventesDocument/pdf",
                method: "POST",
                data: {
                    idVente: this.devis.id,
                    typeVente: this.typeVente
                },
                responseType: "blob",
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute(
                    "download",
                    this.devis.libelle + "-" + this.devis.date_devis + ".pdf"
                );
                document.body.appendChild(link);
                link.click();
                this.loadPDF = false;
            });
        },
        openSendMailDialog() {
            this.dialogSendMail = true
        },
        sendPdfMail() {
            this.loadEmailBtn = true
            var payload = {
                idVente: this.devis.id,
                body: this.emailBody,
                email: this.devis.contact.email,
                typeVente: this.typeVente
            }
            this.$axios.post("/ventesDocument/sendPdfMail", payload).then((response) => {
                this.dialogSendMail = false;
                this.$swal("Bravo !", response.data.message, "success");
                this.loadEmailBtn = false
            })
        },
    }
}
</script>

<style>

</style>