<template>
  <div>
    <v-select
      multiple
      v-model="arrayDatas"
      :items="items"
      :label="label"
      :rules="rules"
      :name="clef"
      hide-details
      filled
      rounded
      :item-text="itemText"
      :item-value="itemValue"
      :placeholder="placeholder"
      :prepend-inner-icon="icon"
      single-line
    >
      <template v-slot:label v-if="rulesInfo == 'required'">
        <span class="input__label"
          >{{ libelle }} <span style="color: red">*</span></span
        >
      </template>
      <template v-slot:label v-else>
        <span class="input__label">{{ libelle }}</span>
      </template>
      <template v-slot:first> -- Choisissez une option -- </template></v-select
    >
  </div>
</template>

<script>
export default {
  props: [
    "libelle",
    "rulesInfo",
    "icon",
    "clef",
    "rules",
    "type",
    "value",
    "placeholder",
    "label",
    "datas",
    "itemText",
    "itemValue",
  ],
  watch: {
    arrayDatas: {
      deep: true,
      handler(newValue) {
        this.$store.dispatch("formulaire/updateForm", {
          form: this.type,
          field: this.clef,
          value: newValue,
        });
      }

    },
  },
  mounted() {
    this.arrayDatas = this.value;
  },
  data() {
    return {
      arrayDatas: [],
    };
  },
  computed: {
    modelValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$store.dispatch("formulaire/updateForm", {
          form: this.type,
          field: this.clef,
          value: val,
        });
      },
    },
    items() {
      return this.datas;
    },
  },
};
</script>

<style>
</style>