/* eslint-disable */
import axios from 'axios'

export default {
    namespaced: true,
    state: {
        coins: null,
    },
    mutations: {
        SET_COIN(state, coins) {
            state.coins = coins
        },
    },
    getters: {
        myCoins(state) {
            return state.coins
        },
    },
    actions: {
        getMyCoins({ commit }) {
            return new Promise((resolve, reject) => {
                axios.get('/coins/monSoldesCoins').then((response) => {
                    commit('SET_COIN', response.data)
                    resolve(response)
                }).catch(function(error) {
                    if (error.response.status === 401) {
                        setTimeout(() => {
                            router.push({
                                name: "Connexion"
                            });
                        }, 1200);
                    }
                })
            })
        },
        async debitCoins({ commit }, array) {
            let response = await axios.post('/coins/debiterCoins', array)
            commit('SET_COIN', response.data.coins)
            return response
        },
    }
}