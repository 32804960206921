/* eslint-disable */
import Vue from 'vue'
import axios from 'axios'
import router from "/src/router";
export default {
    namespaced: true,
    state: {
        reglements: [],
        totalPaginate: 0
    },
    mutations: {
        setReglements(state, datas) {
            state.reglements = datas
        },
        storeReglement(state, data) {
            state.reglements.push(data)
        },
        updateReglement(state, data) {
            var index = state.reglements.findIndex(s => s.id === data.id);
            Vue.set(state.reglements, index, data);
        },
        deleteReglement(state, id) {
            var index = state.reglements.findIndex(reglement => reglement.id == id)
            state.reglements.splice(index, 1)
        },
        setTotalPaginate(state, data) {
            state.totalPaginate = data
        }
    },
    getters: {
        getReglements(state) {
            return state.reglements
        },
        getTotalPaginate(state) {
            return state.totalPaginate
        }
    },
    actions: {
        setReglements({ commit }, page) {
            axios.get('reglements/index?page=' + page).then((response) => {
                commit('setTotalPaginate', response.data.last_page)
                commit('setReglements', response.data.data)
            }).catch(function(error) {
                if (error.response.status === 401) {
                    setTimeout(() => {
                        router.push({
                            name: "Connexion"
                        });
                    }, 900);
                }
            });
        },
        updateReglement({ commit }, data) {
            commit('updateReglement', data)
        },
        storeReglement({ commit }, data) {
            commit('storeReglement', data)
        },
        deleteReglement({commit}, id) {
            commit('deleteReglement', id)
        }
    }
}