/* eslint-disable */
import Vue from 'vue'
import axios from 'axios'
import router from "/src/router";
export default {
    namespaced: true,
    state: {
        sources: [],
        totalPaginate: 0,
    },
    mutations: {
        setSources(state, datas) {
            state.sources = datas
        },
        storeSource(state, data) {
            state.sources.push(data)
        },
        updateSource(state, data) {
            var index = state.sources.findIndex(s => s.id === data.id);
            Vue.set(state.sources, index, data);
        },
        deleteSource(state, id) {
            var index = state.sources.findIndex(source => source.id == id)
            state.sources.splice(index, 1)
        },
        setTotalPaginate(state, data) {
            state.totalPaginate = data
        }
    },
    getters: {
        getSources(state) {
            return state.sources
        },
        getTotalPaginate(state) {
            return state.totalPaginate
        }
    },
    actions: {
        setSourcesParam({ commit }) {
            axios.get('param/getSources').then((response) => {
                commit('setSources', response.data)
            })
        },
        setSources({ commit }, page) {
            axios.get('sources/index?page=' + page).then((response) => {
                commit('setSources', response.data.data)
                commit('setTotalPaginate', response.data.last_page)
            }).catch(function(error) {
                if (error.response.status === 401) {
                    setTimeout(() => {
                        router.push({
                            name: "Connexion"
                        });
                    }, 900);
                }
            });
        },
           updateSource({ commit }, data) {
            commit('updateSource', data)
        },
        storeSource({ commit }, data) {
            commit('storeSource', data)
        },
        deleteSource({commit}, id) {
            commit('deleteSource', id)
        }
    }
}