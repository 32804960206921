<template>
  <!--5A189A-->
  <v-bottom-navigation :color="'#ffffff'" :background-color="'#5A189A'" shift app fixed grow>
    <v-btn :to="{ name: 'Mes rendez-vous' }">
      <span>Calendrier</span>
      <v-icon>mdi-calendar</v-icon>
    </v-btn>

    <v-btn :to="{ name: 'Mes contacts' }">
      <span>Contacts</span>
      <v-icon>mdi-contacts</v-icon>
    </v-btn>

    <v-btn :to="{ name: 'Accueil' }" :style="(isMobile) ? 'bottom: 30%; height:70px !important; width:500px !important' : 'bottom: 30%; height:70px !important; width:75px; !important'" class="rounded-circle bg-color-main" fab>
      <span>Accueil</span>
      <v-icon x-large>mdi-home</v-icon>
    </v-btn>

    <v-btn :to="{ name: 'Mes statistiques' }">
      <span>Stats</span>
      <v-icon>mdi-chart-bar-stacked</v-icon>
    </v-btn>

    <v-btn :to="{ name: 'Mon profil' }">
      <span>Profil</span>
      <v-icon>mdi-account</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
export default {
  data: () => ({}),
};
</script>

<style>
.v-item-group.v-bottom-navigation .v-btn {
  min-width: 0px !important;
}
</style>