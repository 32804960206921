<template>
  <div>
    <TableauProduit
      class="mt-5"
      @updateMontantTva="updateMontantTva"
      :idVente="idVente"
      :vente="vente"
      :idContact="
        this.idContact ? this.idContact : this.$route.params.idContact
      "
      :produitsList="produitsList"
      :typeVente="typeVente"
      @selectItem="selectItem"
    ></TableauProduit>

    <v-card>
      <h1 class="mt-5 text-center p-3">Montants / TVA</h1>

      <v-card-text>
        <div
          v-if="
            vente.montant_ht == 0 &&
            vente.montant_ttc == 0 &&
            vente.montant_total_tva_cinq == 0
          "
        >
          <VLabelParagraph
            label="Montant HT"
            class="text-center"
            :class="isDarkMode ? 'text-white' : 'text-dark'"
            classParagraph="mt-5 text-h4 text-center fw-bold"
            classLabel="text-h6"
            type="devis"
            field="montant_ht"
            :value="vente.montant_ht + ' €'"
          >
          </VLabelParagraph>

          <VLabelParagraph
            label="Montant TTC"
            class="text-center"
            :class="isDarkMode ? 'text-white' : 'text-dark'"
            classParagraph="mt-5 text-h4 text-center fw-bold"
            classLabel="mt-5 text-h5"
            type="devis"
            field="montant_ttc"
            :value="roundMontant(vente.montant_ttc) + ' €'"
          >
          </VLabelParagraph>
          <VLabelParagraph
            label="Montant remisé"
            class="text-center"
            :class="isDarkMode ? 'text-white' : 'text-dark'"
            v-if="vente.montant_remise > 0"
            classParagraph="mt-5 text-h4 text-center fw-bold"
            classLabel="mt-5 text-h6"
            type="devis"
            field="montant_remise"
            :value="roundMontant(vente.montant_remise) + ' €'"
          >
          </VLabelParagraph>
        </div>
        <v-row v-else>
          <v-col>
            <VLabelParagraph
              label="Montant TVA 5.50"
              class="text-center"
              :class="isDarkMode ? 'text-white' : 'text-dark'"
              v-if="vente.montant_total_tva_cinq > 0"
              classParagraph="mt-5 text-h4 text-center fw-bold"
              classLabel="mt-5 text-h6"
              type="devis"
              field="montant_total_tva_cinq"
              :value="roundMontant(vente.montant_total_tva_cinq) + ' €'"
            >
            </VLabelParagraph>
            <VLabelParagraph
              label="Montant TVA 10"
              class="text-center"
              :class="isDarkMode ? 'text-white' : 'text-dark'"
              v-if="vente.montant_total_tva_dix > 0"
              classParagraph="mt-5 text-h4 text-center fw-bold"
              classLabel="mt-5 text-h6"
              type="devis"
              field="montant_total_tva_dix"
              :value="roundMontant(vente.montant_total_tva_dix) + ' €'"
            >
            </VLabelParagraph>
            <VLabelParagraph
              label="Montant TVA 20"
              class="text-center"
              :class="isDarkMode ? 'text-white' : 'text-dark'"
              v-if="vente.montant_total_tva_vingt > 0"
              classParagraph="mt-5 text-h4 text-center fw-bold"
              classLabel="mt-5 text-h6"
              type="devis"
              field="montant_total_tva_vingt"
              :value="roundMontant(vente.montant_total_tva_vingt) + ' €'"
            >
            </VLabelParagraph>
          </v-col>
          <v-col>
            <VLabelParagraph
              label="Montant HT"
              class="text-center"
              :class="isDarkMode ? 'text-white' : 'text-dark'"
              classParagraph="mt-5 text-h4 text-center fw-bold"
              classLabel="text-h6"
              type="devis"
              field="montant_ht"
              :value="vente.montant_ht + ' €'"
            >
            </VLabelParagraph>

            <VLabelParagraph
              label="Montant TTC"
              class="text-center"
              :class="isDarkMode ? 'text-white' : 'text-dark'"
              classParagraph="mt-5 text-h4 text-center fw-bold"
              classLabel="mt-5 text-h5"
              type="devis"
              field="montant_ttc"
              :value="roundMontant(vente.montant_ttc) + ' €'"
            >
            </VLabelParagraph>
            <VLabelParagraph
              label="Montant remisé"
              class="text-center"
              :class="isDarkMode ? 'text-white' : 'text-dark'"
              v-if="vente.montant_remise > 0"
              classParagraph="mt-5 text-h4 text-center fw-bold"
              classLabel="mt-5 text-h6"
              type="devis"
              field="montant_remise"
              :value="roundMontant(vente.montant_remise) + ' €'"
            >
            </VLabelParagraph>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <div class="d-flex justify-content-end">
      <div class="btn-group-vertical">
        <v-btn
          rounded
          v-if="!vente.isDone"
          class="bg-color-main mb-5 mt-5 mr-3 text-white"
          @click="returnTo('Dossier du contact')"
        >
          <v-icon>mdi-content-save</v-icon> Enregistrer {{ venteName }}
        </v-btn>
        <div v-if="!vente.isValidated" class="mb-3 mt-5 mr-3">
          <v-btn
            rounded
            class="bg-color-secondaire text-dark"
            @click="validateVente()"
            :disabled="
              typeVente == 'devis'
                ? showIsSubscriber()
                  ? areYouEnoughCoins(1)
                    ? false
                    : true
                  : false
                : false
            "
          >
            <v-icon>mdi-check</v-icon> Valider {{ venteName }} </v-btn
          ><btn-aide-contextuel
            class="float-right ml-3"
            :description="'En validant, vous pourrez accéder a des actions supplémentaire sur le document, cela vous permettra également de changer un devis en commande ou une commande en facture'"
          ></btn-aide-contextuel>
        </div>

        <v-btn
          v-else
          class="bg-primary mb-5 mt-5 mr-3 text-white"
          rounded
          @click="openActionsModal()"
        >
          <v-icon>mdi-list-box</v-icon> Actions
        </v-btn>

        <v-btn
          rounded
          v-if="!vente.isDone"
          class="bg-danger mb-5 mt-5 mr-3 text-white"
          @click="deleteVente()"
        >
          <v-icon>mdi-check</v-icon> Supprimer {{ venteName }}
        </v-btn>
      </div>
    </div>

    <v-dialog v-model="dialogActions">
      <v-card>
        <v-toolbar class="mb-5">
          <v-btn icon @click="dialogActions = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Que voulez-vous faire ?</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col>
              <v-card
                :disabled="
                  typeVente == 'devis'
                    ? showIsSubscriber()
                      ? areYouEnoughCoins(1)
                        ? false
                        : true
                      : false
                    : false
                "
                @click="
                  typeVente == 'devis'
                    ? changeTypeVente()
                    : openDialogChangeDateDocument()
                "
                class="p-4 bg-primary text-white text-center mb-1"
                v-if="
                  vente.date_signature && vente.montant != 0 && !vente.isDone
                "
              >
                <v-icon class="text-white">mdi-file-move</v-icon>
                <v-icon class="text-white">mdi-file</v-icon> <br />
                Passer {{ venteName }} en
                {{ venteName == "le devis" ? "commande" : "facture" }}
              </v-card>
              <v-card
                @click="openSignatureCanva()"
                v-if="vente.isSigned != 1 && !vente.isDone"
                class="p-4 deep-purple lighten-3 text-dark text-center mb-1"
              >
                <v-icon class="text-dark">mdi-draw</v-icon> <br />
                Signer le devis par le client
              </v-card>
              <v-card
                @click="openSendMailDialog()"
                class="p-4 blue lighten-4 text-dark text-center mb-1"
              >
                <v-icon class="text-dark">mdi-email-arrow-right</v-icon> <br />
                Envoyer par mail le document
              </v-card>
              <v-card
                @click="generateFactureProFormat()"
                v-if="typeVente == 'commande'"
                class="p-4 teal text-white text-center mb-1"
              >
                <v-progress-circular
                  indeterminate
                  v-if="loadFactureProFormat"
                ></v-progress-circular>
                <v-icon class="text-white" v-else>mdi-file</v-icon> <br />
                Facture pro-format
              </v-card>
            </v-col>
            <v-col>
              <v-card
                @click="downloadPDF()"
                class="p-4 text-dark pink text-center mb-1"
              >
                <v-progress-circular
                  indeterminate
                  v-if="loadPDF"
                ></v-progress-circular>
                <v-icon class="text-dark" v-else>mdi-file-pdf-box</v-icon>
                Télécharger le PDF
              </v-card>
              <v-card
                @click="createModel()"
                class="p-4 bg-white text-dark text-center mb-1"
              >
                <v-icon class="text-dark">mdi-content-duplicate</v-icon> <br />
                Créer un modèle
              </v-card>
              <v-card
                v-if="
                  (vente.annuler == 0 || vente.annuler == null) && !vente.isDone
                "
                @click="cancelVente()"
                class="p-4 bg-danger text-dark text-center mn-1"
              >
                <v-icon class="text-dark">mdi-file-cancel-outline</v-icon>
                <br />
                Annuler la vente
              </v-card>
              <v-card
                v-else-if="!vente.isDone"
                @click="retablishVente()"
                class="p-4 bg-danger text-dark text-center mn-1"
              >
                <v-icon class="text-dark">mdi-file-cancel-outline</v-icon>
                <br />
                Rétablir la vente
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogChangeDateDocument" max-width="500px">
      <v-card class="p-5">
        <v-card-title class="headline text-break"
          >Changer la date de la facture ?</v-card-title
        >
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="vente.date_vente"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="vente.date_vente"
              filled
              rounded
              prepend-inner-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            >
              <template v-slot:label>
                <span class="input__label">Date de la facture</span>
              </template>
            </v-text-field>
          </template>
          <v-date-picker
            min="1950-01-01"
            v-model="vente.date_vente"
            no-title
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="closeModal()">
              Réinitialiser
            </v-btn>
            <v-btn text color="primary" @click="menu = false"> Fermer </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.menu.save(vente.date_vente)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="text-dark bg-color-secondaire m-1"
            rounded
            @click="dialogChangeDateDocument = false"
            >Annuler
          </v-btn>
          <v-btn
            class="text-white bg-color-main m-1"
            rounded
            @click="verfiyDateFacture()"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogSendMail" max-width="500px">
      <v-card>
        <v-card-title class="headline text-break"
          >Envoyer le PDF à quel adresse email ?</v-card-title
        >
        <v-card-actions>
          <v-row>
            <v-col>
              <v-text-field
                rounded
                filled
                label="Email"
                v-model="emailContact"
                required
              ></v-text-field>
              <v-textarea
                v-model="emailBody"
                rounded
                filled
                name="input-7-4"
                label="Corps du mail"
              ></v-textarea>
              <div class="d-flex justify-content-end">
                <v-btn
                  class="text-dark bg-color-secondaire m-1"
                  rounded
                  @click="dialogSendMail = false"
                  >Annuler</v-btn
                >
                <v-btn
                  @click="sendPdfMail()"
                  :loading="loadEmailBtn"
                  rounded
                  class="text-white bg-color-main m-1"
                >
                  <v-icon>mdi-send</v-icon>Envoyer
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogSignature" persistent fullscreen>
      <v-card>
        <CadreSignature
          :idVente="vente.id"
          :typeVente="typeVente"
          @closeModalSignature="closeModalSignature"
          @changeDateSignature="changeDateSignature"
        ></CadreSignature>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  props: ["idVente", "produitsList", "idContact", "typeVente"],
  data() {
    return {
      vente: [],
      dialogActions: false,
      dialogSignature: false,
      dialogSendMail: false,
      dialogChangeEtatDocument: false,
      dialogChangeDateDocument: false,
      emailBody: "",
      loadPDF: false,
      loadEmailBtn: false,
      loadFactureProFormat: false,
      menu: false,
    };
  },
  mounted() {
    window.onbeforeunload = function () {
      return "Vous allez perdre les information du devis si vous recharger la page.";
    };
  },
  computed: {
    venteName() {
      if (this.typeVente == "devis") {
        return "le devis";
      } else {
        return "la commande";
      }
    },
    emailContact() {
      if (this.vente.contact) {
        return this.vente.contact.email;
      }
      return "";
    },
  },
  methods: {
    selectItem(item) {
      this.$router.push({
        name: "J'edite le produit de ma vente",
        params: {
          idContact: this.idContact
            ? this.idContact
            : this.$route.params.idContact,
          idProduit: item.id_produit,
          typeCRUD: "edit",
          fromScenario: "vente",
          idVente: this.idVente,
          typeVente: this.typeVente,
        },
      });
    },
    updateMontantTva(vente) {
      this.vente = vente;
    },
    returnTo(path) {
      switch (this.typeVente) {
        case "devis":
          this.$swal({
            toast: true,
            title: "Le devis à été enregistré avec les produits !",
            icon: "success",
            animation: true,
            position: "top-right",
            showConfirmButton: false,
            timerProgressBar: true,
            timer: 2000,
          });
          break;
        case "commande":
          this.$swal({
            toast: true,
            title: "La commande à été enregistré avec les produits !",
            icon: "success",
            animation: true,
            position: "top-right",
            showConfirmButton: false,
            timerProgressBar: true,
            timer: 2000,
          });
          break;

        default:
          break;
      }

      this.$router.push({
        name: path,
        params: {
          idContact: this.idContact ? this.idContact : this.vente.contact_id,
        },
      });
    },
    deleteVente() {
      this.$swal({
        title: "Voulez-vous supprimer ce document ?",
        showDenyButton: true,
        type: "danger",
        showCancelButton: false,
        confirmButtonText: "Oui",
        denyButtonText: `Non`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$axios
            .delete(this.typeVente + "/delete/" + this.idVente)
            .then((response) => {
              this.$swal({
                toast: true,
                title: response.data.message,
                icon: "success",
                animation: true,
                position: "top-right",
                showConfirmButton: false,
                timerProgressBar: true,
                timer: 2000,
              });
              this.$router.push({
                name: "Dossier du contact",
                params: {
                  idContact: this.idContact
                    ? this.idContact
                    : this.vente.contact_id,
                },
              });
            });
        }
      });
    },
    validateVente() {
      if (this.vente.produits.length > 0) {
        var title = "";

        if (this.showIsSubscriber()) {
          if (this.typeVente == "devis") {
            title =
              "Voulez-vous valider le devis, vous ne pourrez plus revenir en arrière après cette action. Cela vous coûtera 1 Coins !";
          } else {
            title =
              "Voulez-vous valider la commande, vous ne pourrez plus revenir en arrière après cette action !";
          }
        } else {
          if (this.typeVente == "devis") {
            title =
              "Voulez-vous valider le devis, vous ne pourrez plus revenir en arrière après cette action.";
          } else {
            title =
              "Voulez-vous valider la commande, vous ne pourrez plus revenir en arrière après cette action.";
          }
        }

        this.$swal({
          title: title,
          showDenyButton: true,
          type: "danger",
          showCancelButton: false,
          confirmButtonText: "Oui",
          denyButtonText: `Non`,
        }).then((result) => {
          if (result.isConfirmed) {
            this.$axios
              .get(this.typeVente + "/validateDocument/" + this.idVente)
              .then((response) => {
                this.$swal({
                  toast: true,
                  title: response.data.message,
                  icon: "success",
                  animation: true,
                  position: "top-right",
                  showConfirmButton: false,
                  timerProgressBar: true,
                  timer: 2000,
                });
                this.vente = response.data.element;
                if (this.typeVente == "devis") {
                  this.debitCoins("SD");
                  this.verifObjectif();
                }
              });
          }
        });
      } else {
        this.$swal({
          toast: true,
          title: "Vous devez d'abord ajouter au moins 1 produit !",
          icon: "warning",
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timerProgressBar: true,
          timer: 2000,
        });
      }
    },
    cancelVente() {
      this.$swal({
        title: "Voulez-vous annuler ce document ?",
        showDenyButton: true,
        type: "danger",
        showCancelButton: false,
        confirmButtonText: "Oui",
        denyButtonText: `Non`,
      }).then((result) => {
        if (result.isConfirmed) {
          var payload = {
            idVente: this.vente.id,
            typeVente: this.typeVente,
          };
          this.$axios
            .post("ventesDocument/cancel", payload)
            .then((response) => {
              this.$swal("Bravo !", response.data.message, "success").then(
                () => {
                  this.vente = response.data.element;
                }
              );
            });
        }
      });
    },
    openActionsModal() {
      this.dialogActions = true;
    },
    createModel() {
      var payload = {
        idVente: this.vente.id,
        typeVente: this.typeVente,
      };
      this.$axios
        .post("/ventesDocument/createModel", payload)
        .then((response) => {
          this.$swal({
            toast: true,
            title: response.data.message,
            icon: "success",
            animation: true,
            position: "top-right",
            showConfirmButton: false,
            timerProgressBar: true,
            timer: 2000,
          });
        });
    },
    downloadPDF() {
      this.loadPDF = true;
      this.$axios({
        url: "ventesDocument/pdf",
        method: "POST",
        data: {
          idVente: this.vente.id,
          typeVente: this.typeVente,
        },
        responseType: "blob",
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        console.log(this.vente.date_vente);
        link.setAttribute(
          "download",
          this.vente.libelle + "-" + this.vente.date_vente + ".pdf"
        );
        document.body.appendChild(link);
        link.click();
        this.loadPDF = false;
      });
    },
    verfiyDateFacture() {
      this.$axios
        .get("factures/verfiyDateFacture/" + this.vente.date_vente)
        .then((response) => {
          if (response.data.result) {
            this.$swal(
              "Aie, aie, aie !",
              "La date de facture doit être supérieur à la dernière facture !",
              "error"
            );
          } else {
            this.changeTypeVente();
            this.dialogChangeDateDocument = false;
            this.$emit("closeModal");
          }
        });
    },
    retablishVente() {
      this.$swal({
        title: "Voulez-vous rétablir ce document ?",
        showDenyButton: true,
        type: "danger",
        showCancelButton: false,
        confirmButtonText: "Oui",
        denyButtonText: `Non`,
      }).then((result) => {
        if (result.isConfirmed) {
          var payload = {
            idVente: this.vente.id,
            typeVente: this.typeVente,
          };
          this.$axios
            .post("ventesDocument/retablish", payload)
            .then((response) => {
              this.$swal("Bravo !", response.data.message, "success").then(
                () => {
                  this.vente = response.data.element;
                }
              );
            });
        }
      });
    },
    openSendMailDialog() {
      this.dialogSendMail = true;
    },
    sendPdfMail() {
      this.loadEmailBtn = true;
      var payload = {
        idVente: this.vente.id,
        body: this.emailBody,
        email: this.vente.contact.email,
        typeVente: this.typeVente,
      };
      this.$axios
        .post("/ventesDocument/sendPdfMail", payload)
        .then((response) => {
          this.dialogSendMail = false;
          this.$swal("Bravo !", response.data.message, "success");
          this.loadEmailBtn = false;
        });
    },
    openDialogChangeDateDocument() {
      this.dialogChangeDateDocument = true;
    },
    changeTypeVente() {
      var newTypeVente = this.typeVente == "devis" ? "commande" : "facture";
      var title =
        "Voulez-vous passer votre " + this.typeVente + " en " + newTypeVente;

      this.$swal({
        title: title,
        showDenyButton: true,
        type: "danger",
        showCancelButton: false,
        confirmButtonText: "Oui",
        denyButtonText: `Non`,
      }).then((result) => {
        if (result.isConfirmed) {
          var payload = {
            idVente: this.vente.id,
            typeVente: this.typeVente,
            date_vente: this.vente.date_vente,
          };
          this.$axios
            .post("ventesDocument/changeTypeVente", payload)
            .then((response) => {
              if (response.data.messageCoin) {
                this.$swal(
                  "Pas assez de coins !",
                  response.data.messageCoin,
                  "error"
                );
                return;
              }
              this.$swal("Bravo !", response.data.message, "success");
              if (newTypeVente == "commande") {
                this.debitCoins("SC");
              } else {
                this.debitCoins("SF", this.vente.montant_ht);
              }
              this.verifObjectif();
              this.dialogActions = false;
              this.$router.push({
                name: "Dossier du contact",
                params: {
                  idContact: this.idContact
                    ? this.idContact
                    : this.vente.contact_id,
                },
              });
            });
        }
      });
    },
    openSignatureCanva() {
      this.dialogSignature = true;
    },
    closeModalSignature() {
      this.dialogSignature = false;
    },
    changeDateSignature(payload) {
      this.vente = payload;
      console.log(this.vente);
    },
    generateFactureProFormat() {
      this.loadFactureProFormat = true;
      this.$axios({
        url: "ventesDocument/pdfFactureProFormat/" + this.vente.id,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          this.typeVente + "-" + this.vente.libelle + ".pdf"
        );
        document.body.appendChild(link);
        link.click();
        this.loadFactureProFormat = false;
      });
    },
  },
};
</script>

<style>
</style>
