/* eslint-disable */
import Vue from "vue";
import axios from "axios";
import router from "/src/router";

export default {
    namespaced: true,
    state: {
        factures: [],
    },
    mutations: {
        setFacture(state, datas) {
            state.factures = datas;
        },
    },
    getters: {
        getFacture(state) {
            return state.factures;
        },
    },
    actions: {
        setFacture({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios.get('/factures/index/' + payload.idContact + '?page=' + payload.page).then((response) => {
                    commit("setFacture", response.data);
                    resolve(response);
                })
            });
        },
    },
};