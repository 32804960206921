<template>
    <div>
        <div class="float-bottom">
            <VTableData :items="items" :loader="loader" @triggerRowTable="editItem" :headers="[
              {
                text: 'Titre du modèle',
                align: 'left',
                sortable: true,
                value: 'titre',
              }, {
                text: 'Type de vente',
                align: 'left',
                sortable: true,
                value: 'typeVente',
              },
            ]" :hiddenFooter="true" :hiddenHeader="false" :total="total" @pageChange="pageChange"></VTableData>
        </div>
        <v-dialog v-model="dialog">
            <v-card>
                <v-toolbar>
                    <v-btn icon @click="dialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>Que voulez-vous faire ?</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <v-row>
                        <v-col cols="6">
                            <v-card class="bg-color-main p-4 text-center" @click="watchModel()" dark>
                                <v-icon> mdi-eye-settings</v-icon> Afficher le modèle
                            </v-card>
                        </v-col>
                        <v-col cols="6">
                            <v-card class="bg-danger p-4 text-center" @click="deleteItem()" dark>
                                <v-icon> mdi-delete</v-icon> Supprimer le modèle
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
  
<script>
import VTableData from "@/components/modules/vTableData.vue";
import viewMixin from "@/mixins/viewMixin.js";

export default {
    components: { VTableData },
    mixins: [viewMixin],
    data() {
        return {
            loader: false,
            page: 1,
            editedIndex: -1,
            editedItem: {},
            dialog: false,
        };
    },
    mounted() {
        this.setModels()
    },
    computed: {
        items() {
            return this.$store.getters["models/getModels"];
        },
        total() {
            return this.$store.getters["models/getTotalPaginate"];
        }
    },
    methods: {
        setModels() {
            this.$store.dispatch("models/setModels", this.page);
        },
        pageChange(page) {
            this.page = page
            this.setModels()
        },
        editItem(item) {
            this.editedIndex = this.items.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialog = true;
        },
        watchModel() {
            switch (this.editedItem.typeVente) {
                case "devis":
                    this.$router.push({
                        name: "J'edite un devis",
                        params: { idVente: this.editedItem.id_for_model, typeVente: this.editedItem.typeVente, idContact: this.editedItem.idContact },
                    });
                    break;
                case "commande":
                    this.$router.push({
                        name: "J'edite une commande",
                        params: { idVente: this.editedItem.id_for_model, typeVente: this.editedItem.typeVente, idContact: this.editedItem.idContact },
                    });
                    break;
                case "facture":
                    this.$router.push({
                        name: "Je regarde ma facture",
                        params: { idVente: this.editedItem.id_for_model, typeVente: this.editedItem.typeVente, idContact: this.editedItem.idContact },
                    });
                    break;

                default:
                    break;
            }

        },
        deleteItem() {
            this.$swal({
                title: "Voulez-vous supprimer ce modèle ?",
                icon: "danger",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "OUI ! Allons-y",
                cancelButtonText: "NON !",
            }).then((result) => {
                if (result.isConfirmed) {
                    this.items.splice(this.editedItem, 1);
                    this.$axios
                        .delete("model/delete/" + this.editedItem.id)
                        .then((response) => {
                            this.$swal("Bravo !", response.data.message, "success");
                            this.editedItem = {}
                            this.dialog = false
                        });
                }
            })

        },
    },
};
</script>
  
<style>

</style>