/* eslint-disable */
import Vue from 'vue'
import axios from 'axios'
import router from "/src/router";

export default {
    namespaced: true,
    state: {
        agences: [],
        totalPaginate: 0,
    },
    mutations: {
        setAgences(state, datas) {
            state.agences = datas
        },
        storeAgence(state, data) {
            state.agences.push(data)
        },
        updateAgence(state, data) {
            var index = state.agences.findIndex(f => f.id === data.id);
            Vue.set(state.agences, index, data);
        },
        deleteAgence(state, id) {
            var index = state.agences.findIndex(agence => agence.id == id)
            state.agences.splice(index, 1)
        },
        setTotalPaginate(data) {
            state.totalPaginate = data
        }
    },
    getters: {
        getAgences(state) {
            return state.agences
        },
        getTotalPaginate(state) {
            return state.totalPaginate
        }
    },
    actions: {
        setAgences({ commit }, page) {
            axios
                .get("agences/index?page=" + page)
                .then((response) => {
                    commit('setAgences', response.data.data)
                    commit('setTotalPaginate', response.data.last_page)
                }).catch(function(error) {
                    if (error.response.status === 401) {
                        setTimeout(() => {
                            router.push({
                                name: "Connexion"
                            });
                        }, 900);
                    }
                });
        },
        updateAgence({ commit }, data) {
            commit('updateAgence', data)
        },
        storeAgence({ commit }, data) {
            commit('storeAgence', data)
        },
        deleteAgence({commit}, id) {
            commit('deleteAgence', id)
        }
    }
}