<template>
    <div>
        <form-devis :mode="'create'" :noContact="true"></form-devis>
    </div>
</template>

<script>
import FormDevis from '@/components/ventes/devis/FormDevis.vue'
export default {
    components: { FormDevis },
    data() {
        return {

        }
    },
    mounted() {
        
    },
}
</script>

<style>

</style>