<template>
    <div></div>
  </template>
  
<script>
/* eslint-disable */
export default {
   data() {
    return {

      }
    },
    mounted() {
      this.showAlert()
    },
    methods: {
      showAlert() {
          this.$swal('Paiement non effectué', 'Votre compte n\'a pas été créditer !', 'error').then(() => {
              this.$router.push({ name: "Je veux des coins" })
          });
      }
    }
}
</script>
  
<style>
  </style>
  