<template>
  <div class="container flex-center position-ref full-height">
    <v-form v-model="validPassword">
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="password" filled rounded :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.minRules]" :type="show1 ? 'text' : 'password'" name="input-10-1"
              label="Tapez un nouveau mot de passe" hint="Le mot de passe doit faire au minimum 6 caractères" counter
              dark @click:append="show1 = !show1"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field block filled rounded v-model="verify" dark :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[passwordMatch]" :type="show1 ? 'text' : 'password'" name="input-10-1"
              label="Confirmer le nouveau mot de passe" counter @click:append="show1 = !show1"></v-text-field>
          </v-col>
        </v-row>
      </v-container>
      <div class="d-flex justify-content-end">
        <v-spacer></v-spacer>
        <v-btn class="bg-primary text-white m-1" :disabled="!validPassword" @click="resetPassword()">
          Sauvegarder
        </v-btn>
      </div>
    </v-form>

  </div>
</template>
<script>
/* eslint-disable */
export default {
  data() {
    return {
      token: null,
      email: null,
      password: null,
      has_error: false,
      validPassword: true,
      verify: "",
      typeTuto: null,
      show1: false,
      requiredRules: [(v) => !!v || "Le champ est obligatoire"],
      numberRules: [
        (v) => !!v || "Le champ est obligatoire",
        (v) => !isNaN(v) || "Ce n'est pas un nombre",
      ],
      emailRules: [
        (v) => !!v || "Le champ doit être remplis",
        (v) => /.+@.+\..+/.test(v) || "L'adresse email est invalide",
      ],
      passwordConfirm: [
        (v) =>
          this.validatePassword(v) || "Les mots de passes ne concorde pas !",
      ],
      rules: {
        requiredRules: (v) => !!v || "Le champ est obligatoire",
        minRules: (v) =>
          (v && v.length >= 6) ||
          "Le mot de passe doit faire au minimum 6 caractères",
      },
      numberRulesNoObligatory: [(v) => !isNaN(v) || "Ce n'est pas un nombre"],
    };
  },
  computed: {
    passwordMatch() {
      return () =>
        this.password === this.verify ||
        "Les mot de passes ne sont pas les mêmes";
    },
  },
  methods: {
    resetPassword() {
      this.$axios
        .post("password/form/reset/password", {
          token: this.$route.query.token,
          email: this.$route.query.email,
          password: this.password,
          password_confirmation: this.password_confirmation,
        })
        .then(
          (response) => {
            this.$swal("BRAVO !", response.data, "success").then(() => {
              this.$router.push({ name: "Authentification" });
            });
          },
          (error) => {
            console.error(error);
          }
        );
    },
  },
};
</script>
<style>
.flex-center {
  align-items: center;
  display: flex;
  justify-content: center;
}

.full-height {
  height: 80vh;
}
</style>
