<template>
  <div>
    <v-form v-model="valid" class="container mb-5">
      <v-card-title> Devis </v-card-title>
      <v-container v-if="loader">
        <h1 class="text-center" style="margin-top: 150px">
          Chargement du formulaire . . .
        </h1>
        <v-progress-linear :indeterminate="true"></v-progress-linear>
      </v-container>
      <div v-else>
        <div class="d-flex justify-content-end">
          
          <v-dialog v-model="modelDialog">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-if="!devis.isDone && devis.contact_id" v-bind="attrs" v-on="on"
                class="bg-primary rounded-xl text-white" @click="dialogModel()"><v-icon>mdi-file-replace</v-icon>
                Appliquer un modèle</v-btn>
            </template>
            <v-card>
              <v-card-title>Selectionner un modèle</v-card-title>
              <v-divider></v-divider>
              <v-card-text style="height: 300px">
                <v-radio-group v-model="modelSelected" v-for="item in models" v-bind:key="item.id" column>
                  <v-radio :label="item.titre" :value="item.id"></v-radio>
                </v-radio-group>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-btn class="m-1 bg-white text-dark rounded-xl" @click="modelDialog = false">
                  <v-icon>mdi-close</v-icon>Fermer
                </v-btn>
                <v-btn class="bg-color-main rounded-xl text-white"  @click="
                  (modelDialog = false),
                  applyModel(modelSelected),
                  (showRestDocument = true),
                  (showButtonModel = false)
                ">
                  <v-icon>mdi-plus</v-icon> Choisir
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <btn-aide-contextuel class="ml-3" description="Vous pouvez appliquer un modèle de devis afin de pré-remplir avec les informations de votre modèle le devis en cours. Les produits aussi seront appliqués."></btn-aide-contextuel>
        </div>
        <v-row>
          <div v-if="noContact && !devisForm.contact_id" class="d-flex justify-content-center mt-3">
            <v-btn rounded class="bg-color-main text-white" @click="redirectTo('Je crée un contact')"><v-icon>mdi-plus</v-icon>Créer un contact</v-btn>
          </div>

          <VOneSelect icon="mdi-account" v-if="noContact" libelle="Contact" :datas="optionsSelectContacts"
            clef="contact_id" itemText="libelle" class="mt-5" itemValue="id"
            :rules="[(v) => !!v || 'Le champ est obligatoire']" rulesInfo="required" type="devis"
            :value="devis.contact_id">
          </VOneSelect>
        
          <div v-if="devisForm.contact_id">
            <vTextInput libelle="Nom du projet" clef="libelle" class="mt-6" id="devis-libelle"
              :disabled="devis.isDone ? true : false" rulesInfo="required" :value="devis.libelle"
              placeholder="Projet véranda..." :rules="[(v) => !!v || 'Le champ est obligatoire']" type="devis"
              icon="mdi-file-document"></vTextInput>

            <VDateSimple icon="mdi-calendar-week" libelle="Date du document" :disabled="devis.isDone ? true : false"
              placeholder="Saississez une date" clef="date_devis" rulesInfo="required" reference="dateDevis"
              type="devis" :value="devis.date_devis" :rules="[(v) => !!v || 'Le champ est obligatoire']">
            </VDateSimple>

            <VOneSelect icon="mdi-file-question" libelle="État du devis" :disabled="devis.isDone ? true : false"
              :datas="optionsSelectEtatDocuments" clef="etat_id" itemText="libelle" v-if="!devis.isSigned"
              itemValue="id" rulesInfo="required" type="devis" :value="devis.etat_id"
              :rules="[(v) => !!v || 'Le champ est obligatoire']"></VOneSelect>

            <VOneSelect icon="mdi-file-question" libelle="Source" :disabled="devis.isDone ? true : false"
              :datas="optionsSelectSources" clef="source_id" itemText="libelle" class="mt-5" itemValue="id"
              :rules="[(v) => !!v || 'Le champ est obligatoire']" rulesInfo="required" type="devis"
              :value="devis.source_id">
            </VOneSelect>

            <VOneSelect icon="mdi-account" libelle="Deuxième commercial" :disabled="devis.isDone ? true : false"
              :datas="optionsSelectUsers" clef="second_commercial" :clear="true" itemText="libelle" itemValue="id"
              class="mt-5" rulesInfo="facultatif" type="devis" :value="devis.second_commercial"></VOneSelect>

            <VDateSimple icon="mdi-calendar-week" :disabled="devis.isDone ? true : false" libelle="Date de signature"
              v-if="devis.isValidated" placeholder="Saississez une date de signature" clef="date_signature"
              rulesInfo="facultatif" reference="dateDevis" type="devis" class="mt-5" :value="devis.date_signature"
              :rules="[(v) => !!v || 'Le champ est obligatoire']">
            </VDateSimple>

            <VOneSelect icon="mdi-file-question" :disabled="devis.isDone ? true : false" libelle="Type de commission"
              :datas="optionSelectTypeCommission" clef="type_commission_id" v-if="devis.isValidated" itemText="libelle"
              itemValue="id" rulesInfo="facultatif" type="devis" :value="devis.type_commission_id"></VOneSelect>

            <VOneSelect icon="mdi-file-question" :disabled="devis.isDone ? true : false" libelle="Type de règlement"
              :datas="optionsSelectReglements" v-if="devis.isValidated" clef="reglement_id" itemText="libelle"
              class="mt-5" itemValue="id" rulesInfo="facultatif" type="devis" :value="devis.reglement_id"></VOneSelect>
          </div>
        </v-row>

        <div class="d-flex justify-content-end mt-5">
          <v-btn :disabled="validateForm()" rounded :valid="valid" v-if="!devis.id"
            :class="isDarkMode ? 'bg-color-main' : 'bg-color-secondaire'" class="mt-3" @click="storeDevis()">
            Suivant <v-icon>mdi-arrow-right</v-icon>
          </v-btn>
          <v-btn :disabled="validateForm()" rounded :valid="valid" v-else
            :class="isDarkMode ? 'bg-color-main' : 'bg-color-secondaire'" class="mt-3" @click="updateDevis()">
            Suivant <v-icon>mdi-arrow-right</v-icon>
          </v-btn>
        </div>
      </div>
    </v-form>
  </div>
</template>

<script>
/* eslint-disabled */
import vTextInput from "@/components/inputs/saisies/vTextInput.vue";
import VOneSelect from "@/components/inputs/saisies/vOneSelect.vue";
import VDateSimple from "@/components/inputs/saisies/VDateSimple.vue";
import variableGlobalMixin from "@/mixins/variableGlobalMixin";

export default {
  components: {
    vTextInput,
    VOneSelect,
    VDateSimple,
  },
  mixins: [variableGlobalMixin],
  props: ["mode", "idContact", "contact", "devisReceived", "noContact"],
  data() {
    return {
      optionSelectTypeCommission: [],
      optionsSelectUsers: [],
      optionSelectCategories: [],
      optionsSelectSources: [],
      optionsSelectContacts: [],
      optionsSelectEtatDocuments: [],
      optionSelectFournisseur: [],
      optionsSelectReglements: [],
      valid: true,
      requiredRules: [(v) => !!v || "Le champ est obligatoire"],
      numberRules: [
        (v) => !!v || "Le champ est obligatoire",
        (v) => !isNaN(v) || "Ce n'est pas un nombre",
      ],
      emailRules: [
        (v) => !!v || "Le champ doit être rempli",
        (v) => /.+@.+\..+/.test(v) || "L'adresse email est invalide",
      ],
      passwordConfirm: [
        (v) =>
          this.validatePassword(v) || "Les mots de passes ne concorde pas !",
      ],
      rules: {
        requiredRules: (v) => !!v || "Le champ est obligatoire",
        minRules: (v) =>
          (v && v.length >= 6) ||
          "Le mot de passe doit faire au minimum 6 caractères",
      },
      selectedItem: 1,
      items: [
        { text: "Real-Time", icon: "mdi-clock" },
        { text: "Audience", icon: "mdi-account" },
        { text: "Conversions", icon: "mdi-flag" },
      ],
      numberRulesNoObligatory: [(v) => !isNaN(v) || "Ce n'est pas un nombre"],
      modelDialog: false,
      models: [],
      modelSelected: [],
      devis: {
        id: "",
        libelle: this.contact
          ? this.contact.nom + " " + this.contact.prenom
          : "",
        montant_ht: "",
        montant_ttc: "",
        montant_total_tva_cinq: "",
        montant_total_tva_dix: "",
        montant_total_tva_vingt: "",
        date_devis: new Date(),
        annuler: 0,
        montant_remise: "",
        date_signature: null,
        signature_image: "",
        type_commission_id: "",
        isSigned: "",
        source_id: "",
        user_id: "",
        isDone: 0,
        contact_id: this.idContact
          ? this.idContact
          : this.$route.params.idContact,
        etat_id: 1,
        reglement_id: "",
        second_commercial: "",
      },
      loader: true,
    };
  },
  mounted() {
    this.loader = true;
    this.optionsSelect();
    var count = 0;
    var refreshIntervalId3 = setInterval(() => {
      if (this.devisReceived) {
        this.devis = this.devisReceived;
        this.setFormDevis();
        clearInterval(refreshIntervalId3);
      }
      if (count > 3) {
        this.setFormDevis();
        clearInterval(refreshIntervalId3);
      }

      count++;
    }, 1000);
  },
  computed: {
    devisForm() {
      if (!this.loader) {
        return this.$store.getters["formulaire/getFormType"]("devis");
      }
      return [];
    },
  },
  methods: {
    setFormDevis() {
      this.$store.dispatch("formulaire/initForm", {
        form: "devis",
        listFields: {
          id: this.devis.id,
          libelle: this.devis.libelle,
          montant_ht: this.devis.montant_ht,
          montant_ttc: this.devis.montant_ttc,
          montant_total_tva_cinq: this.devis.montant_total_tva_cinq,
          montant_total_tva_dix: this.devis.montant_total_tva_dix,
          montant_total_tva_vingt: this.devis.montant_total_tva_vingt,
          date_devis: this.devis.date_devis,
          annuler: this.devis.annuler,
          montant_remise: this.devis.montant_remise,
          date_signature: this.devis.date_signature,
          signature_image: this.devis.signature_image,
          isSigned: this.devis.isSigned,
          source_id: this.devis.source_id,
          user_id: this.devis.user_id,
          type_commission_id: this.devis.type_commission_id,
          contact_id: this.devis.contact_id,
          etat_id: this.devis.etat_id,
          reglement_id: this.devis.reglement_id,
          second_commercial: this.devis.second_commercial,
        },
      });
      this.loader = false;
    },
    validateForm() {
      if (
        this.devisForm.libelle != "" &&
        this.devisForm.date_devis != null &&
        this.devisForm.etat_id != "" &&
        this.devisForm.source_id != ""
      ) {
        return false;
      } else {
        return true;
      }
    },
    optionsSelect() {
      this.$axios.get("param/getSelectVentes").then((response) => {
        this.optionSelectTypeCommission = response.data.type_commission;
        this.optionsSelectUsers = response.data.users;
        this.optionsSelectContacts = response.data.contacts;
        this.optionSelectCategories = response.data.categories;
        this.optionsSelectSources = response.data.sources;
        this.optionsSelectEtatDocuments = response.data.etat_documents;
        this.optionSelectFournisseur = response.data.fournisseurs;
        this.optionsSelectReglements = response.data.reglements;
      });
    },
    returnTo() {
      this.$router.go(-1);
    },
    dialogModel() {
      this.modelDialog = true;
      this.getModels();
    },
    applyModel(idModel) {
      var payload = {
        typeVente: "devis",
        idModel: idModel,
        contact_id: this.contact.id
      }
      this.$axios
        .post("/ventesDocument/applyModel", payload)
        .then((response) => {
          this.devis = response.data;
          console.log(response.data)
          this.setFormDevis()
        });
    },
    getModels() {
      this.$axios.get("/model/list/devis").then((response) => {
        this.models = response.data;
      });
    },
    redirectTo(path) {
      this.$router.push({name: path})
    },
    storeDevis() {
      var contact_id = this.idContact
        ? this.idContact
        : this.$route.params.idContact;
      if (!contact_id) {
        contact_id = this.devisForm.contact_id;
      }
      var payload = {
        annuler: 0,
        date_signature: this.devisForm.date_signature,
        date_devis: this.devisForm.date_devis,
        etat_id: this.devisForm.etat_id,
        libelle: this.devisForm.libelle,
        reglement_id: this.devisForm.reglement_id,
        second_commercial: this.devisForm.second_commercial,
        source_id: this.devisForm.source_id,
        contact_id: contact_id,
        type_commission_id: this.devisForm.type_commission_id
      };
      this.$axios.post("devis/store", payload).then((response) => {
        if (response.data.messageError) {
          this.$swal(
            "Une erreur est survenue",
            response.data.messageError,
            "error"
          );
        }

        this.$router.push({
          name: "J'ajoute mes produits",
          params: {
            idContact: contact_id,
            typeVente: "devis",
            idVente: response.data.devis.id,
          },
        });
        this.$swal({
          toast: true,
          title: response.data.message,
          icon: "success",
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timerProgressBar: true,
          timer: 2000,
        });
      });
    },
    updateDevis() {
      var contact_id = this.idContact
        ? this.idContact
        : this.$route.params.idContact;
      if (!contact_id) {
        contact_id = this.devisForm.contact_id;
      }
      var payload = {
        annuler: this.devisForm.annuler,
        date_signature: this.devisForm.date_signature,
        date_devis: this.devisForm.date_devis,
        etat_id: this.devisForm.etat_id,
        libelle: this.devisForm.libelle,
        reglement_id:
          this.devisForm.reglement_id === undefined
            ? null
            : this.devisForm.reglement_id,
        second_commercial: this.devisForm.second_commercial,
        source_id: this.devisForm.source_id,
        contact_id: contact_id,
        type_commission_id: this.devisForm.type_commission_id
      };
      if (this.devis.isDone) {
        this.$router.push({
          name: "J'ajoute mes produits",
          params: {
            idContact: contact_id,
            typeVente: "devis",
            idVente: this.devis.id,
          },
        });
      } else {
        this.$axios
          .put("devis/update/" + this.devis.id, payload)
          .then((response) => {
            if (response.data.messageError) {
              this.$swal(
                "Une erreur est survenue",
                response.data.messageError,
                "error"
              );
            }
            this.$router.push({
              name: "J'ajoute mes produits",
              params: {
                idContact: contact_id,
                typeVente: "devis",
                idVente: response.data.devis.id,
              },
            });
            this.$swal({
              toast: true,
              title: response.data.message,
              icon: "success",
              animation: true,
              position: "top-right",
              showConfirmButton: false,
              timerProgressBar: true,
              timer: 2000,
            });
          });
      }
    },
  },
};
</script>

<style>

</style>
