/* eslint-disable */
import Vue from "vue";
import axios from "axios";
import router from "/src/router";

export default {
    namespaced: true,
    state: {
        commandes: [],
    },
    mutations: {
        setCommande(state, datas) {
            state.commandes = datas;
        },
        storeCommande(state, data) {
            state.commandes.push(data);
        },
        updateCommande(state, data) {
            var index = state.commandes.findIndex((c) => c.id === data.id);
            Vue.set(state.commandes, index, data);
        },
        deleteCommande(state, id) {
            var index = state.commandes.findIndex((commande) => commande.id == id);
            state.commandes.splice(index, 1);
        },
    },
    getters: {
        getCommande(state) {
            return state.commandes;
        },
    },
    actions: {
        setCommande({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios.get('/commande/index/' + payload.idContact + '?page=' + payload.page).then((response) => {
                    commit("setCommande", response.data);
                    resolve(response);
                })
            });
        },
        updateCommande({ commit }, data) {
            commit("updateCommande", data);
        },
        storeCommande({ commit }, data) {
            commit("storeCommande", data);
        },
        deleteCommande({ commit }, id) {
            commit("deleteCommande", id);
        },
    },
};