/* eslint-disable */
import Vue from "vue";
import axios from "axios";
import router from "/src/router";

export default {
    namespaced: true,
    state: {
        devis: [],
    },
    mutations: {
        setDevis(state, datas) {
            state.devis = datas;
        },
        storeDevis(state, data) {
            state.devis.push(data);
        },
        updateDevis(state, data) {
            var index = state.devis.findIndex((d) => d.id === data.id);
            Vue.set(state.devis, index, data);
        },
        deleteDevis(state, id) {
            var index = state.devis.findIndex((devis) => devis.id == id);
            state.devis.splice(index, 1);
        },
    },
    getters: {
        getDevis(state) {
            return state.devis;
        },
    },
    actions: {
        setDevis({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios.get('/devis/index/' + payload.idContact  + '?page='+ payload.page).then((response) => {
                    commit("setDevis", response.data);
                    resolve(response);
                })
            });
        },
        updateDevis({ commit }, data) {
            commit("updateDevis", data);
        },
        storeDevis({ commit }, data) {
            commit("storeDevis", data);
        },
        deleteDevis({ commit }, id) {
            commit("deleteDevis", id);
        },
    },
};