<template>
    <div>
        <v-container class="mt-5 container-xxl">
            <h1 class="text-center lead fs-1 mt-5">Votre compte est en attente de validation</h1>
            <p class="text-center mt-5 mb-5 fw-bold fs-5">Un email vous a été envoyé afin de valider votre
                compte.</p>
            <div class="d-flex justify-content-center">
                <v-btn class="bg-color-main text-white" rounded :disabled="disabledBTN" :loading="loadingBTN" large
                    @click="sendEmailConfirmation()">
                    <v-icon>mdi-send</v-icon>Cliquer ici pour renvoyer <br> un email de confirmation
                </v-btn>
            </div>
        </v-container>
    </div>
</template>

<script>
export default {
    data() {
        return {
            disabledBTN: false,
            loadingBTN: false,
        }
    },
    methods: {
        sendEmailConfirmation() {
            this.loadingBTN = true
            this.$axios.post('/email/verify/resend').then((response) => {
                this.$swal('L\'email a été envoyé !', response.data.message, 'success')
                this.disabledBTN = true
                this.loadingBTN = false
                setTimeout(() => {
                    this.disabledBTN = false
                }, 10000)
            })
        }
    }
}
</script>

<style>

</style>