var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticStyle:{"right":"-10px"}},[_c('vBtnAdd',{staticClass:"mb-2 mt-3 mr-5 ml-2",attrs:{"classItems":'bg-color-main text-white',"nom":"Ajouter une origine de contact","table":"crud","icon":"mdi-plus"},on:{"openModal":_vm.openModal}})],1),_c('div',{staticClass:"float-bottom"},[_c('VTableData',{attrs:{"items":_vm.items,"loader":_vm.loader,"headers":[
      {
        text: 'Libelle',
        align: 'left',
        sortable: true,
        value: 'libelle',
      },
    ],"hiddenFooter":true,"hiddenHeader":false,"total":_vm.total},on:{"triggerRowTable":_vm.editItem,"pageChange":_vm.pageChange}}),(_vm.dialog)?_c('vModalCrud',{attrs:{"deleteStoreFunction":"sources/deleteSource","items":_vm.items,"itemEdited":_vm.editedItem,"formTitle":_vm.formTitle,"typeCRUD":_vm.typeCRUD,"typeForm":'FormSources',"type":"source"},on:{"closeDialog":_vm.close}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }