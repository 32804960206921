<template>
    <div>
        <form-devis :idContact="(this.idContact) ? this.idContact : this.$route.params.idContact" :mode="mode" :contact="contact"></form-devis>
    </div>
</template>

<script>
import FormDevis from '@/components/ventes/devis/FormDevis.vue'
export default {
    components: { FormDevis },
    props: ['mode', "idContact", "contact"],
    mounted() {
        if(!this.contact) {
            this.$router.go(-1)
        }
        window.onbeforeunload = function () {
            return "Vous allez perdre les information du devis si vous recharger la page.";
        }
    },
}
</script>

<style>

</style>