<template>
  <div>
    <v-app-bar fixed app elevation="11" color="#5A189A">
      <v-app-bar-nav-icon @click="drawer = true" v-if="showAuthenticatedThing" class="text-white">
      </v-app-bar-nav-icon>
      <v-app-bar-nav-icon v-if="currentRouteName == 'J\'ajoute mes produits'" @click="redirectTo('J\'edite un devis')"
        class="text-white">
        <v-icon>mdi-arrow-left</v-icon>
      </v-app-bar-nav-icon>
      <v-app-bar-nav-icon v-else-if="currentRouteName == 'J\'edite un devis'" @click="redirectTo('Dossier du contact')"
        class="text-white">
        <v-icon>mdi-arrow-left</v-icon>
      </v-app-bar-nav-icon>
      <v-app-bar-nav-icon v-else @click="$router.go(-1)" class="text-white">
        <v-icon>mdi-arrow-left</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title class="text-white">{{
        currentRouteName
      }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <component v-bind:is="component.typeComponent" :nom="component.nom"
        v-if="currentRouteName === component.routeDisplay" :icon="component.icon" :onlyIcon="component.onlyIcon"
        :classItems="component.classItems" :style="component.style" :table="component.table"></component>
      <v-menu offset-y rounded :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <v-btn large v-bind="attrs" v-on="on" icon class="text-white">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded>
          <div class="text-center" v-if="showIsSubscriber() && showAuthenticatedThing">
            <h1 class="text-center">Vous avez <br> <strong>{{ this.$store.getters['coin/myCoins'] }}</strong> Coins</h1>
          </div>
          <v-list-item>
            Dark Mode :
            <v-switch class="mt-4 ml-2" v-model="darkMode" :color="darkMode ? '#3333333' : ''" inset></v-switch>
            <v-icon>mdi-theme-light-dark</v-icon>
          </v-list-item>
          <v-list-item v-if="showAuthenticatedThing">
            <v-btn class="bg-color-main text-white" rounded small @click="signout()">
              <v-icon>mdi-logout</v-icon>Déconnexion
            </v-btn>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" absolute temporary>
      <v-list rounded nav>
        <v-list-item-group v-model="listItemSelected" :color="isDarkMode ? '#FF7900' : '#5A189A'">
          <v-spacer></v-spacer>

          <v-list-item v-if="showManagerThing()">
            <v-list-item-icon>
              <v-icon>mdi-home-city</v-icon>
            </v-list-item-icon>
            <v-list-item-title>

              <router-link exact tag="div" class="text-decoration-none" :to="{
                name: 'Mon entreprise',
                params: { notDisplayParamBar: true },
              }">
                Mon entreprise</router-link>
            </v-list-item-title>
          </v-list-item>

          <v-list-item v-if="showManagerThing()">
            <v-list-item-icon>
              <v-icon>mdi-home-group</v-icon>
            </v-list-item-icon>
            <v-list-item-title>

              <router-link exact tag="div" class="text-decoration-none" :to="{
                name: 'Mes points de vente',
                params: { notDisplayParamBar: true },
              }">
                Point de vente</router-link>
            </v-list-item-title>
          </v-list-item>

          <v-list-item v-if="showManagerThing()">
            <v-list-item-icon>
              <v-icon>mdi-account-group</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              <router-link exact tag="div" class="text-decoration-none" :to="{
                name: 'Mes utilisateurs',
                params: { notDisplayParamBar: true },
              }">
                Utilisateurs </router-link>
            </v-list-item-title>
          </v-list-item>

          <v-list-item v-if="showManagerThing()">
            <v-list-item-icon>
              <v-icon>mdi-label-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              <router-link exact tag="div" class="text-decoration-none" :to="{
                name: 'Mes familles de produits',
                params: { notDisplayParamBar: true },
              }">
                Familles de produits</router-link>
            </v-list-item-title>
          </v-list-item>
          <v-list-item v-if="showManagerThing()">
            <v-list-item-icon>
              <v-icon>mdi-truck</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              <router-link exact tag="div" class="text-decoration-none" :to="{
                name: 'Mes fournisseurs',
                params: { notDisplayParamBar: true },
              }">Mes fournisseurs</router-link>
            </v-list-item-title>
          </v-list-item>

          <v-list-item v-if="showManagerThing()">
            <v-list-item-icon>
              <v-icon>mdi-basket</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              <router-link exact tag="div" class="text-decoration-none" :to="{
                name: 'Mes produits',
                params: { notDisplayParamBar: true },
              }">
                Mes produits</router-link>
            </v-list-item-title>
          </v-list-item>

          <v-list-item v-if="showManagerThing()">
            <v-list-item-icon>
              <v-icon>mdi-account-question</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              <router-link exact tag="div" class="text-decoration-none"
                :to="{ name: 'Mes origines de contact', params: { notDisplayParamBar: true } }">
                Origine de contact</router-link>
            </v-list-item-title>
          </v-list-item>

          <v-list-item v-if="showManagerThing()">
            <v-list-item-icon>
              <v-icon>mdi-calendar-question</v-icon>
            </v-list-item-icon>
            <v-list-item-title :to="{ name: 'TypeRdv' }">
              <router-link exact tag="div" class="text-decoration-none"
                :to="{ name: 'Mes types de rendez-vous', params: { notDisplayParamBar: true } }">
                Types de RDV</router-link>

            </v-list-item-title>
          </v-list-item>
          <v-list-item v-if="showManagerThing()">
            <v-list-item-icon>
              <v-icon>mdi-credit-card</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              <router-link exact tag="div" class="text-decoration-none"
                :to="{ name: 'Mes type de règlements', params: { notDisplayParamBar: true } }">
                Règlements</router-link>

            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              <router-link exact tag="div" class="text-decoration-none"
                :to="{ name: 'Mes type de commission', params: { notDisplayParamBar: true } }">
                Type de commission</router-link>
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-file-document-multiple-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              <router-link exact tag="div" class="text-decoration-none"
                :to="{ name: 'Mes modèles', params: { notDisplayParamBar: true } }">
                Modèles</router-link>
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { bus } from "@/main";
export default {
  data() {
    return {
      darkMode: false,
      drawer: false,
      listItemSelected: "",
      component: [],
      entreprise: [],
      role: "",
    };
  },
  created() {
    bus.$on("changeButtonAction", (payload) => {
      this.component = payload;
    });
  },
  watch: {
    darkMode(val) {
      if (val === true) {
        this.$vuetify.theme.dark = true;
        localStorage.setItem("darkModeMy", true);
      } else {
        this.$vuetify.theme.dark = false;
        localStorage.setItem("darkModeMy", false);
      }
    },
  },
  computed: {
    showAuthenticatedThing() {
      return !!this.$store.getters["auth/authenticated"];
    },
  },
  mounted() {
    if (this.showIsSubscriber() == true && this.showAuthenticatedThing) {
      this.getMySoldeCoins()
    }
    this.darkMode =
      localStorage.getItem("darkModeMy") === "true" ? true : false;
    var refreshIntervalId = setInterval(() => {
      if (this.$store.getters["auth/dataLoad"]) {
        this.role = this.$store.getters["auth/role"];
        this.entreprise = this.$store.getters["auth/user"]['entreprise']
        clearInterval(refreshIntervalId);
      }
    }, 1000);
  },
  methods: {
    showManagerThing() {
      if (this.role == "Manager") {
        return true;
      } else {
        return false;
      }
    },
    showIsSubscriber() {
      if (this.entreprise.isSubscriber) {
        return false;
      } else {
        return true;
      }
    },
    getMySoldeCoins() {
      this.$store.dispatch('coin/getMyCoins')
    },
    redirectTo(path) {
      switch (this.$route.params.typeVente) {
        case 'devis':
          if (path == "J'édite un devis") {
            this.$router.push({
              name: path, params: {
                idVente: this.$route.params.idVente,
                idContact: this.$route.params.idContact,
                typeVente: this.$route.params.typeVente
              }
            })
          } else {
            this.$router.push({
              name: path, params: {
                id: this.$route.params.idContact,
              }
            })
          }
          break;
        case 'commande':
          this.$router.push({
            name: "J'edite une commande", params: {
              idVente: this.$route.params.idVente,
              idContact: this.$route.params.idContact,
              typeVente: this.$route.params.typeVente
            }
          })
          break;
        default:
          break;
      }

    },
    signout() {
      this.$swal({
        title: "Êtes-vous sur de vouloir vous déconnecter ?",
        showDenyButton: true,
        type: "question",
        showCancelButton: false,
        confirmButtonText: "Oui",
        denyButtonText: `Non`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("auth/logout");
          this.$router.push({ name: "Connexion" });
        }
      })
    },
  },
};
</script>

<style>

</style>