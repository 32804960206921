/* eslint-disable */
import Vue from 'vue'
import axios from 'axios'
import router from "/src/router";

export default {
    namespaced: true,
    state: {
        users: [],
        totalPaginate: 1
    },
    mutations: {
        setUsers(state, datas) {
            state.users = datas
        },
        storeUser(state, data) {
            state.users.push(data)
        },
        updateUser(state, data) {
            var index = state.users.findIndex(f => f.id === data.id);
            Vue.set(state.users, index, data);
        },
        deleteUser(state, id) {
            var index = state.users.findIndex(user => user.id == id)
            state.users.splice(index, 1)
        },
        setTotalPaginate(state, data) {
            state.totalPaginate = data
        }
    },
    getters: {
        getUsers(state) {
            return state.users
        },
        getTotalPaginate(state) {
            return state.totalPaginate
        }
    },
    actions: {
        setUsers({ commit }, page) {
            axios
                .get("users/index?page=" + page)
                .then((response) => {
                    commit('setUsers', response.data.users) 
                    commit('setTotalPaginate', response.data.total)
                }).catch(function(error) {
                    if (error.response.status === 401) {
                        setTimeout(() => {
                            router.push({
                                name: "Connexion"
                            });
                        }, 900);
                    }
                });
        },
        updateUser({ commit }, data) {
            commit('updateUser', data)
        },
        storeUser({ commit }, data) {
            commit('storeUser', data)
        },
        deleteUser({commit}, id) {
            commit('deleteUser', id)
        }
    }
}