<template>
  <v-container>
    <div v-if="loaderMixin">
      <h1 class="text-center mt-5">Chargement de l'accueil . . .</h1>
      <v-progress-linear color="deep-purple accent-4" indeterminate rounded height="6">
      </v-progress-linear>
    </div>
    <div v-else>
      <h1 class="text-center p-2">Bonjour {{ authUser('prenom') }} !</h1>
      <v-container>
        <v-row>
          <v-col cols="6" v-for="element in elements" :key="element.id">
            <v-hover v-slot="{ hover }" v-if="(element.path == 'Boutique') ? showIsSubscriber() && showManagerThing() : true">
              <v-card max-width="400" class="rounded-xl mx-auto" @click="navigate(element.path)">
                <center>
                  <div class="p-2">
                    <v-img v-if="isDarkMode" :src="element.imgOrange" width="100">
                    </v-img>
                    <v-img v-else :src="element.imgViolet" width="100"></v-img>
                    {{ element.text }}
                  </div>
                </center>
                <v-expand-transition>
                  <v-card class="rounded-b-xl text-center p-2 mb-3" :class="
                    isDarkMode
                      ? 'bg-color-secondaire text-dark'
                      : 'bg-color-main  text-white'
                  " v-if="hover">{{ element.explication }}
                  </v-card>
                </v-expand-transition>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      elements: [
        {
          id: 1,
          text: "Devis",
          explication: "On fait un devis ?",
          path: "Je fais un nouveau devis",
          imgViolet: require("../assets/img/icon/accueil/violet/devis.png"),
          imgOrange: require("../assets/img/icon/accueil/orange/devis.png"),
        },
        {
          id: 2,
          text: "Commande",
          path: "Je fais une nouvelle commande",
          explication: "On fait plutôt un commande ?",
          imgViolet: require("../assets/img/icon/accueil/violet/commande.png"),
          imgOrange: require("../assets/img/icon/accueil/orange/commande.png"),
        },
        {
          id: 3,
          text: "RDV",
          path: "Mes rendez-vous",
          explication: "Consultons nos rendez-vous !",
          imgViolet: require("../assets/img/icon/accueil/violet/calendar.png"),
          imgOrange: require("../assets/img/icon/accueil/orange/calendar.png"),
        },
        {
          id: 4,
          text: "Contacts",
          path: "Mes contacts",
          explication: "Voir la liste de mes contacts ?",
          imgViolet: require("../assets/img/icon/accueil/violet/contacts.png"),
          imgOrange: require("../assets/img/icon/accueil/orange/contacts.png"),
        },
        {
          id: 5,
          text: "Stats",
          path: "Mes statistiques",
          explication: "Voyons voir nos dernièrs chiffre !",
          imgViolet: require("../assets/img/icon/accueil/violet/charts.png"),
          imgOrange: require("../assets/img/icon/accueil/orange/charts.png"),
        },
        {
          id: 6,
          text: "Historique",
          path: "Mon historique",
          explication: "Voyons voir mon historique ?",
          imgViolet: require("../assets/img/icon/accueil/violet/history.png"),
          imgOrange: require("../assets/img/icon/accueil/orange/history.png"),
        },
        {
          id: 7,
          text: "Coins",
          path: "Boutique",
          explication: "Je veux des coins !",
          imgViolet: require("../assets/img/icon/accueil/violet/coins.png"),
          imgOrange: require("../assets/img/icon/accueil/orange/coins.png"),
        },
      ],
      hover: false,
    };
  },
  mounted() {
    var token = localStorage.getItem('tokenMy')
    if (!token) {
      this.$router.push({ name: 'Connexion' })
    }
  },
  methods: {
    navigate(path) {
      this.$router.push({ name: path });
    },
  },
};
</script>

<style>

</style>