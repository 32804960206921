<template>
    <div class="container">
        <h1 class="lead text-center">À propos de votre entreprise</h1>
        <div v-if="!unlockFormEntreprise">
            <p class="text-center fst-italic">Vous pouvez trouver votre numéro de siret ici : <a
                    href="https://www.societe.com/" target="blank_">https://www.societe.com/</a></p>
            <p>Taper votre numéro de SIRET</p>

            <v-text-field v-model="siret" :rules="requiredRules" label="SIRET" filled rounded required>
                <template v-slot:label><span class="input__label">SIRET <span
                            style="color: red">*</span></span></template>
            </v-text-field>
            <div class="d-flex justify-content-end">
                <v-btn class="bg-color-main text-white" :disabled="(siret) ? false : true" rounded
                    :loading="btnLoadingSearch" @click="findEntreprise()">
                    <v-icon>mdi-magnify</v-icon>Chercher l'entreprise
                </v-btn>
            </div>
        </div>
        <v-form v-else ref="form" v-model="valid" lazy-validation>
            <div class="row">
                <div class="col">
                    <v-text-field v-model="form.libelle" filled rounded :rules="requiredRules"
                        label="Nom de l'entreprise" required><template v-slot:label><span class="input__label">Nom de
                                l'entreprise <span style="color: red">*</span></span></template></v-text-field>
                    <v-text-field v-model="form.rue" :rules="requiredRules" label="Rue" filled rounded required>
                        <template v-slot:label><span class="input__label">Rue <span
                                    style="color: red">*</span></span></template>
                    </v-text-field>

                    <v-text-field v-model="form.ville" filled rounded :rules="requiredRules" label="Ville" required>
                        <template v-slot:label><span class="input__label">Ville <span
                                    style="color: red">*</span></span></template>
                    </v-text-field>
                    <v-text-field v-model="form.codePostal" filled rounded :rules="numberRules" label="Code postal"
                        required><template v-slot:label><span class="input__label">Code postal <span
                                    style="color: red">*</span></span></template>
                    </v-text-field>
                    <v-text-field v-model="form.telephone" filled rounded :rules="numberRules" label="Téléphone"
                        required> <template v-slot:label><span class="input__label">Téléphone <span
                                    style="color: red">*</span></span></template></v-text-field>
                </div>
                <div class="col">
                    <v-text-field v-model="form.email" filled rounded :rules="emailRules" label="E-mail" required>
                        <template v-slot:label><span class="input__label">E-mail <span
                                    style="color: red">*</span></span></template>
                    </v-text-field>
                    <v-text-field v-model="form.siret" filled rounded :rules="requiredRules" label="Siret" required>
                        <template v-slot:label><span class="input__label">Siret <span
                                    style="color: red">*</span></span></template>
                    </v-text-field>

                    <v-text-field v-model="form.tvaIntracom" filled rounded :rules="requiredRules"
                        label="TVA Intracom"><template v-slot:label><span class="input__label">TVA Intracom <span
                                    style="color: red">*</span></span></template></v-text-field>
                    <v-text-field v-model="form.codeAPE" filled rounded label="Code APE" required><template
                            v-slot:label><span class="input__label">Code APE <span
                                    style="color: red">*</span></span></template></v-text-field>
                    <v-select rounded filled v-model="form.formeJuridique" label="Forme juridique"
                        :items="['SARL', 'UERL', 'SA', 'SAS', 'SCP', 'Auto-entrepeneur', 'Micro entreprise', 'GIE', 'SNC', 'SELARL']">
                        <template v-slot:label><span class="input__label">Fomre juridique <span
                                    style="color: red">*</span></span></template>
                    </v-select>
                </div>
            </div>
        </v-form>
        <v-card-actions class="mt-5">
            <v-btn text @click="backStep()">
                <v-icon>mdi-arrow-left</v-icon> Retour
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn :disabled="checkIfAllFill()" class="bg-color-main text-white" rounded depressed @click="nextStep()">
                Suivant <v-icon>mdi-arrow-right</v-icon>
            </v-btn>
        </v-card-actions>
    </div>
</template>

<script>
/* eslint-disable */
export default {
    props: ["user"],
    data() {
        return {
            valid: true,
            unlockFormEntreprise: false,
            form: {
                libelle: "",
                rue: "",
                ville: "",
                codePostal: "",
                telephone: "",
                email: "",
                siret: "",
                tvaIntracom: "",
                codeAPE: "",
                formeJuridique: "",
            },
            btnLoadingSearch: false,
            siret: null,
            entreprise: [],
            requiredRules: [(v) => !!v || "Le champ est obligatoire"],
            numberRulesNoObligatory: [(v) => !isNaN(v) || "Ce n'est pas un nombre"],
            numberRules: [
                (v) => !!v || "Le champ est obligatoire",
                (v) => !isNaN(v) || "Ce n'est pas un nombre",
            ],
            emailRules: [
                (v) => !!v || "Le champ doit être remplis",
                (v) => /.+@.+\..+/.test(v) || "L'adresse email est invalide",
            ],
        };
    },
    watch: {},
    mounted() { },
    methods: {
        findEntreprise() {
            this.btnLoadingSearch = true
            this.$axios.get('/register/siret/' + this.siret).then((response) => {
                this.unlockFormEntreprise = true;
                this.form.libelle = response.data.uniteLegale.denominationUniteLegale;
                this.form.siret = response.data.siret;
                this.form.rue = response.data.adresseEtablissement.typeVoieEtablissement + " " + response.data.adresseEtablissement.libelleVoieEtablissement;
                this.form.ville = response.data.adresseEtablissement.libelleCommuneEtablissement;
                this.form.codePostal = response.data.adresseEtablissement.codePostalEtablissement;
                /*                 this.form.formeJuridique =
                                    response.data.siege_social.libelle_nature_juridique_entreprise; */
                /*  this.form.codeAPE = response.data.siege_social.activite_principale_entreprise; */
                /*      this.form.tvaIntracom = response.data.numero_tva_intra */

                this.btnLoadingSearch = false;
            });
            this.btnLoadingSearch = false
        },
        backStep() {
            this.$emit('changeStep', 'back')
        },
        nextStep() {
            console.log(this.form)
            if (this.entreprise.length == 0) {
                var payload = {
                    form: this.form
                }
                this.$store.dispatch('entreprises/storeEntreprise', payload).then((response) => {
                    this.entreprise = response.data.entreprise;
                    this.$swal('Super !', "Votre entreprise a bien été créé !", 'success').then(() => {
                        this.$emit('changeStep', 'next')
                    })
                })

            } else {
                var payload = {
                    id: this.entreprise.id,
                    form: this.form
                }
                this.$store.dispatch('entreprises/updateEntreprise', payload).then((response) => {
                    this.entreprise = response.data.entreprise;
                    this.$swal('Super !', "Votre entreprise a bien été modifié !", 'success').then(() => {
                        this.$emit('changeStep', 'next')
                    })
                })
            }
        },
        checkIfAllFill() {
            if (
                this.form.libelle != "" &&
                this.form.rue != "" &&
                this.form.ville != "" &&
                this.form.codePostal != "" &&
                this.form.telephone != "" &&
                this.form.email != "" &&
                this.form.siret != "" &&
                this.form.formeJuridique != ""
            ) {
                return false;
            } else {
                return true;
            }
        },
    },
};
</script>

<style>

</style>
