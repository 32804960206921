<template>
  <div data-app class="mt-3">
    <h3 class="text-center mt-1 mb-3 p-3" style="background-color: #1a9fc7">
      Liste des RDV
    </h3>
    <VTableData :items="items" :loader="loader" @triggerRowTable="editItem" :headers="[
      {
        text: 'Début',
        align: 'left',
        sortable: true,
        value: 'start',
      },
      {
        text: 'Fin',
        align: 'left',
        sortable: true,
        value: 'end',
      },
      { text: 'Type de rendez-vous', value: 'type_rdv' },
    ]" :hiddenFooter="true" :hiddenHeader="false" :total="total" @pageChange="pageChange"></VTableData>
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
          <v-spacer></v-spacer>
          <v-btn class="text-white" style="background-color: red"
            v-if="showManagerThing && formTitle == 'Editer le rendez-vous'" @click="deleteItem(editedItem)">
            <v-icon> mdi-delete </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-form v-model="valid">
                <input type="hidden" v-model="editedItem.id" />
                <v-col cols="12">
                  <vTextInput libelle="Libelle de l'évenement" clef="name" class="mt-6" id="events-name"
                    rulesInfo="required" :value="editedItem.name" placeholder="R1 avec monsieur Dupont"
                    :rules="[(v) => !!v || 'Le champ est obligatoire']" type="events" icon="mdi-form-textbox"></vTextInput>
                </v-col>
                <v-col cols="12">
                  <label for="">Date de début</label>
                  <VDateInput id="start-event" :rules="requiredRules" clef="start" :noLabel="true" type="events"
                    format="YYYY-MM-DD HH:mm" v-model="editedItem.start"></VDateInput>
                </v-col>
                <v-col cols="12">
                  <label for="">Date de fin</label>
                  <VDateInput :rules="requiredRules" id="start-event" :noLabel="true" clef="end" type="events"
                    format="YYYY-MM-DD HH:mm" v-model="editedItem.end"></VDateInput>
                </v-col>
                <v-col cols="12">
                  <vTextInput libelle="Adresse complète" clef="adresse" class="mt-6" id="event-adresse" rulesInfo="required"
                    :value="editedItem.adresse" placeholder="Rue de la république"
                    :rules="[(v) => !!v || 'Le champ est obligatoire']" type="events" icon="mdi-map-marker"></vTextInput>
                </v-col>
                <v-col cols="12">
                  <VOneSelect icon="mdi-calendar-question" name="Type de rendez-vous" :datas="optionsSelectTypeRdv"
                    clef="typerdv_id" itemText="libelle" itemValue="id" rulesInfo="required" type="events"
                    :value="editedItem.typerdv_id" :rules="[(v) => !!v || 'Le champ est obligatoire']"></VOneSelect>
                </v-col>

              </v-form>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="m-1" text @click="close"> Annuler </v-btn>
          <vBtnEdit nom="Sauvegarder" v-if="validateForm()" :valid="valid" icon="mdi-content-save" @saveForm="returnTo"
            :onlyIcon="false" :id="editedItem.id" table="events"></vBtnEdit>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card style="background-color: #d60000">
        <v-card-title class="headline text-break text-center">Nous allons supprimer cet événement ? <br />
          Tu es sur de toi ?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="text-dark bg-white m-1" text @click="closeDelete">NON</v-btn>
          <v-btn class="text-dark bg-white m-1" text @click="deleteItemConfirm()">OUI</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  props: ["contact"],
  data() {
    return {
      valid: "",
      loader: true,
      dialog: false,
      dialogDelete: false,
      search: "",
      items: [],
      editedIndex: -1,
      editedItem: {
        id: "",
        name: "",
        start: "",
        end: "",
        adresse: "",
      },
      defaultItem: {
        name: "",
        start: "",
        end: "",
        codePostal: "",
        telephone: "",
        adresse: "",
      },
      total:1,
      page: 1,
      requiredRules: [(v) => !!v || "Le champ est obligatoire"],
      numberRules: [
        (v) => !!v || "Le champ est obligatoire",
        (v) => !isNaN(v) || "Ce n'est pas un nombre",
      ],
      emailRules: [
        (v) => !!v || "Le champ doit être remplis",
        (v) => /.+@.+\..+/.test(v) || "L'adresse email est invalide",
      ],
      numberRulesNoObligatory: [(v) => !isNaN(v) || "Ce n'est pas un nombre"],
      optionsSelectTypeRdv: [],
    };
  },
  mounted() {
    this.getEvents();
    this.getSelectOptions();
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Nouvel évenement"
        : "Editer le rendez-vous";
    },
  },
  methods: {
    getEvents() {
      this.loader = true;
      this.$axios.get("events/eventContact/" + this.contact.id + "?page=" + this.page).then((response) => {
        this.items = response.data.events;
        this.total = response.data.total
        this.loader = false;
      });
    },
    pageChange(page) {
      this.page = page
      this.getEvents()
    },
    returnTo(element) {
      this.items = this.items.map((item) => {
        if (item.id === element.id) {
          return Object.assign({}, item, element);
        }
      });
      this.dialog = false;
    },
    validateForm() {
      if (
        this.editedItem.id != "" &&
        this.editedItem.name != "" &&
        this.editedItem.start != null &&
        this.editedItem.end != null &&
        this.editedItem.adresse != ""
      ) {
        return true;
      } else {
        return false;
      }
    },
    getSelectOptions() {
      this.$store.dispatch("events/optionsEvents");
      this.selectUserLoading = true;
      var refreshIntervalId = setInterval(() => {
        if (this.$store.getters["events/getOptions"]["contacts"]) {
          this.optionsSelectContact =
            this.$store.getters["events/getOptions"]["contacts"];
          this.optionsSelectUsers =
            this.$store.getters["events/getOptions"]["users"];
          this.optionsSelectTypeRdv =
            this.$store.getters["events/getOptions"]["typeRdvs"];
          this.optionsSelectSources =
            this.$store.getters["events/getOptions"]["sources"];
          clearInterval(refreshIntervalId);
        }
      }, 1000);
    },
    close() {
      this.dialog = false;
      this.editedItem = {
        id: "",
        name: "",
        start: new Date(),
        end: new Date(),
        adresse: "",
      };
      this.editedIndex = -1;
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;

      this.$store.dispatch("formulaire/initForm", {
        form: "events",
        listFields: {
          start: this.editedItem.start,
          end: this.editedItem.end,
          name: this.editedItem.name,
          fromFolderContact: true,
          id: this.editedItem.id,
          typerdv_id: this.editedItem.typerdv_id,
          commentaires: this.editedItem.commentaires,
          adresse: this.editedItem.adresse,
          contact_id: this.editedItem.contact_id,
          user: [this.editedItem.user],
          annuler: this.editedItem.annuler,
          raisonAnnulation: this.editedItem.raisonAnnulation,
        },
      });
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    deleteItem() {
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.items.splice(this.editedItem, 1);
      this.$axios
        .delete("events/delete/" + this.editedItem.id)
        .then((response) => {
          this.$swal("Bravo !", response.data.message, "success");
          this.closeDelete();
          this.close();
        });
    },
  },
};
</script>
<style lang="css" scoped>
tr :hover {
  cursor: pointer;
}

.row-pointer {
  font-weight: 900 !important;
}
</style>
