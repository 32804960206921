<template>
  <div>
    <h3 class="text-center text-white mt-1 mb-3 p-3" style="background-color: #880484">
      Liste des devis
    </h3>

    <VTableData :items="items" :loader="loader" @triggerRowTable="editItem" :headers="[
      {
        text: 'Titre',
        align: 'left',
        sortable: true,
        value: 'libelle',
      },
      {
        text: 'Montant TTC',
        align: 'left',
        sortable: true,
        value: 'montant_ttc',
      },
      { text: 'Date', value: 'date_devis' },
      { text: 'Etat du devis', value: 'etat_document_libelle' },
    ]" :hiddenFooter="true" :hiddenHeader="false" :total="total" @pageChange="pageChange" type="devis"></VTableData>
  </div>
</template>

<script>
import VTableData from "../../modules/vTableData.vue";
export default {
  props: ['idContact'],
  components: {
    VTableData,
  },
  data() {
    return {
      items: [],
      total: 1,
      current_page: 1,
      loader: true,
    }
  },
  computed: {
    devis() {
      return this.$store.getters['devis/getDevis']
    }
  },
  mounted() {
    this.setDevis()
  },
  methods: {
    setDevis(page = 1) {
      var payload = {
        idContact: (this.idContact) ? this.idContact : this.$route.params.idContact,
        page: page
      }
      this.$store.dispatch('devis/setDevis', payload).then((response) => {
        this.items = response.data.devis
        this.current_page = response.data.current_page
        this.total = response.data.total
        this.loader = false
      })
    },
    editItem(item) {
      if (item.isDone) {
        this.$router.push({
          name: "Je regarde mon devis", params: {
            idContact: (this.idContact) ? this.idContact : this.$route.params.idContact,
            typeVente: 'devis',
            idVente: item.id,
          }
        })
      } else {
        this.$router.push({
        name: "J'edite un devis", params: {
          idContact: (this.idContact) ? this.idContact : this.$route.params.idContact,
          typeVente: 'devis',
          idVente: item.id,
        }
      })
      }

    },
    pageChange(page) {
      this.setDevis(page)
    }
  }
};
</script>

<style>

</style>