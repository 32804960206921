<template>
  <div>
    <v-menu ref="menu" v-model="menu" :disabled="disabled" :close-on-content-click="false" :return-value.sync="modelValue"
      transition="scale-transition" offset-y min-width="auto">
      <template v-slot:activator="{ on, attrs }">
        <v-text-field :disabled="disabled" v-model="date" filled rounded prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on">
          <template v-slot:label v-if="rulesInfo == 'required'">
            <span class="input__label">{{ libelle }} <span style="color: red">*</span></span>
          </template>
          <template v-slot:label v-else>
            <span class="input__label">{{ libelle }}</span>
          </template>
        </v-text-field>
      </template>
      <v-date-picker :disabled="disabled"
        :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
        min="1950-01-01" v-model="date" no-title scrollable>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="closeModal()">
          Réinitialiser
        </v-btn>
        <v-btn text color="primary" @click="menu = false">
          Fermer
        </v-btn>
        <v-btn text color="primary" @click="$refs.menu.save(date)">
          OK
        </v-btn>
      </v-date-picker>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: [
    "id",
    "rulesInfo",
    "libelle",
    "reference",
    "icon",
    "disabled",
    "rules",
    "clef",
    "value",
    "placeholder",
    "type",
    "storeDispatchName",
  ],
  data() {
    return {
      date: null,
      menu: false,
      modal: false,
      menu2: false,
    };
  },
  mounted() {
    if (this.value) {
      this.date = this.$moment(this.value).format("YYYY-MM-DD");
    }

  },
  watch: {
    date(val) {
      this.modelValue = val
    },
  },
  computed: {
    modelValue: {
      get() {
        if (this.value) {
          return this.$moment(this.value).format("YYYY-MM-DD");
        } else {
          return this.date;
        }
      },
      set(val) {
        if (val) {
          this.$store.dispatch("formulaire/updateForm", {
            form: this.type,
            field: this.clef,
            value: this.$moment(val).format("YYYY-MM-DD HH:MM:SS"),
          });
        } else {
          this.$store.dispatch("formulaire/updateForm", {
            form: this.type,
            field: this.clef,
            value: null,
          });
        }
      },
    },
  },
  methods: {
    closeModal() {
      this.menu = false
      this.date = null
      this.$refs.menu.save(this.date)
    }
  },
};
</script>

<style>

</style>