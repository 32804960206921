<template>
  <div>
    <v-btn rounded class="bg-color-main text-white" v-if="this.onlyIcon" @click="add()">
      <v-icon>{{ this.icon }}</v-icon>
    </v-btn>
    <v-btn :disabled="!valid" @click="add()" rounded class="bg-color-main text-white" v-else>
      <v-icon>{{ this.icon }}</v-icon>{{ this.nom }}
    </v-btn>
  </div>
</template>

<script>
export default {
  props: ["table", "nom", "icon", "onlyIcon", "valid"],
  name: "Button",
  data() {
    return {
      payload: [],
      displayMessage: [],
      isDisplay: false,
    };
  },

  mounted() { },
  methods: {
    add() {
      this.$store
        .dispatch("formulaire/storeData", this.table)
        .then((response) => {
          if (response.data.messageError) {
            this.$swal({
              toast: true,
              title: response.data.messageError,
              icon: "error",
              animation: true,
              position: "top-right",
              showConfirmButton: false,
              timerProgressBar: true,
              timer: 2000,
            });
            return;
          }
          this.$swal({
            toast: true,
            title: response.data.message,
            icon: "success",
            animation: true,
            position: "top-right",
            showConfirmButton: false,
            timerProgressBar: true,
            timer: 2000,
          });
          this.$emit("saveForm", response.data.element);
          switch (this.table) {
            case "contact":
              this.debitCoins('SCC');
              break;
            case "produits":
              this.debitCoins('SP');
              break;
            case "users":
              this.debitCoins('SU');
              break;
            case "agences":
              this.debitCoins('SPV');
              break;
            case "fournisseurs":
              this.debitCoins('SFF');
              break;
            default:
              break;
          }
        });
    },
  },
};
</script>

<style>

</style>