<template>
  <v-container>
    <h1 class="text-ceter mb-5">Editer nouveau contact</h1>
    <FormContact :contactField="contactField" :eventParams="eventParams" :mode="'edit'"></FormContact>
  </v-container>
</template>

<script>
import FormContact from "@/components/contacts/FormContact.vue";
export default {
  props: ["id"],
  components: { FormContact },
  data() {
    return {
      contactField: [],
      eventParams: [],
    };
  },
  mounted() {

    this.getFieldsContact();
  },
  methods: {
    getFieldsContact() {
      if (this.paramEvent) {
        this.eventParams['whereReturn'] = this.paramEvent['returnMode']
        this.eventParams['idEvent'] = this.paramEvent['idEvent']
      } else {
        this.eventParams['whereReturn'] = "folderContact"
      }
      this.$axios.get("/contact/read/" + this.id).then((response) => {
        this.contactField = response.data;
        this.$store.dispatch("formulaire/initForm", {
          form: "contact",
          listFields: this.contactField,
        });
      });
    },
  },
};
</script>

<style>

</style>