<template>
  <v-container>
    <div class="row">
      <div class="col">
        <div class="d-flex justify-content-start">
          <v-icon>mdi-calendar-range</v-icon>
          <v-switch v-model="filterContact"></v-switch>
          <v-icon>mdi-order-alphabetical-ascending</v-icon>
          <btn-aide-contextuel class="ml-3" description="Par défaut les contacts sont triés par date de création, mais vous pouvez changer en mettant le trie par ordre alphabétique"></btn-aide-contextuel>
        </div>
      </div>
      <div class="col">
        <div class="d-flex justify-content-end mr-3 mt-3">
          <v-btn text @click="geoLocContact()">
            <v-icon>mdi-bullseye-arrow</v-icon> Géoloc
          </v-btn>
          <btn-aide-contextuel class="ml-3" description="Ce bouton permet de trié les contacts du plus près au plus loin de votre position actuelle"></btn-aide-contextuel>
        </div>
      </div>
    </div>
    <v-text-field filled rounded v-model="search" label="Rechercher un contact" prepend-inner-icon="mdi-magnify">
    </v-text-field>
    <div v-if="loaderInit" class="container">
      <div class="d-flex justify-content-center mb-4">
        <v-progress-circular indeterminate color="purple"></v-progress-circular>
      </div>
      <v-skeleton-loader class="rounded-xl"
        type="list-item-three-line, list-item-three-liner,list-item-three-line,list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line">
      </v-skeleton-loader>
    </div>
    <div v-if="maxContacts == 0" class="container">
      <p class="text-center">Il n'y a aucun contact</p>
    </div>
    <div v-else-if="contactFilter.length == 0" class="container">
      <p class="text-center">Aucun contact trouvé !</p>
    </div>
    <v-list v-else v-model="search" rounded class="rounded-xl">
      <v-list-item-group>
        <div v-infinite-scroll="loadContact" infinite-scroll-disabled="busy" infinite-scroll-limit="3" class="pb-5">
          <v-list-item v-for="(item, i) in contactFilter" :key="i" :search="search" :custom-filter="filterOnlyCapsText">
            <v-list-item-content @click="openContactFolder(item)">
              <v-list-item-title class="text-break">
                <v-icon>mdi-account</v-icon>{{ item.titre }} {{ item.nom }}
                {{ item.prenom }}
              </v-list-item-title>
              <p class="text-break">
                <v-icon>mdi-google-maps</v-icon>
                {{ item.ville }}
                {{ item.codePostal }}
              </p>
              <p class="text-break">
                <v-icon>mdi-account-tie</v-icon>{{ item.user }}
              </p>
              <p v-if="item.distance">
                <v-icon>mdi-target</v-icon> {{item.distance}} km
              </p>
              <p>
                <span style="color:#880484" v-if="item.ventes.devis" class="m-1 font-weight-bold">D</span><span
                  class="m-1 font-weight-bold" style="color:#088404" v-if="item.ventes.commandes">C</span><span
                  class="m-1 font-weight-bold" v-if="item.ventes.facture" style="color:#0804fc">F</span>
              </p>
            </v-list-item-content>
            <v-icon x-large>mdi-chevron-left</v-icon>
          </v-list-item>
        </div>
      </v-list-item-group>
    </v-list>
    <div v-if="loaderNext">
      <v-skeleton-loader class="rounded-xl"
        type="list-item-three-line, list-item-three-liner,list-item-three-line,list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line">
      </v-skeleton-loader>
    </div>
  </v-container>
</template>

<script>
/* eslint-disable */
import { bus } from "@/main";
export default {
  data() {
    return {
      mode: 0,
      limitTimeLine: 5,
      busy: false,
      search: "",
      filter: "lastcreate",
      filterContact: 0,
      contacts: [],
      long: "",
      lat: "",
      maxContacts: 0,
      contacts: [],
      loaderInit: true,
      loaderNext: false,
    };
  },
  created() {
    var payload = {
      table: "contact",
      icon: "mdi-plus",
      onlyIcon: true,
      classItems: "bg-primary text-white",
      typeComponent: "vBtnAdd",
      routeDisplay: "Mes contacts",
    };
    bus.$emit("changeButtonAction", payload);
  },
  watch: {
    filterContact(val) {
      if (val) {
        this.filter = "alphabetic"
      } else {
        this.filter = "lastcreate"
      }

      this.setContactsStore();
    },
    search(val) {
      if (val.length > 2) {
        this.$store.dispatch("contacts/searchContact", val);
      } else if (val.length < 2) {
        this.reinitialisationArrayContact();
      } else if (val.length == 0) {
        this.reinitialisationArrayContact();
      }
    },
  },
  beforeMount() {
    this.setContactsStore();
  },
  mounted() {
    var refreshIntervalId = setInterval(() => {
      if (this.$store.getters["contacts/getContacts"]) {
        this.contacts = this.$store.getters["contacts/getContacts"];
        this.contactFilter = this.contacts;
        this.maxContacts = this.$store.getters["contacts/getMaxContacts"];
        this.loaderInit = false;
        clearInterval(refreshIntervalId);
      }
    }, 1000);
  },
  computed: {
    contactFilter: {
      get() {
        return this.$store.getters["contacts/getContactsForFiltre"];
      },
      set(val) {
        return val;
      },
    },
  },
  methods: {
    geoLocContact() {
      if ("geolocation" in navigator) {
        this.filter = "geoloc";
        navigator.geolocation.getCurrentPosition((position) => {
          this.lat = position.coords.latitude;
          this.long = position.coords.longitude;
          this.setContactsStore()
        })
      }
    },
    setContactsStore() {
      if (this.filter == 'geoloc') {
        var payload = {
          pos: {
            lat: this.lat,
            long: this.long
          },
          mode: this.filter,
          limitTimeLine: this.limitTimeLine,
        };
      } else {
        var payload = {
          mode: this.filter,
          limitTimeLine: this.limitTimeLine,
        };
      }

      this.$store.dispatch("contacts/setContacts", payload);
    },
    filterOnlyCapsText(value, search, item) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleUpperCase().indexOf(search) !== -1
      );
    },
    openContactFolder(item) {
      this.$router.push({
        name: "Dossier du contact",
        params: { idContact: item.id },
      });
    },
    reinitialisationArrayContact() {
      this.$store.dispatch("contacts/setContactFilter");
    },
    loadContact() {
      if (
        this.$store.getters["contacts/getMaxContacts"] >
        this.$store.getters["contacts/getContacts"].length
      ) {
        this.loaderNext = true;
        setTimeout(() => {
          this.limitTimeLine = this.limitTimeLine + 3;
          this.setContactsStore();
          this.loaderNext = false;
        }, 2000);
      }
    },
  },
};
</script>

<style>

</style>