<template>
  <div>
    <Calendar></Calendar>
  </div>
</template>

<script>
import Calendar from "@/components/modules/Calendar.vue";
export default {
  components: {
    Calendar,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style>
</style>