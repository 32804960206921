<template>
    <div >
        <v-tooltip bottom color="#5A189A">
            <template v-slot:activator="{ on, attrs }">
                <v-icon  v-bind="attrs" v-on="on" color="grey lighten-1">mdi-information</v-icon>
            </template>
            <span>{{ description }}</span>
        </v-tooltip>

    </div>

</template>

<script>
export default {
    props: ['description']
}
</script>

<style>

</style>