<template>
  <div>
    <label v-if="rulesInfo == 'required'"
      >{{ libelle }}: <span style="color: red">*</span></label
    >
    <v-datetime-picker
      okText="OK"
      :disabled="disabled"
      clearText="Annuler"
      timeFormat="HH:mm"
      :timePickerProps="{ format: '24hr' }"
      dateFormat="dd-MM-yyyy"
      :textFieldProps="{
        filled: true,
        rounded: true,
        'prepend-inner-icon': icon,
        rules: rules,
      }"
      :label="placeholder"
      v-model="modelValue"
    >
      <template v-slot:dateIcon>
        <v-icon>mdi-calendar</v-icon> &nbsp; Date
      </template>
      <template v-slot:timeIcon>
        <v-icon>mdi-clock-outline</v-icon> &nbsp; Horaire
      </template>
    </v-datetime-picker>
  </div>
</template>

<script>
export default {
  props: [
    "id",
    "rulesInfo",
    "libelle",
    "disabled",
    "reference",
    "icon",
    "rules",
    "clef",
    "value",
    "placeholder",
    "type",
    "storeDispatchName",
  ],
  data() {
    return {
      menu: false,
    };
  },
  computed: {
    modelValue: {
      get() {
        if (this.value) {
          return new Date(this.value);
        } else {
          return null;
        }
      },
      set(val) {
        this.$store.dispatch("formulaire/updateForm", {
          form: this.type,
          field: this.clef,
          value: this.$moment(val).format("YYYY-MM-DD HH:mm"),
        });
      },
    },
  },
};
</script>

<style>
</style>