<template>
  <div>
    <FormProduit :receivedProduit="receivedProduit" :typeCRUD="typeCRUD" :idContact="(this.idContact) ? this.idContact : this.$route.params.idContact" :idVente="idVente" :typeVente="typeVente" :fromScenario="fromScenario"></FormProduit>
  </div>
</template>

<script>
import FormProduit from '@/components/produits/FormProduit.vue';
export default {
  components: { FormProduit },
  props: ['typeCRUD', 'receivedProduit', 'fromScenario', 'idVente', "typeVente", 'idContact'],
  data() {
    return {
      produit: []
    }
  },
  methods: {
    deleteItem() {
      this.$swal({
        title: "Voulez-vous supprimer cet élément ?",
        showDenyButton: true,
        type: "danger",
        showCancelButton: false,
        confirmButtonText: "Oui",
        denyButtonText: `Non`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("produits/deleteProduit", this.produit.id)
          this.$axios
            .delete("produits/delete/" + this.produit.id)
            .then((response) => {
              this.$swal("Bravo !", response.data.message, "success");
              this.$router.push({ name: "Mes produits" })
            });
        }
      });
    }
  }
}
</script>

<style>

</style>