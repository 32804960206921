/* eslint-disable */
import store from "@/store/index.js";
import VueSweetalert2 from 'vue-sweetalert2';
import Vue from "vue"
import axios from "axios"

Vue.use(VueSweetalert2);

export default {
    data() {
        return {
            contactFolder: false,
        }
    },
    methods: {
        debitCoins(type, montantFacture = 0) {
            if (!store.getters['auth/user'].entreprise.isSubscriber) {
                var array = {
                    "type": type,
                    "montantFacture": montantFacture
                }
                store.dispatch('coin/debitCoins', array).then((response) => {
                    setTimeout(() => {
                        this.$swal({
                            toast: true,
                            title: response.data.message,
                            icon: "success",
                            animation: true,
                            position: "top-right",
                            showConfirmButton: false,
                            timerProgressBar: true,
                            timer: 2000,
                        });
                    }, 2000);
                    /*   Vue.$toast.open({ message: response.data.message, type: "info", position: 'top-right' }) */
                })
            }

        },
        async verifObjectif() {
            axios.get("/objectifs/verifObjectif").then((response) => {
                if (response.data.message != null) {
                    setTimeout(() => {
                        const options = {
                            title: "Félicitation !",
                            imageUrl: response.data.badge,
                            imageHeight: "100px",
                            text: response.data.message,
                        };
                        this.$swal(options);
                    }, 2000);
                }
            });
        }
    }
};