<template>
  <div>
    <vBtnAdd :disabled="vente.isDone" class="mb-2" nom="Créer un produit" classItems="bg-color-main text-white ml-3" typeCRUD="add"
      fromScenario="vente" :typeVente="typeVente" :idContact="(this.idContact) ? this.idContact : this.$route.params.idContact" :id="idVente" table="produit" icon="mdi-plus"></vBtnAdd>
    <v-btn v-if="!vente.isDone" @click="redirectTo()" rounded small class="bg-color-main text-white ml-3 mb-3">
      <v-icon>mdi-cart-arrow-down</v-icon>Ajouter un produit existant
    </v-btn>
    <vTableData :items="produits" :loader="loader" @triggerRowTable="selectItem" :headers="[
      {
        text: 'Desig',
        align: 'left',
        sortable: true,
        value: 'designation',
      },
      {
        text: 'Q.',
        align: 'left',
        sortable: true,
        value: 'quantite',
      },
      { text: 'PU', value: 'prix_unitaire' },
      { text: 'TVA', value: 'tva' },
      { text: 'TTC', value: 'montant_ttc' },
    
    ]" :hiddenFooter="true" :hiddenHeader="false"></vTableData>
  </div>
</template>

<script>

export default {
  props: ["idVente", "typeVente", 'idContact', 'vente'],
  data() {
    return {
      loader: true,
      dialog: false,
      produits: []
    }
  },
  mounted() {
    this.getProduitsVente()
  },
  methods: {
    selectItem(item) {
      if(!this.vente.isDone) {
        this.$emit('selectItem', item)
      }
    },
    redirectTo() {
      this.$router.push({ name: "Je cherche mes produits", params: {idContact:(this.idContact) ? this.idContact : this.$route.params.idContact ,idVente: this.idVente, typeVente: this.typeVente, produitsAlreadySelected: this.produits } })
    },
    getProduitsVente() {
      this.loader = true
      var payload = {
        idVente: this.idVente,
        typeVente: this.typeVente
      }
      this.$axios.post('produits/ventes/getProduits', payload).then((response) => {
        this.produits = response.data.produits
        this.$emit('updateMontantTva', response.data)
        this.loader = false
      })
    }
  }
};
</script>

<style>

</style>