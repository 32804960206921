var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('vBtnAdd',{staticClass:"mb-2",attrs:{"disabled":_vm.vente.isDone,"nom":"Créer un produit","classItems":"bg-color-main text-white ml-3","typeCRUD":"add","fromScenario":"vente","typeVente":_vm.typeVente,"idContact":(this.idContact) ? this.idContact : this.$route.params.idContact,"id":_vm.idVente,"table":"produit","icon":"mdi-plus"}}),(!_vm.vente.isDone)?_c('v-btn',{staticClass:"bg-color-main text-white ml-3 mb-3",attrs:{"rounded":"","small":""},on:{"click":function($event){return _vm.redirectTo()}}},[_c('v-icon',[_vm._v("mdi-cart-arrow-down")]),_vm._v("Ajouter un produit existant ")],1):_vm._e(),_c('vTableData',{attrs:{"items":_vm.produits,"loader":_vm.loader,"headers":[
    {
      text: 'Desig',
      align: 'left',
      sortable: true,
      value: 'designation',
    },
    {
      text: 'Q.',
      align: 'left',
      sortable: true,
      value: 'quantite',
    },
    { text: 'PU', value: 'prix_unitaire' },
    { text: 'TVA', value: 'tva' },
    { text: 'TTC', value: 'montant_ttc' },
  
  ],"hiddenFooter":true,"hiddenHeader":false},on:{"triggerRowTable":_vm.selectItem}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }