<template>
  <div>
    <v-tabs :color="isDarkMode ? '#FF7900' : '#5A189A'" v-model="tab" fixed-tabs centered icons-and-text>
      <v-tabs-slider></v-tabs-slider>
      <v-tab href="#tab-1" id="tab-contact"> Contact </v-tab>

      <v-tab href="#tab-2" id="tab-rdv"> RDV </v-tab>

      <v-tab href="#tab-3" id="tab-devis"> Devis </v-tab>

      <v-tab href="#tab-4" id="tab-commandes"> Commandes </v-tab>

      <v-tab href="#tab-5" id="tab-factures"> Factures </v-tab>

      <v-tab href="#tab-6" id="tab-liens"> Liens </v-tab>
    </v-tabs>
    <v-tabs-items touchless v-model="tab">
      <v-tab-item id="tab-1">
        <div v-if="loader" class="mx-auto my-auto">
          <v-progress-linear indeterminate rounded :color="isDarkMode ? '#FF7900' : '#5A189A'"></v-progress-linear>
        </div>

        <div class="container" v-else>
          <h2>{{ contact.nom }} {{ contact.prenom }}</h2>
          <p class="lead" style="font-size: 17px">
            <v-icon>mdi-google-maps</v-icon> {{ contact.rue }}
            {{ contact.ville }} <strong>{{ contact.codePostal }}</strong> <br />
            <v-icon>mdi-phone-classic</v-icon>
            <strong><a class="text-warning" :href="'tel:' + contact.telphoneFixe">{{
            contact.telephoneFixe
            }}</a> </strong><br />
            <v-icon>mdi-cellphone</v-icon>
            <strong><a class="text-warning" :href="'tel:' + contact.telephoneMobile">{{ contact.telephoneMobile
            }}</a></strong><br />
            <v-icon>mdi-at</v-icon>
            <strong><a class="text-warning" :href="'mailto:' + contact.email">
                {{ contact.email }}</a></strong>
            <br />
            <v-icon>mdi-door-closed </v-icon><strong>{{ contact.source }} </strong><br />
          </p>
          <v-container>
            <v-btn class="mt-2 text-white" block style="background-color: #e66d00" @click="editContact(contact)"
              type="button">
              <v-icon>mdi-pencil-box</v-icon>
              Modifier le contact
            </v-btn>
            <v-btn @click="redirectTo('Je fais un devis')" type="button" :disabled="(showIsSubscriber()) ? ((areYouEnoughCoins(1)) ? false : true) : false "
              class="text-white mt-2" block style="background-color: purple">
              <v-icon>mdi-file-outline</v-icon>
              Faire un devis
            </v-btn>
            <v-btn @click="redirectTo('Je faits une commande')" type="button"
              :disabled="(showIsSubscriber()) ? ((areYouEnoughCoins(1)) ? false : true) : false" class="text-white mt-2" block style="background-color: green">
              <v-icon>mdi-file-outline</v-icon>
              Faire une commande
            </v-btn>
            <a class="text-decoration-none" v-if="google_buiz" :href="
              'sms:/' +
              contact.telephoneMobile +
              '?body=Bonjour, voici mon lien Google MyBusiness :' +
              google_buiz
            ">
              <v-btn class="text-dark btn-lg btn-block text-center mt-2" style="background-color: #ffffff" block>
                <v-icon class="text-dark">mdi-google</v-icon> Envoie fiche
                Google Business par SMS
              </v-btn>
            </a>
            <v-btn @click="deleteItem(contact)" v-if="myRole == 'Manager' || 'Superadmin'" class="text-white mt-2" block
              style="background-color: #ef0000">
              <v-icon>mdi-delete</v-icon>
              Supprimer le contact
            </v-btn>
          </v-container>
        </div>
      </v-tab-item>
      <v-tab-item id="tab-2">
        <ListeRdv :contact="contact"></ListeRdv>
      </v-tab-item>
      <v-tab-item id="tab-3">
        <ListeDevis :idContact="idContact"></ListeDevis>
      </v-tab-item>
      <v-tab-item id="tab-4">
        <ListeCommande :idContact="idContact"></ListeCommande>
      </v-tab-item>
      <v-tab-item id="tab-5">
        <ListeFacture :idContact="idContact"></ListeFacture>
      </v-tab-item>
      <v-tab-item id="tab-6"><ListeLien :idContact="idContact"></ListeLien></v-tab-item>
    </v-tabs-items>
  </div>
</template>
<script>
import variableGlobalMixin from "@/mixins/variableGlobalMixin.js";
import ListeRdv from "@/components/rdv/ListeRdv.vue";
import ListeDevis from "@/components/ventes/devis/ListeDevis.vue";
import ListeCommande from "@/components/ventes/commande/ListeCommande.vue"
import ListeFacture from "@/components/ventes/facture/ListeFacture.vue"
import ListeLien from "@/components/liens/ListeLien.vue"
import viewMixin  from "@/mixins/viewMixin.js";

export default {
  components: { ListeRdv, ListeDevis, ListeCommande, ListeFacture , ListeLien},
  mixins: [variableGlobalMixin, viewMixin],
  props: ["idContact"],
  data() {
    return {
      contact: [],
      tab: 1,
      loader: true,
      dialog: false,
      valid: "",
      notifications: false,
      contactFolder: false,
      sound: true,
      dialogConfirmation: false,
      widgets: false,
      dialogDelete: false,
    };
  },
  mounted() {
    this.getContactFolder();
  },
  computed: {
    myRole() {
      if (this.$store.getters["auth/role"]) {
        return this.$store.getters["auth/role"];
      }
      return this.$store.getters["auth/role"];
    },
    google_buiz() {
      return this.authUserEntreprise("google_buiz");
    },
  },
  methods: {
    getContactFolder() {
      this.$axios.get("contact/read/" + this.idContact).then((response) => {
        this.contact = response.data;
        this.loader = false;
      });
    },
    deleteItem(item) {
      this.$swal({
        title: "Voulez-vous supprimer ce contact ?",
        showDenyButton: true,
        type: "danger",
        showCancelButton: false,
        confirmButtonText: "Oui",
        denyButtonText: `Non`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$axios.delete("contact/delete/" + item.id).then((response) => {
            this.$swal("Bravo !", response.data.message, "success");
            this.$router.push({name: "Mes contacts"})
          });
        }
      });
    },
    redirectTo(path) {
      this.$router.push({ name: path, params: { mode: "create", idContact: this.contact.id, contact: this.contact } });
    },
    redirectToDocument(doc) {
      this.dialogConfirmation = false;
      this.$emit("openDevis", doc);
    },
    editContact(contact) {
      this.$router.push({
        name: "J'edite un contact",
        params: { id: contact.id },
      });
    },
  },
};
</script>

<style>

</style>