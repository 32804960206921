<template>
    <div>
        <form-commande :mode="'create'" :noContact="true"></form-commande>
    </div>
</template>

<script>
import FormCommande from '@/components/ventes/commande/FormCommande.vue'
export default {
    components: { FormCommande },
    data() {
        return {

        }
    },
    mounted() {
        
    },
}
</script>

<style>

</style>