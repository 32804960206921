<template>
    <div>
        <audio id="musicplayer" autoplay>
            <source src="../../assets/sound/caisse.mp3" />
        </audio>
    </div>
</template>
  
<script>
/* eslint-disable */
export default {
    data() {
        return {};
    },
    mounted() {
        this.showAlert();
    },
    methods: {
        showAlert() {
            var audio = document.getElementById("musicplayer");
            this.$swal(
                "Une pluie de Coins !",
                "Félicitation votre compte à été créditer en Coins !!",
                "success"
            ).then(() => {
                audio.play();
                this.$router.push({ name: "Je veux des coins" })
            });
        },
    },
};
</script>
  
<style>

</style>
  