/* eslint-disable */
import Vue from 'vue'
import axios from 'axios'
import router from "/src/router";

export default {
    namespaced: true,
    state: {
        models: [],
        totalPaginate: 0,
    },
    mutations: {
        initModels(state, datas) {
            state.models = datas
        },
        deleteModel(state, id) {
            var index = state.models.findIndex(model => model.id == id)
            state.models.splice(index, 1)
        },
        setTotalPaginate(state, data) {
            state.totalPaginate = data
        }
    },
    getters: {
        getModels(state) {
            return state.models
        },
        getTotalPaginate(state) {
            return state.totalPaginate
        }
    },
    actions: {
        setModels({ commit }, page) {
            axios
                .get("model/index?page=" + page)
                .then((response) => {
                    commit('initModels', response.data.models)
                    commit('setTotalPaginate', response.data.last_page)
                }).catch(function(error) {
                    if (error.response.status === 401) {
                        setTimeout(() => {
                            router.push({
                                name: "Connexion"
                            });
                        }, 900);
                    }
                });
        },
        deleteModel({ commit }, id) {
            commit('deleteModel', id)
        }
    }
}