<template>
    <div>
      <v-form v-model="valid">
        <input type="hidden" v-model="editedItem.id" />
        <v-col cols="12">
          <vTextInput
            libelle="Libelle"
            clef="libelle"
            class="mt-6"
            id="sources-libelle"
            rulesInfo="required"
            :value="editedItem.libelle"
            placeholder="Internet.."
            :rules="[(v) => !!v || 'Le champ est obligatoire']"
            type="sources"
            icon="mdi-form-textbox"
          ></vTextInput>
        </v-col>
        <v-card-actions>
          <v-spacer></v-spacer>
          <vBtnEdit
            nom="Sauvegarder"
            v-if="validateForm() && source.id"
            :valid="valid"
            icon="mdi-content-save"
            @saveForm="returnTo"
            :onlyIcon="false"
            :id="editedItem.id"
            table="sources"
          ></vBtnEdit>
          <VBtnSave
            v-else
            nom="Créer"
            :valid="valid"
            icon="mdi-plus"
            @saveForm="returnTo"
            :onlyIcon="false"
            table="sources"
          ></VBtnSave>
          <v-btn class="m-1" text @click="closeModal"> Annuler </v-btn>
        </v-card-actions>
      </v-form>
    </div>
  </template>
  
  <script>
  export default {
    props: ["editedItem","typeCRUD"],
    data() {
      return {
        valid: true,
        requiredRules: [(v) => !!v || "Le champ est obligatoire"],
      };
    },
    computed: {
      source() {
        return this.$store.getters["formulaire/getFormType"]("sources")
      }
    },
    methods: {
      validateForm() {
        if (this.source.libelle != "") {
          return true;
        } else {
          return false;
        }
      },
      closeModal() {
        this.$emit("close");
      },
      returnTo(sourceStored) {
        if (this.typeCRUD == "edit") {
          this.$store.dispatch("sources/updateSource", sourceStored);
        } else {
            this.$store.dispatch("sources/storeSource", sourceStored);
        }
        
        this.closeModal()
      },
    },
  };
  </script>
  
  <style>
  </style>