<template>
  <div>
    <v-form v-model="valid">
      <input type="hidden" v-model="editedItem.id" />
      <v-col cols="12">
        <vTextInput
          libelle="Nom"
          clef="libelle"
          class="mt-6"
          id="commissions-libelle"
          rulesInfo="required"
          :value="editedItem.libelle"
          placeholder="Societe..."
          :rules="[(v) => !!v || 'Le champ est obligatoire']"
          type="type_commissions"
          icon="mdi-form-textbox"
        ></vTextInput>
        <VBoxCheck
          libelle="Ce type de commission est-il celui donné l'entreprise ?"
          :value="editedItem.isSociete"
          clef="isSociete"
          id="event-isSocite"
          label="Ce type de commission est-il celui donné l'entreprise ?"
          type="type_commissions"
          icon="mdi-close-outline"
        ></VBoxCheck>
      </v-col>
      <v-card-actions>
        <v-spacer></v-spacer>
        <vBtnEdit
          nom="Sauvegarder"
          v-if="validateForm() && commission.id"
          :valid="valid"
          icon="mdi-content-save"
          @saveForm="returnTo"
          :onlyIcon="false"
          :id="editedItem.id"
          table="type_commissions"
        ></vBtnEdit>
        <VBtnSave
          v-else
          nom="Créer"
          :valid="valid"
          icon="mdi-plus"
          @saveForm="returnTo"
          :onlyIcon="false"
          table="type_commissions"
        ></VBtnSave>
        <v-btn class="m-1" text @click="closeModal"> Annuler </v-btn>
      </v-card-actions>
    </v-form>
  </div>
</template>

<script>
export default {
  props: ["editedItem", "typeCRUD"],
  data() {
    return {
      valid: true,
      requiredRules: [(v) => !!v || "Le champ est obligatoire"],
    };
  },
  computed: {
    commission() {
      return this.$store.getters["formulaire/getFormType"]("type_commissions");
    },
  },
  methods: {
    validateForm() {
      if (this.commission.libelle != "") {
        return true;
      } else {
        return false;
      }
    },
    closeModal() {
      this.$emit("close");
    },
    returnTo(commissionStored) {
      if (this.typeCRUD == "edit") {
        this.$store.dispatch("commission/updateCommission", commissionStored);
      } else {
        this.$store.dispatch("commission/storeCommission", commissionStored);
      }

      this.closeModal();
    },
  },
};
</script>

<style></style>
