/* eslint-disable */
import Vue from 'vue'
import axios from 'axios'
import router from "/src/router";
export default {
    namespaced: true,
    state: {
        commissions: [],
        totalPaginate: 0
    },
    mutations: {
        setCommissions(state, datas) {
            state.commissions = datas
        },
        storeCommission(state, data) {
            state.commissions.push(data)
        },
        updateCommission(state, data) {
            var index = state.commissions.findIndex(s => s.id === data.id);
            Vue.set(state.commissions, index, data);
        },
        deleteCommission(state, id) {
            var index = state.commissions.findIndex(commission => commission.id == id)
            state.commissions.splice(index, 1)
        },
        setTotalPaginate(state, data) {
            state.totalPaginate = data
        }
    },
    getters: {
        getCommissions(state) {
            return state.commissions
        },
        getTotalPaginate(state) {
            return state.totalPaginate
        }
    },
    actions: {
        setCommissions({ commit }, page) {
            axios.get('type_commissions/index?page=' + page).then((response) => {
                commit('setTotalPaginate', response.data.last_page)
                commit('setCommissions', response.data.data)
            }).catch(function(error) {
                if (error.response.status === 401) {
                    setTimeout(() => {
                        router.push({
                            name: "Connexion"
                        });
                    }, 900);
                }
            });
        },
        updateCommission({ commit }, data) {
            commit('updateCommission', data)
        },
        storeCommission({ commit }, data) {
            commit('storeCommission', data)
        },
        deleteCommission({ commit }, id) {
            commit('deleteCommission', id)
        }
    }
}