var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticStyle:{"right":"-10px"}},[(_vm.areYouEnoughCoins(1) || !_vm.showIsSubscriber())?_c('vBtnAdd',{staticClass:"mb-2 mt-3 mr-5 ml-2",attrs:{"classItems":'bg-color-main text-white',"nom":"Ajouter une produit","element":_vm.editedItem,"typeCRUD":"create","table":"produit","icon":"mdi-plus"}}):_vm._e()],1),_c('div',{staticClass:"float-bottom"},[_c('VTableData',{attrs:{"items":_vm.items,"loader":_vm.loader,"headers":[
      {
        text: 'Désignation',
        align: 'left',
        sortable: true,
        value: 'designation',
      },
      {
        text: 'Description',
        align: 'left',
        sortable: true,
        value: 'description',
      },
      {
        text: 'Famille de produit',
        align: 'left',
        sortable: true,
        value: 'categorie',
      },
      {
        text: 'TVA',
        align: 'left',
        sortable: true,
        value: 'tva',
      },
      {
        text: 'Fournisseur',
        align: 'left',
        sortable: true,
        value: 'fournisseur',
      },
      {
        text: 'Prix unitaire',
        align: 'left',
        sortable: true,
        value: 'prix_unitaire',
      },
    ],"hiddenFooter":true,"hiddenHeader":false,"total":_vm.total},on:{"triggerRowTable":_vm.editItem,"pageChange":_vm.pageChange}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }