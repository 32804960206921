/* eslint-disable */
import Vue from 'vue'
import axios from 'axios'
import router from "/src/router";
export default {
    namespaced: true,
    state: {
        events: [],
        event: [],
        optionsSelectContact: [],
        optionsSelectTypeRdv: [],
        optionsSelectUsers: [],
        optionsSelectSources: [],
        optionLoad: false,
    },
    mutations: {
        setEvents(state, datas) {
            state.events = datas
        },
        setContactId(state, id) {
            state.contact_id = id
        },
        addEvent(state, data) {
            state.events.push(data)
        },
        setEvent(state, data) {
            state.event = data
        },
        setOptions(state, datas) {
            state.optionsSelectContact = datas.contacts
            state.optionsSelectTypeRdv = datas.typeRdv
            state.optionsSelectSources = datas.sources
            state.optionsSelectUsers = datas.users
        },
        setOptionLoad(state, data) {
            state.optionLoad = data
        },
        editEvent(state, data) {
            state.events = state.events.map(event => {
                if (event.id === data.id) {
                    return Object.assign({}, event, data)
                }
            })
        },
        deleteEvent(state, id) {
            var index = state.events.findIndex(event => event.id == id)
            state.events.splice(index, 1)
        },
    },
    getters: {
        getEvents(state) {
            return state.events
        },
        getEvent(state) {
            return state.event
        },
        getOptions(state) {
            var payload = {
                contacts: state.optionsSelectContact,
                typeRdvs: state.optionsSelectTypeRdv,
                sources: state.optionsSelectSources,
                users: state.optionsSelectUsers
            }
            return payload
        },
        getOptionLoad(state) {
            return state.optionLoad
        },
    },
    actions: {
        setEvents({
            commit
        }, datas) {
            commit("setEvents", datas);
        },
        storeEvents({
            commit
        }, payload) {
            return new Promise((resolve, reject) => {
                axios.post("events/store", payload).then((response) => {
                    commit('addEvent', response.data.element)
                    if (response.data.message) {
                        resolve(response)
                    }

                });
            })
        },
        updateEvent({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios.put("events/update/" + payload.id, payload.datas).then((response) => {
                    resolve(response)
                    commit("editEvent", response.data.event)
                })
            })
        },
        setEvent({
            commit
        }, datas) {
            commit('setEvent', datas)
        },
        deleteEvent({
            commit
        }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .delete("events/delete/" + data.id)
                    .then((response) => {
                        resolve(response)
                        commit("deleteEvent", data.id);
                    });
            })
        },
        optionsEvents({
            commit
        }) {
            axios.get("param/getSelectEvents").then((response) => {
                commit('setOptionLoad', true)
                commit('setOptions', response.data);
            });

        }
    }
}