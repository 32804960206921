<template>
  <div>
    <v-form v-model="valid" class="container mb-5">
      <v-card-title> Commande </v-card-title>
      <v-container v-if="loader">
        <h1 class="text-center" style="margin-top: 150px">
          Chargement du formulaire . . .
        </h1>
        <v-progress-linear :indeterminate="true"></v-progress-linear>
      </v-container>
      <div v-else>
        <div class="d-flex justify-content-end">
          <v-dialog v-model="modelDialog">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="!commande.isDone && commande.contact_id"
                v-bind="attrs"
                v-on="on"
                class="bg-primary rounded-xl text-white"
                @click="dialogModel()"
                ><v-icon>mdi-file-replace</v-icon> Appliquer un modèle</v-btn
              >
            </template>
            <v-card>
              <v-card-title>Selectionner un modèle</v-card-title>
              <v-divider></v-divider>
              <v-card-text style="height: 300px">
                <v-radio-group
                  v-model="modelSelected"
                  v-for="item in models"
                  v-bind:key="item.id"
                  column
                >
                  <v-radio :label="item.titre" :value="item.id"></v-radio>
                </v-radio-group>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-btn class="m-1 bg-secondary" @click="modelDialog = false">
                  Fermer
                </v-btn>
                <v-btn
                  class="bg-primary"
                  text
                  @click="
                    (modelDialog = false),
                      applyModel(modelSelected),
                      (showRestDocument = true),
                      (showButtonModel = false)
                  "
                >
                  Choisir
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
        <v-row>
          <div v-if="noContact && !commandeForm.contact_id" class="d-flex justify-content-center mt-3">
            <v-btn rounded class="bg-color-main text-white" @click="redirectTo('Je crée un contact')"><v-icon>mdi-plus</v-icon>Créer un contact</v-btn>
          </div>

          <VOneSelect
            icon="mdi-account"
            v-if="noContact"
            libelle="Contact"
            :datas="optionsSelectContacts"
            clef="contact_id"
            itemText="libelle"  
            class="mt-5"
            itemValue="id"
            :rules="[(v) => !!v || 'Le champ est obligatoire']"
            rulesInfo="required"
            type="commande"
            :value="commande.contact_id"
          >
          </VOneSelect>
          <div v-if="commandeForm.contact_id">
            <vTextInput
              libelle="Nom du projet"
              :disabled="commande.isDone ? true : false"
              clef="libelle"
              class="mt-6"
              id="commande-libelle"
              rulesInfo="required"
              :value="commande.libelle"
              placeholder="Projet véranda..."
              :rules="[(v) => !!v || 'Le champ est obligatoire']"
              type="commande"
              icon="mdi-file-document"
            ></vTextInput>

            <VDateSimple
              icon="mdi-calendar-week"
              :disabled="commande.isDone ? true : false"
              libelle="Date du document"
              placeholder="Saississez une date"
              clef="date_commande"
              rulesInfo="required"
              reference="dateCommande"
              type="commande"
              :value="commande.date_commande"
              :rules="[(v) => !!v || 'Le champ est obligatoire']"
            >
            </VDateSimple>

            <VOneSelect
              icon="mdi-file-question"
              :disabled="commande.isDone ? true : false"
              libelle="État de la commande"
              :datas="optionsSelectEtatDocuments"
              clef="etat_id"
              v-if="!commande.isSigned"
              itemText="libelle"
              itemValue="id"
              rulesInfo="required"
              type="commande"
              :value="commande.etat_id"
              :rules="[(v) => !!v || 'Le champ est obligatoire']"
            >
            </VOneSelect>

            <VOneSelect
              icon="mdi-file-question"
              :disabled="commande.isDone ? true : false"
              libelle="Source"
              :datas="optionsSelectSources"
              clef="source_id"
              itemText="libelle"
              class="mt-5"
              itemValue="id"
              :rules="[(v) => !!v || 'Le champ est obligatoire']"
              rulesInfo="required"
              type="commande"
              :value="commande.source_id"
            >
            </VOneSelect>

            <VOneSelect
              icon="mdi-account"
              :disabled="commande.isDone ? true : false"
              libelle="Deuxième commercial"
              :datas="optionsSelectUsers"
              clef="second_commercial"
              itemText="libelle"
              itemValue="id"
              class="mt-5"
              rulesInfo="facultatif"
              type="commande"
              :value="commande.second_commercial"
            ></VOneSelect>

            <VOneSelect
              icon="mdi-file-question"
              :disabled="commande.isDone ? true : false"
              v-if="commande.isValidated"
              libelle="Type de commission"
              class="mt-5"
              :datas="optionSelectTypeCommission"
              clef="type_commission_id"
              itemText="libelle"
              itemValue="id"
              rulesInfo="facultatif"
              type="commande"
              :value="commande.type_commission_id"
            >
            </VOneSelect>

            <VOneSelect
              icon="mdi-file-question"
              :disabled="commande.isDone ? true : false"
              v-if="commande.isValidated"
              libelle="Type de règlement"
              :datas="optionsSelectReglements"
              clef="reglement_id"
              itemText="libelle"
              class="mt-5"
              itemValue="id"
              rulesInfo="facultatif"
              type="commande"
              :value="commande.reglement_id"
            >
            </VOneSelect>
          </div>
        </v-row>

        <div class="d-flex justify-content-end mt-5">
          <v-btn
            :disabled="validateForm()"
            rounded
            :valid="valid"
            v-if="!commande.id"
            :class="isDarkMode ? 'bg-color-main' : 'bg-color-secondaire'"
            class="mt-3"
            @click="storeCommande()"
          >
            Suivant <v-icon>mdi-arrow-right</v-icon>
          </v-btn>
          <v-btn
            :disabled="validateForm()"
            rounded
            :valid="valid"
            v-else
            :class="isDarkMode ? 'bg-color-main' : 'bg-color-secondaire'"
            class="mt-3"
            @click="updateCommande()"
          >
            Suivant <v-icon>mdi-arrow-right</v-icon>
          </v-btn>
        </div>
      </div>
    </v-form>
  </div>
</template>

<script>
/* eslint-disabled */

export default {
  props: ["mode", "idContact", "contact", "commandeReceived", "noContact"],
  data() {
    return {
      optionSelectTypeCommission: [],
      optionsSelectUsers: [],
      optionSelectCategories: [],
      optionsSelectSources: [],
      optionsSelectContacts: [],
      optionsSelectEtatDocuments: [],
      optionSelectFournisseur: [],
      optionsSelectReglements: [],
      valid: true,
      requiredRules: [(v) => !!v || "Le champ est obligatoire"],
      numberRules: [
        (v) => !!v || "Le champ est obligatoire",
        (v) => !isNaN(v) || "Ce n'est pas un nombre",
      ],
      emailRules: [
        (v) => !!v || "Le champ doit être rempli",
        (v) => /.+@.+\..+/.test(v) || "L'adresse email est invalide",
      ],
      passwordConfirm: [
        (v) =>
          this.validatePassword(v) || "Les mots de passes ne concorde pas !",
      ],
      rules: {
        requiredRules: (v) => !!v || "Le champ est obligatoire",
        minRules: (v) =>
          (v && v.length >= 6) ||
          "Le mot de passe doit faire au minimum 6 caractères",
      },
      numberRulesNoObligatory: [(v) => !isNaN(v) || "Ce n'est pas un nombre"],
      modelDialog: false,
      models: [],
      modelSelected: [],
      commande: {
        id: "",
        libelle: "",
        montant_ht: "",
        montant_ttc: "",
        montant_total_tva_cinq: "",
        montant_total_tva_dix: "",
        montant_total_tva_vingt: "",
        date_commande: null,
        annuler: 0,
        montant_remise: "",
        date_signature: null,
        signature_image: "",
        isSigned: "",
        source_id: "",
        isDone: 0,
        user_id: "",
        contact_id: this.idContact
          ? this.idContact
          : this.$route.params.idContact,
        etat_id: 1,
        reglement_id: "",
        type_commission_id: "",
        second_commercial: "",
      },
      loader: true,
    };
  },
  mounted() {
    this.loader = true;
    this.optionsSelect();
    var count = 0;
    var refreshIntervalId3 = setInterval(() => {
      if (this.commandeReceived) {
        this.commande = this.commandeReceived;
        this.setFormCommande();
        clearInterval(refreshIntervalId3);
      }
      if (count > 3) {
        this.setFormCommande();
        clearInterval(refreshIntervalId3);
      }

      count++;
    }, 1000);
  },
  computed: {
    commandeForm() {
      if (!this.loader) {
        return this.$store.getters["formulaire/getFormType"]("commande");
      }
      return [];
    },
  },
  methods: {
    setFormCommande() {
      this.$store.dispatch("formulaire/initForm", {
        form: "commande",
        listFields: {
          id: this.commande.id,
          libelle: this.contact
            ? this.contact.nom + " " + this.contact.prenom
            : "",
          montant_ht: this.commande.montant_ht,
          montant_ttc: this.commande.montant_ttc,
          montant_total_tva_cinq: this.commande.montant_total_tva_cinq,
          montant_total_tva_dix: this.commande.montant_total_tva_dix,
          montant_total_tva_vingt: this.commande.montant_total_tva_vingt,
          date_commande: this.commande.date_commande,
          annuler: this.commande.annuler,
          montant_remise: this.commande.montant_remise,
          date_signature: this.commande.date_signature,
          signature_image: this.commande.signature_image,
          isSigned: this.commande.isSigned,
          source_id: this.commande.source_id,
          user_id: this.commande.user_id,
          contact_id: this.commande.contact_id,
          etat_id: this.commande.etat_id,
          type_commission_id: this.commande.type_commission_id,
          reglement_id: this.commande.reglement_id,
          second_commercial: this.commande.second_commercial,
        },
      });
      this.loader = false;
    },
    validateForm() {
      if (
        this.commandeForm.libelle != "" &&
        this.commandeForm.date_commande != null &&
        this.commandeForm.etat_id != "" &&
        this.commandeForm.source_id != ""
      ) {
        return false;
      } else {
        return true;
      }
    },
    optionsSelect() {
      this.$axios.get("param/getSelectVentes").then((response) => {
        this.optionSelectTypeCommission = response.data.type_commission;
        this.optionsSelectUsers = response.data.users;
        this.optionsSelectContacts = response.data.contacts;
        this.optionSelectCategories = response.data.categories;
        this.optionsSelectSources = response.data.sources;
        this.optionsSelectEtatDocuments = response.data.etat_documents;
        this.optionSelectFournisseur = response.data.fournisseurs;
        this.optionsSelectReglements = response.data.reglements;
      });
    },
    dialogModel() {
      this.modelDialog = true;
      this.getModels();
    },
    applyModel(idModel) {
      var payload = {
        typeVente: "commande",
        idModel: idModel,
        contact_id: this.contact.id
      }
      this.$axios
        .post("/ventesDocument/applyModel", payload)
        .then((response) => {
          this.commande = response.data;
          this.setFormDevis();
        });
    },
    getModels() {
      this.$axios.get("/model/list/commande").then((response) => {
        this.models = response.data;
      });
    },
    returnTo() {
      this.$router.go(-1);
    },
    storeCommande() {
      var contact_id = this.idContact
        ? this.idContact
        : this.$route.params.idContact;
      if (!contact_id) {
        contact_id = this.commandeForm.contact_id;
      }
      var payload = {
        annuler: 0,
        date_signature: this.commandeForm.date_signature,
        date_commande: this.commandeForm.date_commande,
        etat_id: this.commandeForm.etat_id,
        libelle: this.commandeForm.libelle,
        reglement_id: this.commandeForm.reglement_id,
        second_commercial: this.commandeForm.second_commercial,
        source_id: this.commandeForm.source_id,
        type_commission_id: this.commandeForm.type_commission_id,
        contact_id: contact_id,
      };
      this.$axios.post("commande/store", payload).then((response) => {
        if (response.data.messageError) {
          this.$swal(
            "Une erreur est survenue",
            response.data.messageError,
            "error"
          );
        }

        this.$router.push({
          name: "J'ajoute mes produits",
          params: {
            idContact: contact_id,
            typeVente: "commande",
            idVente: response.data.commande.id,
          },
        });
        this.$swal({
          toast: true,
          title: response.data.message,
          icon: "success",
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timerProgressBar: true,
          timer: 2000,
        });
        /* this.$store
          .dispatch("commande/storeCommande", response.data.commande)
          .then(() => {
      
          }); */
      });
    },
    redirectTo(path) {
      this.$router.push({name: path})
    },
    updateCommande() {
      var contact_id = this.idContact
        ? this.idContact
        : this.$route.params.idContact;
      if (!contact_id) {
        contact_id = this.commandeForm.contact_id;
      }
      var payload = {
        annuler: this.commandeForm.annuler,
        date_signature: this.commandeForm.date_signature,
        date_commande: this.commandeForm.date_commande,
        etat_id: this.commandeForm.etat_id,
        libelle: this.commandeForm.libelle,
        reglement_id:
          this.commandeForm.reglement_id === undefined
            ? null
            : this.commandeForm.reglement_id,
        second_commercial: this.commandeForm.second_commercial,
        source_id: this.commandeForm.source_id,
        contact_id: contact_id,
        type_commission_id: this.commandeForm.type_commission_id
      };
      if (this.commande.isDone) {
        this.$router.push({
          name: "J'ajoute mes produits",
          params: {
            idContact: contact_id,
            typeVente: "commande",
            idVente: this.commande.id,
          },
        });
      } else {
        this.$axios
          .put("commande/update/" + this.commande.id, payload)
          .then((response) => {
            if (response.data.messageError) {
              this.$swal(
                "Une erreur est survenue",
                response.data.messageError,
                "error"
              );
            }
            this.$router.push({
              name: "J'ajoute mes produits",
              params: {
                idContact: contact_id,
                typeVente: "commande",
                idVente: response.data.commande.id,
              },
            });
            this.$swal({
              toast: true,
              title: response.data.message,
              icon: "success",
              animation: true,
              position: "top-right",
              showConfirmButton: false,
              timerProgressBar: true,
              timer: 2000,
            });
          });
      }
    },
  },
};
</script>

<style>
</style>
