import Vue from "vue";
import NavBar from "@/components/navigations/NavBar.vue";
import BottomBar from "@/components/navigations/BottomBar.vue";
import BtnAideContextuel from '@/components/modules/BtnAideContextuel.vue';
import vTextInput from "@/components/inputs/saisies/vTextInput.vue";
import VDateSimple from "@/components/inputs/saisies/VDateSimple.vue";
import VLabelParagraph from "@/components/inputs/displays/VLabelParagraph.vue";
import CadreSignature from "@/components/modules/CadreSignature.vue";
import TableauProduit from "@/components/produits/TableauProduit.vue";
import VBtnEdit from "@/components/inputs/boutons/vBtnEdit.vue";
import VBtnSave from "@/components/inputs/boutons/vBtnSave.vue";
import VOneSelect from "@/components/inputs/saisies/vOneSelect.vue";
import VBoxCheck from "@/components/inputs/saisies/vBoxCheck.vue";
import vBtnAdd from "@/components/inputs/boutons/vBtnAdd.vue";
import vMultipleSelect from "@/components/inputs/saisies/vMultipleSelect.vue";
import vAreaText from "@/components/inputs/saisies/vAreaText.vue";
import vDateInput from "@/components/inputs/saisies/vDateInput.vue";
import vTableSimple from "@/components/modules/vTableSimple.vue";
import VTableData from "@/components/modules/vTableData.vue";
import VPickerColor from "@/components/inputs/saisies/vPickerColor.vue";
/* MODAL CRUD */
import FormFournisseur from "@/components/fournisseurs/FormFournisseur.vue";
import FormSources from "@/components/sources/FormSources.vue";
import FormCategorie from "@/components/categories/FormCategorie.vue";
import FormReglement from "@/components/reglements/FormReglement.vue";
import FormTypeRdv from '@/components/rdv/FormTypeRdv.vue';
import FormAgence from '@/components/agence/FormAgence.vue';
import FormUser from '@/components/user/FormUser.vue';
import FormLien from "@/components/liens/FormLien.vue";
import FormCommission from "@/components/commission/FormCommission.vue";

Vue.component('btn-aide-contextuel', BtnAideContextuel)
Vue.component('NavBar', NavBar)
Vue.component('BottomBar', BottomBar)
Vue.component('vTextInput', vTextInput)
Vue.component('VOneSelect', VOneSelect)
Vue.component('VDateSimple', VDateSimple)
Vue.component('VLabelParagraph', VLabelParagraph)
Vue.component('CadreSignature', CadreSignature)
Vue.component('TableauProduit', TableauProduit)
Vue.component('VBtnEdit', VBtnEdit)
Vue.component('VBtnSave', VBtnSave)
Vue.component('vBtnAdd', vBtnAdd)
Vue.component('VBoxCheck', VBoxCheck)
Vue.component('vMultipleSelect', vMultipleSelect)
Vue.component('VAreaText', vAreaText)
Vue.component('vDateInput', vDateInput)
Vue.component('VTableData', VTableData)
Vue.component('vTableSimple', vTableSimple)
Vue.component('VPickerColor', VPickerColor)
    /* MODAL CRUD */
Vue.component('FormFournisseur', FormFournisseur)
Vue.component('FormSources', FormSources)
Vue.component('FormCategorie', FormCategorie)
Vue.component('FormReglement', FormReglement)
Vue.component('FormTypeRdv', FormTypeRdv)
Vue.component('FormAgence', FormAgence)
Vue.component('FormUser', FormUser)
Vue.component('FormLien', FormLien)
Vue.component('FormCommission', FormCommission)