<template>
  <div>
    <label :class="classLabel">{{ label }}</label>
    <p :class="classParagraph">{{ value }}</p>
  </div>
</template>

<script>
export default {
  props: ["value", "label", "classParagraph", "classLabel", "type", "field"],
};
</script>

<style>

</style>