/* eslint-disable */
import Vue from 'vue'
import axios from 'axios'
import router from "/src/router";
export default {
    namespaced: true,
    state: {
        categories: [],
        totalPaginate: 1,
    },
    mutations: {
        setCategories(state, datas) {
            state.categories = datas
        },
        storeCategorie(state, data) {
            state.categories.push(data)
        },
        updateCategorie(state, data) {
            var index = state.categories.findIndex(c => c.id === data.id);
            Vue.set(state.categories, index, data);
        },
        deleteCategorie(state, id) {
            var index = state.categories.findIndex(categorie => categorie.id == id)
            state.categories.splice(index, 1)
        },
        setTotalPaginate(state, data) {
            state.totalPaginate = data
        }
    },
    getters: {
        getCategories(state) {
            return state.categories
        },
        getCategorie: (state) => (id) => {
            return state.categories.find(categorie => categorie.id === id)
        },
        getTotalPaginate(state) {
            return state.totalPaginate
        }

    },
    actions: {
        setCategories({ commit }, page) {
            axios.get('categories/index?page=' + page).then((response) => {
                commit('setCategories', response.data.data)
                commit('setTotalPaginate', response.data.last_page)
            }).catch(function(error) {
                if (error.response.status === 401) {
                    setTimeout(() => {
                        router.push({
                            name: "Connexion"
                        });
                    }, 900);
                }
            });
        },
        updateCategorie({ commit }, data) {
            commit('updateCategorie', data)
        },
        storeCategorie({ commit }, data) {
            commit('storeCategorie', data)
        },
        deleteCategorie({commit}, id) {
            commit('deleteCategorie', id)
        }
    }
}