<template>
    <div>
        <div style="right: -10px">
            <vBtnAdd class="mb-2 mt-3 mr-5 ml-2" v-if="areYouEnoughCoins(40) || !showIsSubscriber()" :classItems="'bg-color-main text-white'" nom="Ajouter un point de vente" @openModal="openModal" table="crud"
                icon="mdi-plus"></vBtnAdd>
        </div>
        <div class="float-bottom">
            <VTableData :items="items" :loader="loader" @triggerRowTable="editItem" :headers="[
              {
                text: 'Libelle',
                align: 'left',
                sortable: true,
                value: 'libelle',
              },
              {
                text: 'Rue',
                align: 'left',
                sortable: true,
                value: 'rue',
              },
              {
                text: 'Ville',
                align: 'left',
                sortable: true,
                value: 'ville',
              },
              {
                text: 'Code postal',
                align: 'left',
                sortable: true,
                value: 'codePostal',
              },
              {
                text: 'Téléphone',
                align: 'left',
                sortable: true,
                value: 'telephone',
              },
              {
                text: 'Email',
                align: 'left',
                sortable: true,
                value: 'email',
              },

            ]" :hiddenFooter="true" :hiddenHeader="false" :total="total" @pageChange="pageChange"></VTableData>
            <vModalCrud @closeDialog="close" deleteStoreFunction="agences/deleteAgence" :items="items"
                :itemEdited="editedItem" :formTitle="formTitle" v-if="dialog" :typeCRUD="typeCRUD"
                :typeForm="'FormAgence'" type="agence"></vModalCrud>
        </div>
    </div>
</template>
    
<script>
import vBtnAdd from "@/components/inputs/boutons/vBtnAdd.vue";
import VTableData from "@/components/modules/vTableData.vue";
import vModalCrud from "@/components/modules/vModalCrud.vue";
import viewMixin from "@/mixins/viewMixin.js";
import variableGlobalMixin from "@/mixins/variableGlobalMixin.js"

export default {
    components: { VTableData, vModalCrud, vBtnAdd },
    mixins: [viewMixin, variableGlobalMixin],
    data() {
        return {
            loader: false,
            formTitle: "Créer un point de vente",
            editedIndex: -1,
            editedItem: {
                id: "",
                libelle: "",
                rue: "",
                ville: "",
                codePostal: "",
                telephone: "",
                email: ""
            },
            role: "",
            dialog: false,
            dialogDelete: false,
            valid: true,
            typeCRUD: "",
            requiredRules: [(v) => !!v || "Le champ est obligatoire"],
            page: 1,
        };
    },
    mounted() {
        this.setAgences()

    },
    computed: {
        items() {
            return this.$store.getters["agences/getAgences"];
        },
        total() {
            return this.$store.getters['agences/getTotalPaginate']
        }
    },
    methods: {
        setAgences() {
            this.$store.dispatch("agences/setAgences", this.page);
        },
        validateForm() {
            if (this.editedItem.libelle != "") {
                return true;
            } else {
                return false;
            }
        },
        returnTo() {
            this.$store.dispatch("agences/updateAgence", this.editedItem);
        },
        openModal() {
            this.typeCRUD = "create";
            this.$store.dispatch("formulaire/initForm", {
                form: "agences",
                listFields: {
                    id: "",
                    libelle: "",
                    rue: "",
                    ville: "",
                    codePostal: "",
                    telephone: "",
                    email: ""
                },
            });
            this.dialog = true;
            this.formTitle = "Créer un point de vente";
        },
        editItem(item) {
            this.typeCRUD = "edit";
            this.editedIndex = this.items.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.formTitle = "Editer le point de vente";
            this.dialog = true;
            this.$store.dispatch("formulaire/initForm", {
                form: "agences",
                listFields: {
                    id: this.editedItem.id,
                    libelle: this.editedItem.libelle,
                    rue: this.editedItem.rue,
                    ville: this.editedItem.ville,
                    codePostal: this.editedItem.codePostal,
                    telephone: this.editedItem.telephone,
                    email: this.editedItem.email,
                },
            });
        },
        closeDelete() {
            this.dialogDelete = false;
        },
        close() {
            this.dialog = false;
            this.editedItem = {
                id: "",
                libelle: "",
            };
            this.editedIndex = -1;
        },
        deleteItem() {
            this.dialogDelete = true;
        },
        deleteItemConfirm() {
            this.items.splice(this.editedItem, 1);
            this.$axios
                .delete("agences/delete/" + this.editedItem.id)
                .then((response) => {
                    this.$swal("Bravo !", response.data.message, "success");
                    this.closeDelete();
                    this.close();
                });
        },
        pageChange(page) {
            this.page = page
            this.setAgences()
        },
    },
};
</script>
    
<style>

</style>