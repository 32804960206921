<template>
  <div>
    <div style="right: -10px">
      <vBtnAdd class="mb-2 mt-3 mr-5 ml-2" :classItems="'bg-color-main text-white'" nom="Ajouter une famille de produit" @openModal="openModal" table="crud"
        icon="mdi-plus"></vBtnAdd>
    </div>
    <div class="float-bottom">
      <VTableData :items="items" :loader="loader" @triggerRowTable="editItem" :headers="[
        {
          text: 'Libelle',
          align: 'left',
          sortable: true,
          value: 'libelle',
        },
      ]" :hiddenFooter="true" :hiddenHeader="false" :total="total" @pageChange="pageChange"></VTableData>
      <vModalCrud @closeDialog="close" deleteStoreFunction="categories/deleteCategorie" :items="items"
        :itemEdited="editedItem" :formTitle="formTitle" v-if="dialog" :typeCRUD="typeCRUD" :typeForm="'FormCategorie'"
        type="categorie"></vModalCrud>
    </div>
  </div>
</template>

<script>
import vBtnAdd from "@/components/inputs/boutons/vBtnAdd.vue";
import VTableData from "@/components/modules/vTableData.vue";
import vModalCrud from "@/components/modules/vModalCrud.vue";
import viewMixin from "@/mixins/viewMixin.js";

export default {
  components: { VTableData, vModalCrud, vBtnAdd },
  mixins: [viewMixin],
  data() {
    return {
      loader: false,
      page:1,
      formTitle: "Créer une famille de produit",
      editedIndex: -1,
      editedItem: {
        id: "",
        libelle: "",
      },
      defaultItem: {
        id: "",
        libelle: "",
      },
      role: "",
      dialog: false,
      dialogDelete: false,
      valid: true,
      typeCRUD: "",
      requiredRules: [(v) => !!v || "Le champ est obligatoire"],
    };
  },
  mounted() {
    this.setCategorie()
  },
  computed: {
    items() {
      return this.$store.getters["categories/getCategories"];
    },
    total() {
      return this.$store.getters["categories/getTotalPaginate"];
    }
  },
  methods: {
    setCategorie() {
      this.$store.dispatch("categories/setCategories", this.page);
    },
    validateForm() {
      if (this.editedItem.libelle != "") {
        return true;
      } else {
        return false;
      }
    },
    pageChange(page) {
      this.page = page
      this.setCategorie()
    },
    returnTo() {
      this.$store.dispatch("categories/updateCategorie", this.editedItem);
    },
    openModal() {
      this.typeCRUD = "create";
      this.$store.dispatch("formulaire/initForm", {
        form: "categories",
        listFields: {
          id: "",
          libelle: "",
        },
      });
      this.dialog = true;
      this.formTitle = "Créer une famille de produit";
    },
    editItem(item) {
      this.typeCRUD = "edit";
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.formTitle = "Editer la famille de produit";
      this.dialog = true;
      this.$store.dispatch("formulaire/initForm", {
        form: "categories",
        listFields: {
          id: this.editedItem.id,
          libelle: this.editedItem.libelle,
        },
      });
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    close() {
      this.dialog = false;
      this.editedItem = {
        id: "",
        libelle: "",
      };
      this.editedIndex = -1;
    },
    deleteItem() {
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.items.splice(this.editedItem, 1);
      this.$axios
        .delete("categories/delete/" + this.editedItem.id)
        .then((response) => {
          this.$swal("Bravo !", response.data.message, "success");
          this.closeDelete();
          this.close();
        });
    },
  },
};
</script>

<style>

</style>