var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticStyle:{"right":"-10px"}},[(_vm.areYouEnoughCoins(40) || !_vm.showIsSubscriber())?_c('vBtnAdd',{staticClass:"mb-2 mt-3 mr-5 ml-2",attrs:{"classItems":'bg-color-main text-white',"nom":"Ajouter un point de vente","table":"crud","icon":"mdi-plus"},on:{"openModal":_vm.openModal}}):_vm._e()],1),_c('div',{staticClass:"float-bottom"},[_c('VTableData',{attrs:{"items":_vm.items,"loader":_vm.loader,"headers":[
          {
            text: 'Libelle',
            align: 'left',
            sortable: true,
            value: 'libelle',
          },
          {
            text: 'Rue',
            align: 'left',
            sortable: true,
            value: 'rue',
          },
          {
            text: 'Ville',
            align: 'left',
            sortable: true,
            value: 'ville',
          },
          {
            text: 'Code postal',
            align: 'left',
            sortable: true,
            value: 'codePostal',
          },
          {
            text: 'Téléphone',
            align: 'left',
            sortable: true,
            value: 'telephone',
          },
          {
            text: 'Email',
            align: 'left',
            sortable: true,
            value: 'email',
          },

        ],"hiddenFooter":true,"hiddenHeader":false,"total":_vm.total},on:{"triggerRowTable":_vm.editItem,"pageChange":_vm.pageChange}}),(_vm.dialog)?_c('vModalCrud',{attrs:{"deleteStoreFunction":"agences/deleteAgence","items":_vm.items,"itemEdited":_vm.editedItem,"formTitle":_vm.formTitle,"typeCRUD":_vm.typeCRUD,"typeForm":'FormAgence',"type":"agence"},on:{"closeDialog":_vm.close}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }