<template>
  <div>
    <div style="right: -10px">
      <vBtnAdd class="mb-2 mt-3 mr-5 ml-2" :classItems="'bg-color-main text-white'" nom="Ajouter une origine de contact" @openModal="openModal" table="crud"
        icon="mdi-plus"></vBtnAdd>
    </div>
    <div class="float-bottom">
      <VTableData :items="items" :loader="loader" @triggerRowTable="editItem" :headers="[
        {
          text: 'Libelle',
          align: 'left',
          sortable: true,
          value: 'libelle',
        },
      ]" :hiddenFooter="true" :hiddenHeader="false" :total="total" @pageChange="pageChange"></VTableData>
      <vModalCrud @closeDialog="close" deleteStoreFunction="sources/deleteSource" :items="items"
        :itemEdited="editedItem" :formTitle="formTitle" v-if="dialog" :typeCRUD="typeCRUD" :typeForm="'FormSources'"
        type="source"></vModalCrud>
    </div>
  </div>
</template>
  
<script>
import vBtnAdd from "@/components/inputs/boutons/vBtnAdd.vue";
import VTableData from "@/components/modules/vTableData.vue";
import vModalCrud from "@/components/modules/vModalCrud.vue";
import viewMixin from "@/mixins/viewMixin.js";
import { bus } from "@/main";

export default {
  components: { VTableData, vModalCrud, vBtnAdd },
  mixins: [viewMixin],
  data() {
    return {
      loader: false,
      formTitle: "Créer une origine de contact",
      editedIndex: -1,
      editedItem: {
        id: "",
        libelle: "",
      },
      defaultItem: {
        id: "",
        libelle: "",
      },
      role: "",
      dialog: false,
      dialogDelete: false,
      valid: true,
      typeCRUD: "",
      page:1,
      requiredRules: [(v) => !!v || "Le champ est obligatoire"],
    };
  },
  created() {
    var payload = {
      table: "contact",
      icon: "mdi-plus",
      onlyIcon: true,
      classItems: "bg-primary text-white",
      typeComponent: "vBtnAdd",
      routeDisplay: "Mes contacts",
    };
    bus.$emit("changeButtonAction", payload);
  },
  mounted() {
    this.setSources()
  },
  computed: {
    items() {
      return this.$store.getters["sources/getSources"];
    },
    total() {
      return this.$store.getters["sources/getTotalPaginate"];
    }
  },
  methods: {
    setSources() {
      this.$store.dispatch("sources/setSources", this.page);
    },  
    pageChange(page) {
      this.page = page
      this.setSources()
    },
    validateForm() {
      if (this.editedItem.libelle != "") {
        return true;
      } else {
        return false;
      }
    },
    returnTo() {
      this.$store.dispatch("sources/updateSource", this.editedItem);
    },
    openModal() {
      this.typeCRUD = "create";
      this.$store.dispatch("formulaire/initForm", {
        form: "sources",
        listFields: {
          id: "",
          libelle: "",
        },
      });
      this.dialog = true;
      this.formTitle = "Créer une source";
    },
    editItem(item) {
      this.typeCRUD = "edit";
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.formTitle = "Editer la source";
      this.dialog = true;
      this.$store.dispatch("formulaire/initForm", {
        form: "sources",
        listFields: {
          id: this.editedItem.id,
          libelle: this.editedItem.libelle,
        },
      });
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    close() {
      this.dialog = false;
      this.editedItem = {
        id: "",
        libelle: "",
      };
      this.editedIndex = -1;
    },
    deleteItem() {
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.items.splice(this.editedItem, 1);
      this.$axios
        .delete("sources/delete/" + this.editedItem.id)
        .then((response) => {
          this.$swal("Bravo !", response.data.message, "success");
          this.closeDelete();
          this.close();
        });
    },
  },
};
</script>
  
<style>

</style>