<template>
    <div>
        <v-btn class="text-white float-right mt-5 m-3" style="background-color: red" rounded
            v-if="showManagerThing && produit.id" @click="deleteItem(produit)">
            <v-icon> mdi-delete </v-icon>
        </v-btn>
        <FormProduit :receivedProduit="produit" :typeCRUD="typeCRUD" :idVente="idVente" :typeVente="typeVente"
            :fromScenario="fromScenario">
        </FormProduit>
    </div>
</template>
  
<script>
import FormProduit from '@/components/produits/FormProduit.vue';
import viewMixin from "@/mixins/viewMixin.js";
export default {
    mixins: [viewMixin],
    components: { FormProduit },
    props: ['typeCRUD', 'receivedProduit', 'fromScenario', 'idVente', "typeVente", "idProduit"],
    data() {
        return {
            produit: []
        }
    },
    created() {
        this.getProduit()
    },
    methods: {
        getProduit() {

            var payload = {
                id: this.idProduit
            }

            this.$axios.post('produits/read', payload).then((response) => {
                var array = response.data
                this.produit = array

            })
        },
        deleteItem() {
            this.$swal({
                title: "Voulez-vous supprimer cet élément ?",
                showDenyButton: true,
                type: "danger",
                showCancelButton: false,
                confirmButtonText: "Oui",
                denyButtonText: `Non`,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch("produits/deleteProduit", this.produit.id)
                    this.$axios
                        .delete("produits/delete/" + this.produit.id)
                        .then((response) => {
                            this.$swal("Bravo !", response.data.message, "success");
                            this.$router.push({ name: "Mes produits" })
                        });
                }
            });
        }
    }
}
</script>
  
<style>

</style>