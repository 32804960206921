<template>
  <div>
    <h3 class="text-center text-white mt-1 mb-3 p-3" style="background-color: #0804fc">
      Liste des factures
    </h3>

    <VTableData :items="items" :loader="loader" @triggerRowTable="editItem" :headers="[
      {
        text: 'Titre',
        align: 'left',
        sortable: true,
        value: 'libelle',
      },
      {
        text: 'Montant TTC',
        align: 'left',
        sortable: true,
        value: 'montant_ttc',
      },
      { text: 'Date', value: 'date_facture' },
      { text: 'Etat du facture', value: 'etat_document_libelle' },
    ]" :hiddenFooter="true" :hiddenHeader="false" :total="total" @pageChange="pageChange" type="facture">
    </VTableData>
  </div>
</template>
  
<script>
import VTableData from "../../modules/vTableData.vue";
export default {
  props: ['idContact'],
  components: {
    VTableData,
  },
  data() {
    return {
      items: [],
      total: 1,
      current_page: 1,
      loader: true,
    }
  },
  computed: {
    factures() {
      return this.$store.getters['facture/getFacture']
    }
  },
  mounted() {
    this.setFacture()
  },
  methods: {
    setFacture(page = 1) {
      var payload = {
        idContact: (this.idContact) ? this.idContact : this.$route.params.idContact,
        page: page
      }
      this.$store.dispatch('facture/setFacture', payload).then((response) => {
        this.items = response.data.factures
        this.current_page = response.data.current_page
        this.total = response.data.total
        this.loader = false
      })
    },
    editItem(item) {
      this.$router.push({
        name: "Je regarde ma facture", params: {
          idContact: (this.idContact) ? this.idContact : this.$route.params.idContact,
          idVente: item.id,
          typeVente: "facture"
        }
      })
    },
    pageChange(page) {
      this.setFacture(page)
    }
  }
};
</script>
  
<style>

</style>