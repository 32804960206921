<template>
    <div class="container">
        <v-container fill-height fluid>
            <v-row align="center" justify="center">
                <v-col>
                    <h1 class="text-center p-3 padding-title">Mot de passe oublié</h1>

                    <v-form v-model="valid">
                        <v-text-field rounded filled prepend-inner-icon="mdi-email" name="login" :rules="emailRules"
                            v-model="form.email" label="Tapez votre adresse e-mail" type="text"
                            :error-messages="msgError"></v-text-field>
                    </v-form>

                    <v-spacer></v-spacer>
                    <div class="d-flex justify-content-end">
                        <v-btn class="bg-color-main text-white" rounded :disabled="!valid" :loading="loaderBtn"
                            @click="submit()">
                            <v-icon>mdi-send</v-icon>Envoyer
                        </v-btn>
                    </div>

                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
  
<script>
/* eslint-disable */
export default {
    data() {
        return {
            valid: false,
            msgError: "",
            loaderBtn: false,
            form: {
                email: "",
            },
            emailRules: [
                (v) => !!v || "Le champ doit être rempli",
                (v) => /.+@.+\..+/.test(v) || "L'adresse email est invalide",
            ],
        };
    },
    mounted() { },
    methods: {
        submit() {
            this.loaderBtn = true;
            this.$axios.post("/password/verify-email", this.form).then((response) => {
                if (response.data.status) {
                    this.$axios
                        .post("/password/send-email", this.form)
                        .then((response) => {
                            if (response.data.messageError) {
                                this.$swal(
                                    "Une erreur est survenu !",
                                    response.data.messageError.email[0],
                                    "error"
                                ).then(() => {
                                    this.loaderBtn = false;
                                });
                                return;
                            } else if (response.data.tooManyPasswordReset) {
                                this.$swal(
                                    "Une erreur est survenu !",
                                    response.data.tooManyPasswordReset,
                                    "error"
                                ).then(() => {
                                    this.loaderBtn = false;
                                });

                                return;
                            } else {
                                if (response.data == "passwords.sent") {
                                    this.loaderBtn = false;
                                    this.$swal(
                                        "Un email a été envoyé !",
                                        "Nous avons envoyer un email pour que réinitialiser votre mot de passe !",
                                        "success"
                                    ).then(() => {
                                        this.loaderBtn = false;
                                        this.$router.push({ name: "Connexion" });
                                    });
                                } else {
                                    this.$swal(
                                        "Une erreur est survenu !",
                                        "Nous n'avons pas pu envoyer le mail de réinitialisation, veuillez réessayer ultérieurement.",
                                        "error"
                                    ).then(() => {
                                        this.loaderBtn = false;
                                    });
                                }
                            }
                        }).catch((error) => {
                            this.loaderBtn = false;
                        })
                } else {
                    this.$swal(
                        "Oups !",
                        "L'adresse e-mail que vous avez saisie n'existe pas !",
                        "error"
                    );
                    this.loaderBtn = false;
                }
            });
        },
        returnBack() {
            this.$router.go(-1);
        },
    },
};
</script>
  
<style>

</style>
  