<template>
  <div>
    <v-btn
      rounded
      class="bg-color-main text-white"
      v-if="this.onlyIcon"
      @click="edit()"
      ><v-icon>{{ this.icon }}</v-icon></v-btn
    >
    <v-btn
      :disabled="!valid"
      @click="edit()"
      rounded
      class="bg-color-main text-white"
      v-else
      ><v-icon>{{ this.icon }}</v-icon
      >{{ this.nom }}</v-btn
    >
  </div>
</template>

<script>
export default {
  props: ["id", "table", "nom", "icon", "onlyIcon", "valid"],
  name: "Button",
  data() {
    return {
      payload: [],
      displayMessage: [],
      isDisplay: false,
    };
  },

  mounted() {},
  methods: {
    edit() {
      var payload = {
        id: this.id,
        table: this.table,
      };
      this.$store
        .dispatch("formulaire/updateData", payload)
        .then((response) => {
          if (response.data.messageError) {
            this.$swal({
              toast: true,
              title: response.data.messageError,
              icon: "error",
              animation: true,
              position: "top-right",
              showConfirmButton: false,
              timerProgressBar: true,
              timer: 2000,
            });
            return;
          }
          this.$swal({
            toast: true,
            title: response.data.message,
            icon: "success",
            animation: true,
            position: "top-right",
            showConfirmButton: false,
            timerProgressBar: true,
            timer: 2000,
          });
          this.$emit("saveForm", response.data.element);
          switch (this.table) {
            case 'events':
              this.verifObjectif();
              break;
            default:
              break;
          }
        });
    },
  },
};
</script>

<style>
</style>