<template>
    <div>
      <div style="right: -10px">
        <vBtnAdd class="mb-2 mt-3 mr-5 ml-2" :classItems="'bg-color-main text-white'" nom="Ajouter un type de commission"
          @openModal="openModal" table="crud" icon="mdi-plus"></vBtnAdd>
      </div>
      <div class="float-bottom">
        <VTableData :items="items" :loader="loader" @triggerRowTable="editItem" :headers="[
          {
            text: 'Nom',
            align: 'left',
            sortable: true,
            value: 'libelle',
          },
          {
            text: 'Type de commission identifié comme celui de la societe',
            align: 'left',
            sortable: true,
            value: 'isSociete',
          }
        ]" :hiddenFooter="true" :hiddenHeader="false" :total="total" @pageChange="pageChange"></VTableData>
        <vModalCrud @closeDialog="close" deleteStoreFunction="commission/deleteCommission" :items="items"
          :itemEdited="editedItem" :formTitle="formTitle" v-if="dialog" :typeCRUD="typeCRUD" :typeForm="'FormCommission'"
          type="type_commission"></vModalCrud>
      </div>
    </div>
  </template>
    
  <script>
  import vBtnAdd from "@/components/inputs/boutons/vBtnAdd.vue";
  import VTableData from "@/components/modules/vTableData.vue";
  import vModalCrud from "@/components/modules/vModalCrud.vue";
  import viewMixin from "@/mixins/viewMixin.js";
  
  export default {
    components: { VTableData, vModalCrud, vBtnAdd },
    mixins: [viewMixin],
    data() {
      return {
        loader: false,
        formTitle: "Créer un type de commission",
        editedIndex: -1,
        editedItem: {
          id: "",
          libelle: "",
        },
        page: 1,
        role: "",
        dialog: false,
        dialogDelete: false,
        valid: true,
        typeCRUD: "",
        requiredRules: [(v) => !!v || "Le champ est obligatoire"],
      };
    },
    mounted() {
      this.setCommissions()
    },
    computed: {
      items() {
        return this.$store.getters["commission/getCommissions"];
      },
      total() {
        return this.$store.getters['commission/getTotalPaginate']
      }
    },
    methods: {
      setCommissions() {
        this.$store.dispatch("commission/setCommissions", this.page);
      },
      pageChange(page) {
        this.page = page
        this.setCommissions()
      },
      validateForm() {
        if (this.editedItem.libelle != "") {
          return true;
        } else {
          return false;
        }
      },
      returnTo() {
        this.$store.dispatch("commission/updateCommission", this.editedItem);
      },
      openModal() {
        this.typeCRUD = "create";
        this.$store.dispatch("formulaire/initForm", {
          form: "type_commissions",
          listFields: {
            id: "",
            libelle: "",
          },
        });
        this.dialog = true;
        this.formTitle = "Créer un type de commission";
      },
      editItem(item) {
        this.typeCRUD = "edit";
        this.editedIndex = this.items.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.formTitle = "Editer le type de règlement";
        this.dialog = true;
        this.$store.dispatch("formulaire/initForm", {
          form: "type_commissions",
          listFields: {
            id: this.editedItem.id,
            libelle: this.editedItem.libelle,
          },
        });
      },
      closeDelete() {
        this.dialogDelete = false;
      },
      close() {
        this.dialog = false;
        this.editedItem = {
          id: "",
          libelle: "",
        };
        this.editedIndex = -1;
      },
      deleteItem() {
        this.dialogDelete = true;
      },
      deleteItemConfirm() {
        this.items.splice(this.editedItem, 1);
        this.$axios
          .delete("type_commissions/delete/" + this.editedItem.id)
          .then((response) => {
            this.$swal("Bravo !", response.data.message, "success");
            this.closeDelete();
            this.close();
          });
      },
    },
  };
  </script>
    
  <style>
  
  </style>