/* eslint-disable */
import axios from 'axios'
import Vue from 'vue'
import router from "/src/router";

export default {
    namespaced: true,
    state: {
        entreprise: [],
        load: false,
    },
    mutations: {
        SET_ENTREPRISE(state, data) {
            state.entreprise = data
        },
        SET_DATALOAD(state, data) {
            state.load = data
        },
    },
    getters: {
        getEntreprise(state) {
            return state.entreprise
        },
        dataLoad(state) {
            return state.load
        }
    },
    actions: {
        setEntreprise({ commit }, payload) {
            commit('SET_ENTREPRISE', payload)
        },
        storeEntreprise({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios.post("/register/entreprise/store", payload.form).then((response) => {
                    commit("SET_ENTREPRISE", response.data.entreprise)
                    commit('SET_DATALOAD', true)
                    resolve(response)
                });
            })
        },
        updateEntreprise({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios.put("/register/entreprise/registerUpdate/" + payload.id,
                        payload.form
                    )
                    .then((response) => {
                        commit("SET_ENTREPRISE", response.data.entreprise)
                        commit('SET_DATALOAD', true)
                        resolve(response)

                    });
            })
        },
    }
}