<template>
  <div>
    <v-card>
      <v-card-text>
        <v-container>
          <v-form ref="form" class="container" v-model="valid" lazy-validation>
            <v-row>
              <input type="hidden" v-model="eventSelected.id" />
              <v-col cols="12">
                <VBoxCheck libelle="Raison d'annulation" :value="eventSelected.annuler" clef="annuler"
                  id="event-annuler" label="Annuler le rendez-vous" rulesInfo="required" type="events"
                  icon="mdi-close-outline"></VBoxCheck>
                <vTextInput v-if="eventSelected.annuler" libelle="Raison d'annulation"
                  :value="eventSelected.raisonAnnulation" clef="raisonAnnulation" id="event-raison-annuler"
                  :rules="[(v) => !!v || 'Le champ est obligatoire']" placeholder="Le client s'est désister"
                  rulesInfo="required" type="events" icon="mdi-close-outline"></vTextInput>

                <vTextInput libelle="Titre de l'évenement" :value="eventSelected.name" clef="name" id="event-name"
                  :rules="[(v) => !!v || 'Le champ est obligatoire']" placeholder="RDV avec monsieur ..."
                  rulesInfo="required" type="events" icon="mdi-calendar"></vTextInput>

                <VOneSelect icon="mdi-calendar-question" libelle="Type de rendez-vous" :datas="optionsSelectTypeRdv"
                  clef="typerdv_id" itemText="libelle" itemValue="id" rulesInfo="required" type="events"
                  :value="eventSelected.typerdv_id" :rules="[(v) => !!v || 'Le champ est obligatoire']"></VOneSelect>
              </v-col>
              <hr />
              <h2>Contact</h2>
              <v-btn rounded v-if="!eventSelected.contact_id" class="mb-5 p-1" @click="openDialogContact()">
                Ajouter un contact à ce rendez-vous
              </v-btn>
              <p v-if="!eventSelected.contact_id">Aucun contact</p>

              <h3 v-if="eventSelected.contact_id">
                <v-icon @click="eventSelected.contact_id = ''" class="text-danger">mdi-close</v-icon> {{
                    eventSelected.contact
                }}
              </h3>
              <p v-if="eventSelected.contact_id">Vous pouvez supprimer ce contact pour en ajouter un autre</p>
              <hr />
              <v-col cols="12">
                <vDateInput icon="mdi-calendar-week" libelle="Début du RDV" clef="start" rulesInfo="required"
                  reference="startEvent" type="events" :value="eventSelected.start"
                  :rules="[(v) => !!v || 'Le champ est obligatoire']"></vDateInput>
                <vDateInput icon="mdi-calendar-week" libelle="Fin du RDV" clef="end" rulesInfo="required"
                  reference="endEvent" type="events" :value="eventSelected.end"
                  :rules="[(v) => !!v || 'Le champ est obligatoire']"></vDateInput>
                <vTextInput libelle="Adresse de l'évènement" :value="eventSelected.adresse" clef="adresse"
                  id="event-adresse" :rules="[(v) => !!v || 'Le champ est obligatoire']"
                  placeholder="Rue de la république ..." rulesInfo="required" type="events" icon="mdi-map-marker">
                </vTextInput>

                <vAreaText libelle="Commentaires" :value="eventSelected.commentaires" clef="commentaires"
                  id="event-commentaires" :rules="[(v) => !!v || 'Le champ est obligatoire']"
                  placeholder="Le client possède deux chiens ..." type="events" icon="mdi-card-text"></vAreaText>

                <div v-if="showManagerThing">
                  <vMultipleSelect icon="mdi-account-group" libelle="Selectionner un ou des commerciaux"
                    :datas="optionsSelectUsers" clef="user" itemText="nomPrenom" itemValue="id" rulesInfo="required"
                    type="events" :value="[eventSelected.user]" :rules="[(v) => !!v || 'Le champ est obligatoire']">
                  </vMultipleSelect>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn rounded class="m-1" text @click="$router.go(-1)"> Retour </v-btn>
        <vBtnEdit nom="Sauvegarder" :valid="valid" icon="mdi-content-save" @saveForm="returnTo" :onlyIcon="false"
          :id="eventSelected.id" table="events"></vBtnEdit>
        <!--   <v-btn
          class="m-1 bg-color-main"
          rounded
          v-if="validateForm()"
          @click="saveEvent()"
          :disabled="!valid"
        >
          Sauvegarder
        </v-btn> -->
      </v-card-actions>
    </v-card>
    <v-dialog v-model="dialogContact">
      <v-card>
        <v-toolbar class="bg-color-main text-white">
          <v-icon class="text-white" @click="dialogContact = false">mdi-close</v-icon>
          <v-spacer></v-spacer>
          <v-toolbar-title>Contact</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-container>
          <div>
            <v-item-group>
              <v-container>
                <v-row>
                  <v-col cols="12" md="4" lg="6">
                    <v-item v-slot="{ active, toggle }">
                      <v-card :color="active ? '#5A189A' : ''" class="align-center p-3 d-flex justify-content-center"
                        :elevation="11" height="200" :dark="!isDarkMode"
                        @click="toggle && redirectTo('Je crée un contact')">
                        <h1 class="text-break">
                          Créer un nouveau contact ?
                        </h1>
                      </v-card>
                    </v-item>
                  </v-col>
                  <v-col cols="12" md="4" lg="6">
                    <v-item v-slot="{ active, toggle }">
                      <v-card :color="active ? '#5A189A' : ''" :dark="!isDarkMode" :elevation="11"
                        class="align-center p-3 text-center d-flex justify-content-center" height="200"
                        @click="toggle && openModalSearchContact()">
                        <h1 class="text-break">
                          Ajouter contact existant ?
                        </h1>
                      </v-card>
                    </v-item>
                  </v-col>
                </v-row>
              </v-container>
            </v-item-group>
          </div>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogSearchContact" width="500">
      <v-card>
        <v-toolbar>
          <v-icon @click="dialogSearchContact = false">mdi-close</v-icon>
        </v-toolbar>
        <v-card-title class="text-h5">
          Rechercher un contact
        </v-card-title>
        <v-card-text>
          <v-text-field filled rounded v-model="search" label="Rechercher un contact" prepend-inner-icon="mdi-magnify">
          </v-text-field>
          <v-list rounded>
            <v-subheader>Contacts</v-subheader>
            <v-list-item-group color="primary">
              <v-list-item @click="selectContact(contact)" v-for="contact in itemsContact" :key="contact.id">
                <v-list-item-content>
                  <v-list-item-title> {{ contact.prenom }} {{ contact.nom }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
/* eslint-disable */
export default {
  props: ["eventSelected"],
  data() {
    return {
      dialogContact: false,
      menuEnd: false,
      menuStart: false,
      valid: true,
      requiredRules: [(v) => !!v || "Le champ est obligatoire"],
      numberRules: [
        (v) => !!v || "Le champ est obligatoire",
        (v) => !isNaN(v) || "Ce n'est pas un nombre",
      ],
      emailRules: [
        (v) => !!v || "Le champ doit être remplis",
        (v) => /.+@.+\..+/.test(v) || "L'adresse email est invalide",
      ],
      itemsContact: [],
      dialogSearchContact: false,
      search: "",
      optionsSelectTypeRdv: [],
      optionsSelectSources: [],
      optionsSelectUsers: [],
      optionsSelectContact: [],
    };
  },
  mounted() {
    this.getSelectOptions();
  },
  watch: {
    search(val) {
      if (val.length > 2) {
        this.$axios
          .get("param/searchContact/" + val)
          .then((res) => {
            this.itemsContact = res.data;
          })
          .catch((err) => { });
      } else if (val.length < 2) {
        this.itemsContact = [];
        this.findName = true;
      }
    },
  },
  methods: {
    validateForm() {
      if (
        this.eventSelected.libelle != "" &&
        this.eventSelected.typerdv_id != "" &&
        this.eventSelected.start != null &&
        this.eventSelected.end != null
      ) {
        return true;
      } else {
        return false;
      }
    },
    redirectTo(path) {
      var array = []
      array['returnMode'] = "calendar"
      array['idEvent'] = this.eventSelected.id
      this.$router.push({
        name: path, params: {
          paramEvent: array
        }
      });
    },
    returnTo() {
      this.$router.go(-1);
    },
    selectContact(item) {
      this.eventSelected.contact = item.prenom + ' ' + item.nom
      this.eventSelected.contact_id = item.id
      this.eventSelected.adresse =
        item.rue + " " + item.ville + " " + item.codePostal;
      this.$store.dispatch("formulaire/updateForm", {
        form: "events",
        field: "contact_id",
        value: item.id,
      });
      this.$store.dispatch("formulaire/updateForm", {
        form: "events",
        field: "adresse",
        value: this.eventSelected.adresse,
      });
      this.dialogSearchContact = false;
      this.dialogContact = false
    },
    openDialogContact() {
      this.dialogContact = true;
    },
    getSelectOptions() {
      this.$store.dispatch("events/optionsEvents");
      this.selectUserLoading = true;
      this.optionsSelectContact =
        this.$store.getters["events/getOptions"]["contacts"];
      this.optionsSelectUsers =
        this.$store.getters["events/getOptions"]["users"];
      this.optionsSelectTypeRdv =
        this.$store.getters["events/getOptions"]["typeRdvs"];
      this.optionsSelectSources =
        this.$store.getters["events/getOptions"]["sources"];
    },
    openModalSearchContact() {
      this.dialogSearchContact = true
    },
    close() {
      this.$emit("closeDialog");
    },
  },
};
</script>

<style>
</style>