/* eslint-disable */
import Vue from "vue";
import store from "@/store/index.js";
import Router from "vue-router";
import Accueil from "@/views/Accueil.vue";
import Contacts from "@/views/Contacts.vue";
import Login from "@/views/auth/Login.vue"
import FolderContact from "@/views/contacts/FolderContact.vue"
import CreateContact from "@/views/contacts/CreateContact.vue"
import EditContact from "@/views/contacts/EditContact.vue";
import Rdv from "@/views/rdv/Rdv.vue";
import SaisieRdv from "@/views/rdv/SaisieRdv"
import CreateDevis from "@/views/ventes/devis/CreateDevis.vue"
import CreateProduit from "@/views/produits/CreateProduit.vue"
import Categorie from "@/views/parametres/Categorie.vue"
import Fournisseur from "@/views/parametres/Fournisseur.vue"
import Source from "@/views/parametres/Source.vue"
import Reglement from "@/views/parametres/Reglement.vue"
import TypeRdv from "@/views/parametres/TypeRdv.vue"
import Produit from "@/views/parametres/Produit.vue"
import EditProduit from "@/views/produits/EditProduit.vue";
import Agence from "@/views/parametres/Agence.vue";
import MonEntreprise from "@/views/parametres/MonEntreprise.vue"
import User from "@/views/parametres/User.vue"
import Historique from "@/views/Historique.vue"
import FormVenteProduit from "@/views/ventes/FormVenteProduit.vue"
import FindProduit from "@/views/produits/FindProduit.vue"
import EditProduitVente from "@/views/produits/EditProduitVente.vue"
import EditDevis from "@/views/ventes/devis/EditDevis.vue"
import CreateNewDevis from "@/views/ventes/devis/CreateNewDevis.vue"
import EditCommande from "@/views/ventes/commandes/EditCommande.vue"
import CreateNewCommande from "@/views/ventes/commandes/CreateNewCommande.vue"
import CreateCommande from "@/views/ventes/commandes/CreateCommande.vue"
import ReadFacture from "@/views/ventes/factures/ReadFacture.vue"
import ReadDevis from "@/views/ventes/devis/ReadDevis.vue"
import ReadCommande from "@/views/ventes/commandes/ReadCommande.vue"
import Stats from "@/views/Stats.vue"
import Modele from "@/views/parametres/Modele.vue"
import Profil from "@/views/Profil.vue";
import Boutique from "@/views/Boutique.vue"
import NeedCoins from "@/views/boutique/NeedCoins.vue"
import PaymentSuccess from "@/views/boutique/PaymentSuccess.vue"
import PaymentFailed from "@/views/boutique/PaymentFailed.vue"
import ForgotMyPassword from "@/views/password/ForgotMyPassword.vue"
import ChangeMyPassword from "@/views/password/ChangeMyPassword.vue"
import Register from "@/views/auth/Register.vue"
import TypeCommission from "@/views/parametres/Commission.vue";
import VerifySuccess from "@/views/confirmAccount/VerifySuccess.vue";
import WaitVerifyEmail from "@/views/confirmAccount/WaitVerifyEmail";

Vue.use(Router)

const router = new Router({
    mode: "history",
    routes: [{
            path: "/",
            redirect: "/accueil"
        },
        {
            path: "/accueil",
            name: "Accueil",
            props: true,
            component: Accueil,
            meta: {
                requiresAuth: true
            },
        },
        {
            path: "/historiques",
            name: "Mon historique",
            props: true,
            component: Historique,
            meta: {
                requiresAuth: true
            },
        },
        {
            path: "/statistiques",
            name: "Mes statistiques",
            props: true,
            component: Stats,
            meta: {
                requiresAuth: true
            },
        },
        {
            path: "/boutique",
            name: "Boutique",
            props: true,
            component: Boutique,
            meta: {
                requiresAuth: true
            },
            beforeEnter: (to, from, next) => {
                setTimeout(() => {
                    if (store.getters['auth/role'] != "Manager") {
                        next(false);
                    } else {
                        next();
                    }
                }, 1000)


            }
        },
        {
            path: "/je_veux_des_coins",
            name: "Je veux des coins",
            props: true,
            component: NeedCoins,
            meta: {
                requiresAuth: true
            },
            beforeEnter: (to, from, next) => {
                setTimeout(() => {
                    if (store.getters['auth/role'] != "Manager") {
                        next(false);
                    } else {
                        next();
                    }
                }, 1000)


            }
        },
        {
            path: "/je_veux_des_coins/achat/paiement_reussi",
            name: "Le paiement a réussi",
            component: PaymentSuccess,
            meta: {
                requiresAuth: false
            },
            beforeEnter: (to, from, next) => {
                setTimeout(() => {
                    if (store.getters['auth/role'] != "Manager") {
                        next(false);
                    } else {
                        next();
                    }
                }, 1000)

            }
        },
        {
            path: "/je_veux_des_coins/achat/paiement_echoue",
            name: "Le paiement a échoué",
            component: PaymentFailed,
            meta: {
                requiresAuth: false
            },
            beforeEnter: (to, from, next) => {
                setTimeout(() => {
                    if (store.getters['auth/role'] != "Manager") {
                        next(false);
                    } else {
                        next();
                    }
                }, 1000)

            }
        },
        {
            path: "/mon_profil",
            name: "Mon profil",
            props: true,
            component: Profil,
            meta: {
                requiresAuth: true
            },
        },
        {
            path: "/mon_entreprise",
            name: "Mon entreprise",
            props: true,
            component: MonEntreprise,
            meta: {
                requiresAuth: true
            },
        },
        {
            path: '/verification/email/reussi',
            name: 'Compte validé',
            component: VerifySuccess,
            beforeEnter: (to, from, next) => {
                setTimeout(() => {
                    if (store.getters['auth/authenticated'] && store.getters["auth/isValidated"]) {
                        next("/accueil");
                    } else if (!store.getters['auth/authenticated']) {
                        next("/connexion");
                    } else {
                        next();
                    }
                }, 500)

            }

        },
        {
            path: '/verification/email/en_attente',
            name: 'Compte en attente de validation',
            component: WaitVerifyEmail,
            beforeEnter: (to, from, next) => {
                setTimeout(() => {
                    if (store.getters['auth/authenticated'] && store.getters["auth/isValidated"]) {
                        next("/accueil");
                    } else if (!store.getters['auth/authenticated']) {
                        next("/connexion");
                    } else {
                        next();
                    }
                }, 500)

            }
        },
        {
            path: "/utilisateurs",
            name: "Mes utilisateurs",
            props: true,
            component: User,
            meta: {
                requiresAuth: true
            },
        },
        {
            path: "/mes_contacts",
            name: "Mes contacts",
            props: true,
            component: Contacts,
            meta: {
                requiresAuth: true
            },
        }, {
            path: "/dossier_contact/:idContact",
            name: "Dossier du contact",
            component: FolderContact,
            props: true,
            meta: {
                requiresAuth: true
            },
        },
        {
            path: "/creer_un_contact",
            props: true,
            name: "Je crée un contact",
            component: CreateContact,
            meta: {
                requiresAuth: true
            },
        },
        {
            path: "/creer_un_devis",
            props: true,
            name: "Je fais un nouveau devis",
            component: CreateNewDevis,
            meta: {
                requiresAuth: true
            },
        },
        {
            path: "/creer_une_commande",
            props: true,
            name: "Je fais une nouvelle commande",
            component: CreateNewCommande,
            meta: {
                requiresAuth: true
            },
        },
        {
            path: "/dossier_contact/:idContact/creer_un_devis",
            props: true,
            name: "Je fais un devis",
            component: CreateDevis,
            meta: {
                requiresAuth: true
            },
        },
        {
            path: "/dossier_contact/:idContact/:typeVente/:idVente/editer_un_devis",
            props: true,
            name: "J'edite un devis",
            component: EditDevis,
            meta: {
                requiresAuth: true
            },
        },
        {
            path: "/dossier_contact/:idContact/creer_une_commande",
            props: true,
            name: "Je crée une commande",
            component: CreateCommande,
            meta: {
                requiresAuth: true
            },
        },
        {
            path: "/dossier_contact/:idContact/:typeVente/consulter/:idVente",
            props: true,
            name: "Je regarde ma facture",
            component: ReadFacture,
            meta: {
                requiresAuth: true
            },
        },
        {
            path: "/dossier_contact/:idContact/:typeVente/consulter/:idVente",
            props: true,
            name: "Je regarde mon devis",
            component: ReadDevis,
            meta: {
                requiresAuth: true
            },
        },
        {
            path: "/dossier_contact/:idContact/:typeVente/consulter/:idVente",
            props: true,
            name: "Je regarde ma commande",
            component: ReadCommande,
            meta: {
                requiresAuth: true
            },
        },
        {
            path: "/dossier_contact/:idContact/:typeVente/:idVente/editer_une_commande",
            props: true,
            name: "J'edite une commande",
            component: EditCommande,
            meta: {
                requiresAuth: true
            },
        },
        {
            path: "/dossier_contact/:idContact/:typeVente/:idVente/ajouter_produits",
            props: true,
            name: "J'ajoute mes produits",
            component: FormVenteProduit,
            meta: {
                requiresAuth: true
            },
        },
        {
            path: "/dossier_contact/:idContact/:typeVente/:idVente/chercher_produits",
            props: true,
            name: "Je cherche mes produits",
            component: FindProduit,
            meta: {
                requiresAuth: true
            },
        },
        {
            path: "/editer_un_contact/:idContact",
            props: true,
            name: "J'edite un contact",
            component: EditContact,
            meta: {
                requiresAuth: true
            },
        },
        {
            path: "/creer_un_produit",
            name: "Je crée un produit",
            component: CreateProduit,
            props: true,
            meta: {
                requiresAuth: true
            },
        },
        {
            path: "/editer_le_produit/:idProduit",
            name: "J'edite le produit",
            component: EditProduit,
            props: true,
            meta: {
                requiresAuth: true
            },
        },
        {
            path: "/dossier_contact/:idContact/:typeVente/:idVente/editer_le_produit/:idProduit",
            name: "J'edite le produit de ma vente",
            component: EditProduitVente,
            props: true,
            meta: {
                requiresAuth: true
            },
        },
        {
            path: "/mes_rendez_vous",
            name: "Mes rendez-vous",
            component: Rdv,
            meta: {
                requiresAuth: true
            },
        },
        {
            path: "/saisir_un_rendez_vous/:id",
            props: true,
            name: "Saisir un rendez-vous",
            component: SaisieRdv,
            meta: {
                requiresAuth: true
            },
        },
        {
            path: "/famille_de_produits",
            name: "Mes familles de produits",
            component: Categorie,
            meta: {
                requiresAuth: true
            },
        },
        {
            path: "/points_de_vente",
            name: "Mes points de vente",
            component: Agence,
            meta: {
                requiresAuth: true
            },
        },
        {
            path: "/produits",
            name: "Mes produits",
            component: Produit,
            meta: {
                requiresAuth: true
            },
        },
        {
            path: "/fournisseurs",
            name: "Mes fournisseurs",
            component: Fournisseur,
            meta: {
                requiresAuth: true
            },
        },
        {
            path: "/modeles",
            name: "Mes modèles",
            component: Modele,
            meta: {
                requiresAuth: true
            },
        }, {
            path: "/origine_de_contact",
            name: "Mes origines de contact",
            component: Source,
            meta: {
                requiresAuth: true
            },
        },
        {
            path: "/reglements",
            name: "Mes type de règlements",
            component: Reglement,
            meta: {
                requiresAuth: true
            },
        }, {
            path: "/type_de_commission",
            name: "Mes type de commission",
            component: TypeCommission,
            meta: {
                requiresAuth: true
            },

        }, {
            path: "/type_rdv",
            name: "Mes types de rendez-vous",
            component: TypeRdv,
            meta: {
                requiresAuth: true
            },
        },
        {
            path: "/connexion",
            name: "Connexion",
            component: Login,
        },
        {
            path: "/je_creer_mon_compte_entreprise",
            name: "Je crée mon compte d'entreprise",
            component: Register,
        },
        {
            path: "/mot_de_passe_oublie",
            name: "J'ai oublié mon mot de passe",
            component: ForgotMyPassword,
        },
        {
            path: '/reinitialisation_de_votre_mot_de_passe/*',
            name: 'Je change mon mot de passe',
            component: ChangeMyPassword,
        },
    ],
})
router.beforeEach((to, from, next) => {
    try {
        if (to.meta.requiresAuth) {
            if (store.getters["auth/authenticated"]) {
                var refreshIntervalId = setInterval(() => {
                    if (store.getters["auth/dataLoad"]) {
                        if (store.getters['auth/isValidated']) {
                            clearInterval(refreshIntervalId);
                            next();
                        } else {
                            clearInterval(refreshIntervalId);
                            next('/verification/email/en_attente');
                        }
                    }
                }, 100);

            } else {
                next('/connexion');
            }
        } else {
            next();
        }
    } catch (error) {
        //
    }
});
export default router;