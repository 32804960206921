/* eslint-disable */
import Vue from 'vue'
import Vuex from 'vuex'
import auth from "../store/auth"
import formulaire from "./formulaire"
import contacts from "./contacts"
import sources from "./sources"
import events from "./events"
import fournisseurs from "./fournisseurs"
import produits from "./produits"
import categories from "./categories"
import reglement from "./reglement"
import typeRdv from "./typeRdv"
import users from "./users"
import agences from "./agences"
import devis from "./devis"
import commande from "./commande"
import facture from "./facture"
import models from "./models"
import liens from "./liens"
import coin from "./coin"
import entreprises from "./entreprises"
import commission from "./commission"

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {},
    mutations: {},
    actions: {

    },
    modules: {
        auth,
        formulaire,
        contacts,
        sources,
        events,
        fournisseurs,
        produits,
        categories,
        reglement,
        typeRdv,
        users,
        agences,
        devis,
        commande,
        facture,
        models,
        liens,
        coin,
        entreprises,
        commission,
    },
})

export default store